import { Box } from '@mui/material';
import { images } from 'src/assets';

import { MelCoinIcon } from 'src/components/Icon';

import styles from './energyPackageCard.module.scss';
import { EnergyCardPackageProps } from './energyPackageCard.type';
import Text from '../../text';

function EnergyPackageCard(props: EnergyCardPackageProps) {
  const renderImage = () => {
    switch (props.amount) {
      case '7':
        return images.energyX7;
      case '15':
        return images.energyX15;
      case '40':
        return images.energyX40;
      default:
        return images.energyX2;
    }
  };

  return (
    <Box className={styles.energyCard}>
      <img src={renderImage()} style={{ width: '100%' }} />
      <Box className={styles.price}>
        <MelCoinIcon length={'25px'} />
        <Text type={'h3'}>{props.price}</Text>
      </Box>
    </Box>
  );
}

export default EnergyPackageCard;
