import { InfoRounded } from '@mui/icons-material';
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import loadingLottieBox from 'src/assets/lottie/loading-box-lottie.json';
import LevelUpSection from 'src/pages/race/components/levelUpSection';
import RacePrizeSection from 'src/pages/race/components/racePrizeSection';
import RaceProgress from 'src/pages/race/components/raceProgressSection';
import { selectAccountAddress } from 'src/redux/accountMetamaskSlice';
import { RootState } from 'src/redux/store';
import { getMelCoinBalance } from 'src/services/contract/erc20/mel';
import { getEnergyBalance } from 'src/services/contract/game/energy';
import {
  initiateContract,
  useProvider,
} from 'src/services/contract/metamask/provider';
import { nft, singleRace } from 'src/utils/constants/addresses';

import GradientButton from 'src/components/lofi/gradientButton';
import Text from 'src/components/lofi/text';

import styles from './result.module.scss';
import { LevelUpProps } from './result.type';

function Result() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentAccount: string = useSelector(selectAccountAddress);
  const { activeBike } = useSelector((state: RootState) => state.race);

  const [raceStatus, setRaceStatus] = useState('finished');
  const [raceResult, setRaceResult] = useState({
    exp: 0,
    coin: 0,
    result: false,
  });
  const [levelUp, setLevelUp] = useState<LevelUpProps | null>(null);

  const LoadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loadingLottieBox,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // force browser back button to redirect to world-map
  window.onpopstate = () => {
    navigate('/world-map', { state: { from: 'result' }, replace: true });
  };

  const renderSectionTitle = () => {
    if (raceStatus === 'finished') {
      return 'Race Finished';
    } else if (levelUp) {
      return 'Level Up';
    }
  };

  const renderButtonTitle = () => {
    if (raceStatus === 'finished' && levelUp) {
      return 'Continue';
    } else if (raceStatus === 'waiting') {
      return 'View Race History';
    }

    return 'Done';
  };

  const renderButtonAction = () => {
    if (raceStatus == 'finished' && levelUp) {
      return setRaceStatus('levelup');
    }

    return navigate('/race', { state: { from: 'result' }, replace: true });
  };

  const renderSectionElement = () => {
    if (raceStatus == 'finished') {
      return <RacePrizeSection exp={raceResult.exp} coin={raceResult.coin} />;
    } else if (raceStatus == 'waiting') {
      return (
        <div className={styles.raceProgressContainer}>
          <RaceProgress
            title='Race is in progress'
            media={{
              type: 'lottie',
              media: LoadingLottie,
            }}
            infoBox={{
              icon: <InfoRounded />,
              description:
                'You can see the results and rewards in race history page',
            }}
          />
        </div>
      );
    } else if (levelUp) {
      return <LevelUpSection levelUpData={{ ...levelUp }} />;
    }
  };

  useEffect(() => {
    (async () => {
      const provider = useProvider();
      const raceContract = initiateContract(singleRace.address, singleRace.abi);
      const nftContract = initiateContract(nft.address, nft.abi);
      const startBlockNumber = provider ? await provider.getBlockNumber() : 0;

      const raceFinishedFilter = raceContract.filters.RaceResult(
        currentAccount.toLowerCase(),
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );

      const filteredEvent = await raceContract.queryFilter(
        raceFinishedFilter,
        startBlockNumber - 1000,
        startBlockNumber
      );

      const sortedEvents = filteredEvent.sort(
        (current, next) => next.blockNumber - current.blockNumber
      );

      const eventResult = sortedEvents.find(
        (event) =>
          event?.args?.playerAddress.toLowerCase() ===
            currentAccount.toLowerCase() &&
          activeBike?.tokenId == BigNumber.from(event?.args?.nftId).toString()
      );
      const eventArgs = eventResult?.args;

      setRaceResult({
        exp: eventArgs?.exp,
        coin: eventArgs?.mel,
        result: eventArgs?.result,
      });

      if (eventArgs?.isLevelUp) {
        const nftData = await nftContract.getNftData(activeBike?.tokenId);
        const levelUpTimes = eventArgs?.currentLevel - eventArgs?.previousLevel;

        setLevelUp({
          previousLevel: eventArgs?.previousLevel,
          currentLevel: eventArgs?.currentLevel,
          attributes: {
            speed: nftData.speedGrowth * levelUpTimes,
            acceleration: nftData.accelerationGrowth * levelUpTimes,
            drift: nftData.driftGrowth * levelUpTimes,
          },
        });
      }

      await getEnergyBalance(eventArgs?.playerAddress, dispatch);
      await getMelCoinBalance(dispatch, eventArgs?.playerAddress);

      return () => {
        raceContract.off(raceContract, setRaceStatus);
      };
    })();
  }, [location]);

  useEffect(() => {
    !location.state &&
      navigate('/world-map', { state: { from: 'result' }, replace: true });
  }, [location]);

  if (location.state) {
    return (
      <div className={styles.resultContainer}>
        <Text type={'h1'} align={'center'} className={styles.title}>
          {renderSectionTitle()}
        </Text>
        {renderSectionElement()}
        <div className={styles.buttonContainer}>
          <GradientButton
            position='right'
            title={renderButtonTitle()}
            onClick={() => renderButtonAction()}
          />
        </div>
      </div>
    );
  } else {
    return <div className={styles.blankResult}></div>;
  }
}

export default Result;
