import backIcon from './icons/back.png';
import crown from './icons/crown.png';
import dummyImg from './icons/dummy.png';
import dummyOpp from './icons/dummyOpp.png';
import dummyRaceTrack from './icons/dummyRaceTrack.png';
import energyIcon from './icons/energyIcon.png';
import rcCoinIcon from './icons/raceCoinIcon.png';
import energyX2 from './images/2xEnergy.png';
import energyX7 from './images/7xEnergy.png';
import energyX15 from './images/15xEnergy.png';
import energyX40 from './images/40xEnergy.png';
import coinRace from './images/coinRace.png';
import expRace from './images/expRace.png';
import glow from './images/glow.png';
import singlePlayer from './images/single.png';
import tutorAchievement from './images/tutorial/achievement.png';
import tutorBankExchange from './images/tutorial/bankExchange.png';
import tutorBankFocus from './images/tutorial/bankFocus.png';
import tutorBankWithdraw from './images/tutorial/bankWithdraw.png';
import tutorChargingStationActive from './images/tutorial/chargingStationActive.png';
import tutorChargingStationFocus from './images/tutorial/chargingStationFocus.png';
import tutorGetStarted from './images/tutorial/getStarted.png';
import tutorHistorySingle from './images/tutorial/historySingle.png';
import tutorHistoryVersus from './images/tutorial/historyVersus.png';
import tutorRaceChooseMode from './images/tutorial/raceChooseMode.png';
import tutorRaceFocus from './images/tutorial/raceFocus.png';
import tutorRaceJoin from './images/tutorial/raceJoin.png';
import tutorRaceResult from './images/tutorial/raceResult.png';
import tutorRaceSelectBike from './images/tutorial/raceSelectBike.png';
import tutorRaceSelectTrack from './images/tutorial/raceSelectTrack.png';
import tutorRaceSingleChooseBike from './images/tutorial/raceSingleChooseBike.png';
import tutorRaceSingleInProgress from './images/tutorial/raceSingleInProgress.png';
import tutorRaceSinglePlayerFocus from './images/tutorial/raceSinglePlayerFocus.png';
import tutorRaceSingleResult from './images/tutorial/raceSingleResult.png';
import tutorRaceSingleTrackInfo from './images/tutorial/raceSingleTrackInfo.png';
import tutorRaceVersusChooseBike from './images/tutorial/raceVersusChooseBike.png';
import tutorRaceVersusFinished from './images/tutorial/raceVersusFinished.png';
import tutorRaceVersusInProgress from './images/tutorial/raceVersusInProgress.png';
import tutorRaceVersusJoin from './images/tutorial/raceVersusJoin.png';
import tutorRaceVersusStart from './images/tutorial/raceVersusStart.png';
import tutorWorkshopActive from './images/tutorial/workshopActive.png';
import tutorWorkshopFocus from './images/tutorial/workshopFocus.png';
import versus from './images/versus.png';
import raceIcon from './images/worldMapIcons/race.png';
import accelerationIcon from './svg/acceleration-icon.svg';
import driftIcon from './svg/drift-icon.svg';
import emptyBox from './svg/empty-box.svg';
import emptyDocs from './svg/empty-docs.svg';
import filterIcon from './svg/filter.svg';
import glowBg from './svg/glow-bg.svg';
import melCoinIcon from './svg/Melx-crypto-coin.svg';
import speedIcon from './svg/speed-icon.svg';
import trophy from './svg/trophy.svg';
import voucher from './svg/voucher.svg';
import wheelBg from './svg/wheel-bg.svg';

export const images = {
  energyIcon,
  rcCoinIcon,
  dummyImg,
  backIcon,
  dummyRaceTrack,
  dummyOpp,
  crown,
  glow,
  energyX2,
  energyX7,
  energyX15,
  energyX40,
  raceIcon,
  expRace,
  coinRace,
  tutorGetStarted,
  tutorChargingStationFocus,
  tutorChargingStationActive,
  tutorRaceFocus,
  tutorRaceSelectTrack,
  tutorRaceSelectBike,
  tutorRaceJoin,
  tutorRaceResult,
  tutorWorkshopFocus,
  tutorWorkshopActive,
  tutorBankFocus,
  tutorBankWithdraw,
  tutorBankExchange,
  tutorAchievement,
  tutorRaceChooseMode,
  tutorRaceSinglePlayerFocus,
  tutorRaceSingleChooseBike,
  tutorRaceSingleTrackInfo,
  tutorRaceSingleInProgress,
  tutorRaceSingleResult,
  tutorRaceVersusChooseBike,
  tutorRaceVersusJoin,
  tutorRaceVersusStart,
  tutorRaceVersusInProgress,
  tutorRaceVersusFinished,
  tutorHistoryVersus,
  tutorHistorySingle,
  singlePlayer,
  versus,
};

export const svgs = {
  emptyDocs,
  speedIcon,
  melCoinIcon,
  accelerationIcon,
  driftIcon,
  emptyBox,
  filterIcon,
  wheelBg,
  glowBg,
  trophy,
  voucher,
};
