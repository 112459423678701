import React from 'react';
import {
  Link as ReactLink,
  LinkProps,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import './navItem.module.scss';

const NavItem = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <React.Fragment>
      <ReactLink className={match ? 'isActive' : undefined} to={to} {...props}>
        {children}
      </ReactLink>
    </React.Fragment>
  );
};

export default NavItem;
