import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { images } from 'src/assets';
import MelxCryptoLogo from 'src/assets/svg/MelxCryptoLogo';
import {
  selectAccountMelBalance,
  selectGachaVoucher,
  selectGarageLimit,
  selectNftCount,
} from 'src/redux/accountMetamaskSlice';
import { minimalMelToMint } from 'src/services/contract/erc20/mel';
import { gachaWithVoucher } from 'src/services/contract/nft/gacha';
import { getNftMintingFee } from 'src/services/contract/nft/nft';
import { motorbikeOnTheWay } from 'src/utils/constants/loadingMessageConstant';
import {
  formatBigNumberToString,
  formatStringToBigNumber,
  getTxError,
} from 'src/utils/helper';

import PageHeader from 'src/components/hifi/pageHeader';
import GachaVoucherButton from 'src/components/lofi/button/gachaVoucherButton';
import BikeCard from 'src/components/lofi/card/bikeCard';
import GradientButton from 'src/components/lofi/gradientButton';
import RefferalModal from 'src/components/lofi/modal/referralModal';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';
import Text from 'src/components/lofi/text';

import styles from './motorbikeDealer.module.scss';
import {
  defaultMintingRequirement,
  MintingRequirementType,
} from './motorbikeDealer.type';

const data = {
  name: 'XEV ELECTRIC MX AA',
  type: 'acceleration',
  rarity: 'UNCOMMON',
  level: 1,
  A: 24,
  S: 9,
  D: 9,
  imageUrl: images.dummyImg,
  id: '1',
};

const data2 = {
  name: 'XEV ELECTRIC MX AA',
  type: 'acceleration',
  rarity: 'LEGENDARY',
  level: 1,
  A: 24,
  S: 9,
  D: 9,
  imageUrl: images.dummyImg,
  id: '2',
};

const data3 = {
  name: 'XEV ELECTRIC MX AA',
  type: 'acceleration',
  rarity: 'COMMON',
  level: 1,
  A: 24,
  S: 9,
  D: 9,
  imageUrl: images.dummyImg,
  id: '3',
};

function MotorbikeDealer() {
  const [showGachaModal, setShowGachaModal] = useState<boolean>(false);
  const [mintingRequirement, setMintingRequirement] =
    useState<MintingRequirementType>(defaultMintingRequirement);
  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);
  const currentBalanceMel = useSelector(selectAccountMelBalance);
  const currentNftCount = useSelector(selectNftCount);
  const currentGarageLimit = useSelector(selectGarageLimit);
  const userMelBalance = formatStringToBigNumber(currentBalanceMel);
  const hasGachaVoucher = useSelector(selectGachaVoucher) > 0;
  const hasEnoughGarageSpace = currentNftCount < currentGarageLimit;

  const getMintingRequirement = async () => {
    const fee = await getNftMintingFee();
    const minimalMel = await minimalMelToMint();

    setMintingRequirement({ ...mintingRequirement, fee, minimalMel });
  };

  useEffect(() => {
    getMintingRequirement();
  }, []);

  const gachaWithVoucherHandler = async (e: any) => {
    e.preventDefault();
    try {
      setWaitingModalContent({
        ...defaultModalContent,
        openStatus: true,
        title: 'Waiting approve the transaction',
        message: 'Please open your wallet and confirm the transaction',
        showViewInventory: false,
      });
      await gachaWithVoucher();
    } catch (err: any) {
      const modalContent = getTxError(err);

      setWaitingModalContent({
        ...waitingModalContent,
        ...modalContent,
      });

      return;
    }

    setWaitingModalContent({ ...defaultModalContent, ...motorbikeOnTheWay });
  };

  return (
    <div className={styles.dealer}>
      <PageHeader pageTitle='Bike Dealer' />
      <Grid container spacing={2} className={styles.bikeBoxContainer}>
        <Grid item xs={3}>
          <BikeCard data={data} disabled={true} />
        </Grid>
        <Grid item xs={3}>
          <BikeCard data={data} disabled={true} />
        </Grid>
        <Grid item xs={3}>
          <BikeCard data={data2} disabled={true} />
        </Grid>
        <Grid item xs={3}>
          <BikeCard data={data3} disabled={true} />
        </Grid>
      </Grid>
      <div className={styles.descBox}>
        <Text type='h3' align='center' style={{ marginBottom: 10 }}>
          Collect the world's most popular motorbikes
        </Text>
        <Text>
          Purchase and collect tons of unique motorbikes that appeal to your
          class and get more chances to triumph in the race! Expand your
          motorbike garage and make it the most well-known in the globe.
        </Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '32px' }}>
        {!mintingRequirement.fee.eq(0) && (
          <GradientButton
            disabled={
              userMelBalance.lt(mintingRequirement.minimalMel) ||
              currentNftCount >= currentGarageLimit
            }
            title={'Gacha Bike'}
            onClick={() => setShowGachaModal(true)}
            rightContent={
              <div className={styles.melDetail}>
                <MelxCryptoLogo sx={{ width: '26px', height: '26px' }} />
                <Text type='button' className={styles.value}>
                  {formatBigNumberToString(mintingRequirement.fee)}
                </Text>
              </div>
            }
            style={{ margin: '0 0' }}
          />
        )}
        {hasGachaVoucher && (
          <GachaVoucherButton
            canGachaVoucher={hasGachaVoucher && hasEnoughGarageSpace}
            clickFunction={gachaWithVoucherHandler}
          />
        )}
      </div>
      {userMelBalance.lt(mintingRequirement.minimalMel) && (
        <Text type='small' align='center' className={styles.error}>
          {`Insufficient MEL Token (Your account should hold at least ${formatBigNumberToString(
            mintingRequirement.minimalMel
          )} MEL Token)`}
        </Text>
      )}
      {currentNftCount >= currentGarageLimit && (
        <Text type='small' align='center' className={styles.error}>
          {`You're already at your garage limit! Please upgrade your garage limit on garage`}
        </Text>
      )}
      <RefferalModal
        open={showGachaModal}
        handleClose={() => setShowGachaModal(false)}
        waitingModalContent={waitingModalContent}
        setWaitingModalContent={setWaitingModalContent}
      />
      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={() => setWaitingModalContent(defaultModalContent)}
      />
    </div>
  );
}

export default MotorbikeDealer;
