import { Box } from '@mui/material';

const VersionBox = () => {
  return (
    <Box
      sx={{
        height: '20px',
        font: 'white',
        color: 'white',
        position: 'absolute',
        bottom: '10px',
        left: '10px',
      }}
    >
      {process.env.REACT_APP_DEV_VERSION}
    </Box>
  );
};

export default VersionBox;
