import { Tooltip as MuiTooltip } from '@mui/material';
import React from 'react';

import type { TooltipProps } from './tooltip.type';

const Tooltip = (props: TooltipProps) => {
  return (
    <MuiTooltip
      title={props.title}
      arrow={props.arrow}
      placement={props.placement}
    >
      {props.children}
    </MuiTooltip>
  );
};

export default Tooltip;
