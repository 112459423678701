import { Box } from '@mui/material';
import Lottie from 'react-lottie';

import Text from 'src/components/lofi/text';

import { RaceProgressProps } from './raceProgress.type';
import styles from './raceProgressSection.module.scss';

const RaceProgress = (props: RaceProgressProps) => {
  const { media, title, infoBox } = props;

  const renderMedia = () => {
    if (media.type == 'image') {
      return media.media;
    } else if (media.type == 'lottie') {
      return <Lottie options={media.media} width={105} />;
    }
  };

  return (
    <div className={styles.raceProgress}>
      <Box>{renderMedia()}</Box>
      <Box className={styles.titleContainer}>
        <Text type='h3'>{title}</Text>
      </Box>
      {infoBox && (
        <Box className={styles.infoBoxContainer}>
          {infoBox.icon}
          <p>{infoBox.description}</p>
        </Box>
      )}
    </div>
  );
};

export default RaceProgress;
