import { ethers } from 'ethers';
import { setWhitelistStatusEmpty } from 'src/redux/accountMetamaskSlice';
import {
  removeModalContent,
  setModalContent,
} from 'src/redux/modalContentSlice';
import type { AppDispatch } from 'src/redux/store';
import {
  initiateContract,
  useProvider,
} from 'src/services/contract/metamask/provider';
import { gacha } from 'src/utils/constants/addresses';
import {
  modalAddPolygonTestnet,
  modalMetamaskNotInstalled,
  modalUserRejectedTheApproval,
  modalWrongNetwork,
} from 'src/utils/constants/errorMessageConstants';
import { SignatureType, WhitelistStatus } from 'src/utils/interface';

async function connectMetamask(dispatch: AppDispatch): Promise<string> {
  dispatch(setWhitelistStatusEmpty());
  let account = '';
  if (typeof window.ethereum !== 'undefined') {
    account = await getAccount();
    const network = await window.ethereum.request({ method: 'eth_chainId' });
    if (network != process.env.REACT_APP_METAMASK_CHAINID) {
      switchNetwork(dispatch);
    }
  } else {
    dispatch(setModalContent(modalMetamaskNotInstalled));
  }
  return account;
}

async function getAccount(): Promise<string> {
  let account = '';
  try {
    const [response]: string[] = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    account = response;
  } catch (err: any) {
    if (err.code === 4001) {
      account = err.code.toString();
    }
  }
  return account;
}

async function checkNetwork(): Promise<string> {
  const network = await window.ethereum.request({ method: 'eth_chainId' });
  return network;
}

async function switchNetwork(dispatch: AppDispatch) {
  try {
    dispatch(setModalContent(modalWrongNetwork));
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: process.env.REACT_APP_METAMASK_CHAINID }],
    });
    dispatch(removeModalContent());
  } catch (switchError: any) {
    if (switchError.code === 4001) {
      dispatch(setModalContent(modalUserRejectedTheApproval));
    }
    //* This error 4902 code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        //* if user not rejected then they didnt have polygon testnet on their wallet
        dispatch(setModalContent(modalAddPolygonTestnet));
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: process.env.REACT_APP_METAMASK_CHAINID,
              chainName: 'Polygon Testnet Mumbai',
              rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
            },
          ],
        });
        dispatch(removeModalContent());
      } catch (err: any) {
        //* if user reject the request the err.code will be 4001
        if (err.code === 4001) {
          dispatch(setModalContent(modalUserRejectedTheApproval));
        }
      }
    }
  }
}

async function getWhitelistStatus(
  currentAccount: string
): Promise<WhitelistStatus> {
  const gachaContract = initiateContract(gacha.address, gacha.abi);
  const contractWhitelistStatus: boolean =
    await gachaContract.whitelistStatus();
  const currentWhitelistBatch: number = await gachaContract.whitelistBatch();
  const isUserWhitelisted: boolean =
    await gachaContract.whitelistBatchToAddressMap(
      currentWhitelistBatch,
      currentAccount
    );

  return {
    isContractWhitelistOn: contractWhitelistStatus,
    isUserWhitelisted: isUserWhitelisted,
    isUserCanMint: !contractWhitelistStatus || isUserWhitelisted,
  };
}

async function getSignature(): Promise<SignatureType> {
  const provider = useProvider();
  const signer = provider?.getSigner();
  const [account]: string[] = await window.ethereum.request({
    method: 'eth_requestAccounts',
  });

  const originalCaseAccount = await ethers.utils.getAddress(account);

  const currentTime = Math.round(Number(new Date()) / 1000);
  const message = `Hi I wanna using Mel X API | ${currentTime}`;
  const messageUtf8Bytes = ethers.utils.toUtf8Bytes(message);
  const testBytes = ethers.utils.arrayify(messageUtf8Bytes);
  const signature = await signer?.signMessage(testBytes);

  const accountSignature = {
    pubkey: originalCaseAccount,
    message: message,
    signature: signature,
  };
  return accountSignature;
}

export {
  checkNetwork,
  connectMetamask,
  getAccount,
  getSignature,
  getWhitelistStatus,
  switchNetwork,
};
