import { EnergyIcon, GameCoinIcon, MelCoinIcon } from 'src/components/Icon';

import styles from './currencyBox.module.scss';
import { currencyBoxProps } from './currencyBox.type';

function CurrencyBox(props: currencyBoxProps) {
  const { icon, value, style, symbol } = props;

  const renderIcon = () => {
    switch (icon) {
      case 'energy':
        return <EnergyIcon length='40px' />;
      case 'mel-coin':
        return <MelCoinIcon length='40px' />;
      case 'r-coin':
        return <GameCoinIcon length='40px' />;
    }
  };

  return (
    <div className={styles.currencyBox} style={style}>
      {renderIcon()}
      <div className={styles.currencyValue}>
        {value} {symbol && symbol}
      </div>
    </div>
  );
}

export default CurrencyBox;
