import { images } from 'src/assets';

import BackButton from 'src/components/lofi/button/backButton';
import Text from 'src/components/lofi/text';

import styles from './tutorial.module.scss';

function Tutorial() {
  return (
    <main className={styles.tutorial}>
      <BackButton />
      <Text type={'h1'} align={'center'} style={{ marginBottom: 50 }}>
        How to Play
      </Text>
      <section>
        <Text className={styles.sectionTitle} type='h2'>
          Overview
        </Text>
        <Text>
          Bike Battle is a racing and bike collecting game. In this game you
          can:
        </Text>
        <Text>
          <ol>
            <li>Buy a bike</li>
            <li>Race your bike with other players or with bots</li>
            <li>Win in crypto rewards</li>
            <li>Level up and modify your bike to make it more awesome</li>
          </ol>
        </Text>
        <Text>This guide will explain how to play the game.</Text>
      </section>
      <section>
        <Text className={styles.sectionTitle} type='h2'>
          Getting Started
        </Text>
        <Text className={styles.sectionParagraph}>
          To play the game, you have to first own a Bike Battle motorcycle. You
          can buy a motorcycle using the MEL token. You can get MEL tokens in
          any decentralized exchange. After you get some MEL tokens, you can buy
          a motorcycle from the Bike Battle landing page.
        </Text>
        <img src={images.tutorGetStarted} className={styles.tutorImg} />
        <Text className={styles.sectionParagraph}>
          <div>
            When you click on <strong>Get your Motorbike</strong>, a gacha UI
            will appear. You will first be allowed to put in a referral code.
            You can skip the process and go to the gacha part. When you’re in
            the gacha part, you will be redirected to Metamask UI (if you don’t
            have it you have to install it first) where you will pay for the
            motorbike. One motorbike costs 2000 MEL to gacha.
          </div>
        </Text>
        <Text className={styles.sectionParagraph}>
          Once you finished buying a motorbike you will be directed to the world
          map.
        </Text>
      </section>
      <section>
        <Text className={styles.sectionTitle} type='h2'>
          Buying Energy for Races
        </Text>
        <Text className={styles.sectionParagraph}>
          You need the energy to compete in races. Energy can be bought in the
          charging station which you can go to from the world map.
        </Text>
        <img
          src={images.tutorChargingStationFocus}
          className={styles.tutorImg}
        />
        <Text className={styles.sectionParagraph}>
          Once you’re in the charging station you can select which energy
          package you’d like to buy. After choosing a package you will be
          redirected to Metamask where you will pay for the energy package.
        </Text>
        <img
          src={images.tutorChargingStationActive}
          className={styles.tutorImg}
        />
      </section>
      <section>
        <Text className={styles.sectionTitle} type='h2'>
          Joining a Race
        </Text>
        <Text className={styles.sectionParagraph}>
          Racing is the core part of the game. You will compete against
          procedurally generated opponents (Single Player) or with other
          opponents (Player vs Player). Once you finished a race you will get a
          coin reward based on your position. You will also get XP for the bike
          that you use in the race which will level up your bike.
        </Text>
        <Text className={styles.sectionParagraph}>
          You can register for a race by going to race tracks which can be
          accessed from the world map.
        </Text>
        <img src={images.tutorRaceFocus} className={styles.tutorImg} />
        <Text className={styles.sectionParagraph}>
          When you first arrived at race tracks, you will be prompted to choose
          which type of race you want to take.
        </Text>
        <img src={images.tutorRaceChooseMode} className={styles.tutorImg} />
      </section>
      <section>
        <Text className={styles.sectionTitle} type='h2'>
          Single Player
        </Text>
        <Text className={styles.sectionParagraph}>
          When you choose the single player, you will race against the bot and
          get rewards to enhance your bike stats.
        </Text>
        <Text className={styles.sectionParagraph}>
          You have to choose which bike you want to use for this race. Remember
          that the better bike you use, the bigger your chance to win the race.
        </Text>
        <img
          src={images.tutorRaceSingleChooseBike}
          className={styles.tutorImg}
        />
        <Text className={styles.sectionParagraph}>
          After choosing the bike, you will get information about today’s race
          track. To make the gaming experience more fun, we generate random race
          tracks every day. You need to click on the button to start the race,
          the single-player race will cost 1 energy.
        </Text>
        <img
          src={images.tutorRaceSingleTrackInfo}
          className={styles.tutorImg}
        />
        <Text className={styles.sectionParagraph}>
          Then you need to wait while the race is in progress.
        </Text>
        <img
          src={images.tutorRaceSingleInProgress}
          className={styles.tutorImg}
        />
        <Text className={styles.sectionParagraph}>
          After the race is finished, you will see your rewards. On the
          single-player race, you will get XP and MEL coins as rewards.
        </Text>
        <Text className={styles.sectionParagraph}>
          <ol>
            <li>
              The MEL coin is used to buy energy, parts, and other payable
              components of the game.
            </li>
            <li>
              Experience point (XP). Each motorbike has experience points. When
              the XP reaches a certain number the bike will level up. When it
              levels up your bike stats will increase.
            </li>
          </ol>
        </Text>
        <img src={images.tutorRaceSingleResult} className={styles.tutorImg} />
      </section>
      <section>
        <Text className={styles.sectionTitle} type='h2'>
          PVP (Player vs Player)
        </Text>
        <Text className={styles.sectionParagraph}>
          If you want to race against the other players, choose Versus option.
        </Text>
        <Text className={styles.sectionParagraph}>
          After choosing the Versus menu, you will have to choose which bike you
          want to use for the race. Use your best bike to get a bigger chance of
          winning against your opponent.
        </Text>
        <img
          src={images.tutorRaceVersusChooseBike}
          className={styles.tutorImg}
        />
        <Text className={styles.sectionParagraph}>
          After choosing the bike, you will have to wait until the other racers
          join the pool.
        </Text>
        <Text className={styles.sectionParagraph}>
          Any one of the players can start the race, but they have to pay for
          the gas fee. As a reward, the player who starts the race will get 5 BB
          coins as an incentive.
        </Text>
        <Text className={styles.sectionParagraph}>
          The race can be started when 3 players are available.
        </Text>
        <img src={images.tutorRaceVersusJoin} className={styles.tutorImg} />
        <img src={images.tutorRaceVersusStart} className={styles.tutorImg} />
        <Text className={styles.sectionParagraph}>
          Please wait while the race is in progress.
        </Text>
        <img
          src={images.tutorRaceVersusInProgress}
          className={styles.tutorImg}
        />
        <Text className={styles.sectionParagraph}>
          When the race is finished, you can claim the reward on the race
          history page.
        </Text>
        <img src={images.tutorRaceVersusFinished} className={styles.tutorImg} />
        <Text className={styles.sectionParagraph}>
          After finishing a race, you’ll be awarded: the MEL coin used to buy
          energy, parts, and other payable components of the game.
        </Text>
      </section>
      <section>
        <Text className={styles.sectionTitle} type='h2'>
          Race History
        </Text>
        <Text className={styles.sectionParagraph}>
          On this page, you can see the history of race that you had. We divide
          into PVP and single-player races. You can also claim the rewards from
          the previous race here.
        </Text>
        <img src={images.tutorHistoryVersus} className={styles.tutorImg} />
        <img src={images.tutorHistorySingle} className={styles.tutorImg} />
      </section>
      <section>
        <Text className={styles.sectionTitle} type='h2'>
          Modifying Your Bike
        </Text>
        <Text className={styles.sectionParagraph}>
          To make your bike perform better in races, you can modify your
          motorcycle by stat vouchers in the workshop and then putting them on
          the bike. You can go to the workshop on the world map.
        </Text>
        <img src={images.tutorWorkshopFocus} className={styles.tutorImg} />
        <Text className={styles.sectionParagraph}>
          There are 3 sections of the workshop:
        </Text>
        <Text className={styles.sectionParagraph}>
          <ol>
            <li>Shop. This is where you browse and buy available vouchers.</li>
            <li>
              Modify. This is where you put the bought vouchers onto the
              motorcycle.
            </li>
            <li>
              Inventory. This is where you can see all the vouchers you’ve
              bought.
            </li>
          </ol>
        </Text>
        <img src={images.tutorWorkshopActive} className={styles.tutorImg} />
      </section>
    </main>
  );
}

export default Tutorial;
