import { SvgIcon } from '@mui/material';
import React from 'react';

const MelxLogo = (props: any) => {
  return (
    <SvgIcon
      {...props}
      width='138'
      height='30'
      viewBox='0 0 138 30'
      fill='none'
    >
      <path
        d='M59.0337 25.632L59.004 10.0012L51.4986 23.0269H50.134L42.6285 10.0932V25.632H39.7806V4.17798H42.2132L50.8756 19.2571L59.4194 4.17798H61.852L61.8816 25.632H59.0337Z'
        fill='white'
      />
      <path
        d='M83.184 22.9656V25.632H68.1138V4.17798H82.7687V6.84441H71.0804V13.4339H81.4931V16.039H71.0804V22.9656H83.184Z'
        fill='white'
      />
      <path
        d='M87.9876 4.17798H90.9542V22.9656H102.198V25.632H87.9876V4.17798Z'
        fill='white'
      />
      <path
        d='M134.588 25.632L128.359 16.7746L122.218 25.632H118.806L126.608 14.6292L119.281 4.17798H122.663L128.477 12.4225L134.232 4.17798H137.466L130.139 14.5372L138 25.632H134.588Z'
        fill='white'
      />
      <mask
        id='mask0_359_70'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='30'
        height='30'
      >
        <rect width='29.038' height='30' fill='#C4C4C4' />
      </mask>
      <g mask='url(#mask0_359_70)'>
        <path
          d='M20.23 19.0085L15.4193 21.3773L23.144 25.2126V20.4658L20.23 19.0085Z'
          fill='url(#paint0_linear_359_70)'
        />
        <path
          d='M5.89404 20.2053V24.9428L13.3636 21.2626L8.62799 18.8442L5.89404 20.2053Z'
          fill='url(#paint1_linear_359_70)'
        />
        <path
          d='M14.519 15.1485L29.038 8.033V24.0065L24.7976 25.997V14.8695L14.501 19.9387L4.24047 14.7021V25.997L0 24.0065V7.82837L14.519 15.1485Z'
          fill='url(#paint2_linear_359_70)'
        />
        <path
          d='M14.6121 8.40511L5.59694 3.93115L0.621216 6.17588L14.6121 13.1829L28.2698 6.34021L23.3781 4.05517L14.6121 8.40511Z'
          fill='url(#paint3_linear_359_70)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_359_70'
          x1='28.053'
          y1='21.7082'
          x2='0.557074'
          y2='23.3353'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FE01FA' />
          <stop offset='0.3163' stopColor='#FF0F6F' />
          <stop offset='0.5195' stopColor='#FF3D3D' />
          <stop offset='0.7086' stopColor='#FF851F' />
          <stop offset='1' stopColor='#FFDB01' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_359_70'
          x1='27.9866'
          y1='20.5848'
          x2='0.490621'
          y2='22.2118'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FE01FA' />
          <stop offset='0.3163' stopColor='#FF0F6F' />
          <stop offset='0.5195' stopColor='#FF3D3D' />
          <stop offset='0.7086' stopColor='#FF851F' />
          <stop offset='1' stopColor='#FFDB01' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_359_70'
          x1='0'
          y1='16.9122'
          x2='29.0366'
          y2='16.9122'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FE01FA' />
          <stop offset='0.3163' stopColor='#FF0F6F' />
          <stop offset='0.5195' stopColor='#FF3D3D' />
          <stop offset='0.7086' stopColor='#FF851F' />
          <stop offset='1' stopColor='#FFDB01' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_359_70'
          x1='0.620195'
          y1='8.55765'
          x2='28.2706'
          y2='8.55765'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFDB01' />
          <stop offset='0.2914' stopColor='#FF851F' />
          <stop offset='0.4805' stopColor='#FF3D3D' />
          <stop offset='0.6837' stopColor='#FF0F6F' />
          <stop offset='1' stopColor='#FE01FA' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default MelxLogo;
