import { Box, ButtonBase, Grid, Link } from '@mui/material';
import styles from 'src/pages/worldMap/worldMap.module.scss';

import WorldMapButton from 'src/components/lofi/button/worldMapButton';

import type { WorldMapGridProps } from './worldMapGrid.type';

function WorldMapGrid(props: WorldMapGridProps) {
  const firstRowHeight = '52%';
  const secondRowHeight = '48%';

  const firstColWidth = '24.3%';
  const secondColWidth = '16.67%';
  const thirdColWidth = '20.8%';
  const fourthColWidth = '17.3%';
  const fifthColWidth = '20.8%';

  // change to true when adjusting button position are needed
  const showGrid = false;
  const gridColorProps = {
    background: showGrid ? 'white' : 'transparent',
    opacity: showGrid ? '40%' : '100%',
    border: showGrid ? '1px dashed grey' : '1px',
  };

  return (
    <>
      <Grid container className={styles.grid}>
        {/* Bank */}
        <Box
          className={styles.iconWrapper}
          sx={{
            top: '33%',
            left: '14%',
          }}
        >
          <Link href='/bank'>
            <WorldMapButton
              buttonIcon={styles.bank}
              tooltipText={'Bank'}
              length={'62px'}
              isShowed={showGrid}
            />
          </Link>
        </Box>

        {/* Garage */}
        <Box
          className={styles.iconWrapper}
          sx={{
            left: '27%',
            top: '41%',
          }}
        >
          <Link href='/garage'>
            <WorldMapButton
              buttonIcon={styles.garage}
              tooltipText={'Garage'}
              length={'72px'}
              isShowed={showGrid}
            />
          </Link>
        </Box>

        {/* Race */}
        <Box
          className={styles.iconWrapper}
          sx={{
            left: '49.5%',
            top: '38%',
          }}
        >
          <Link href='/race'>
            <WorldMapButton
              buttonIcon={styles.race}
              tooltipText={'Race'}
              length={'65px'}
              isShowed={showGrid}
            />
          </Link>
        </Box>

        {/* Charging Station */}
        <Box
          className={styles.iconWrapper}
          sx={{
            left: '71%',
            top: '29%',
          }}
        >
          <Link href='/charging-station'>
            <WorldMapButton
              buttonIcon={styles.chargingStation}
              tooltipText={'Charging Station'}
              length={'72px'}
              isShowed={showGrid}
            />
          </Link>
        </Box>

        {/*  Airport */}
        <Box
          className={styles.iconWrapper}
          sx={{
            left: '82%',
            top: '40%',
          }}
        >
          <ButtonBase
            onClick={() => {
              props.setShowComingSoonModal(true);
            }}
          >
            <WorldMapButton
              buttonIcon={styles.airport}
              tooltipText={'Airport'}
              length={'60px'}
              isShowed={showGrid}
            />
          </ButtonBase>
        </Box>

        {/* Workshop */}
        <Box
          className={styles.iconWrapper}
          sx={{
            left: '91%',
            top: '59%',
          }}
        >
          <Link href='/workshop'>
            <WorldMapButton
              buttonIcon={styles.workshop}
              tooltipText={'Workshop'}
              length={'60px'}
              isShowed={showGrid}
            />
          </Link>
        </Box>

        {/* Home */}
        <Box
          className={styles.iconWrapper}
          sx={{
            left: '37.5%',
            top: '60.5%',
          }}
        >
          <Link href='/home'>
            <WorldMapButton
              buttonIcon={styles.house}
              tooltipText={'Home'}
              length={'55px'}
              isShowed={showGrid}
            />
          </Link>
        </Box>

        {/* Dock  */}
        <Box
          className={styles.iconWrapper}
          sx={{
            left: '68.5%',
            top: '59%',
          }}
        >
          <ButtonBase
            onClick={() => {
              props.setShowComingSoonModal(true);
            }}
          >
            <WorldMapButton
              buttonIcon={styles.dock}
              tooltipText={'Dock'}
              length={'62px'}
              isShowed={showGrid}
            />
          </ButtonBase>
        </Box>

        {/*  Motorbike Dealer*/}
        <Box
          className={styles.iconWrapper}
          sx={{
            left: '10%',
            top: '60%',
          }}
        >
          <Link href='/bike-dealer'>
            <WorldMapButton
              buttonIcon={styles.motorbikeDealer}
              tooltipText={'Bike Dealer'}
              length={'60px'}
              isShowed={showGrid}
            />
          </Link>
        </Box>
      </Grid>
    </>
  );
}

export default WorldMapGrid;
