import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAccountAddress } from 'src/redux/accountMetamaskSlice';
import { getReferralText } from 'src/utils/helper';
import type { ReferralProps } from 'src/utils/interface';

import ClipboardButton from 'src/components/lofi/button/clipboardButton';
import ShareToTwitter from 'src/components/lofi/button/shareToTwitter';
import ReferralDashboardSummaryText from 'src/components/lofi/typography/referralSummaryText';

import styles from './referralDashboardGrid.module.scss';
import Text from '../../text';

export default function SummaryItemGrid(props: {
  TotalReferralMelProps: ReferralProps;
  TotalUserUsedProps: ReferralProps;
  TotalReferralUsedProps: ReferralProps;
}) {
  // Get Current Account from State
  const currentAccount: string = useSelector(selectAccountAddress);

  return (
    <Grid container className={styles.rowOne}>
      <Grid item xs={4}>
        {/* TOTAL REFERRAL MEL  */}
        <ReferralDashboardSummaryText {...props.TotalReferralMelProps} />
      </Grid>
      <Grid item xs={4} paddingX={2}>
        {/* TOTAL USER USED REFERRAL  */}
        <ReferralDashboardSummaryText {...props.TotalUserUsedProps} />
      </Grid>
      <Grid item xs={2} paddingRight={2}>
        {/* TOTAL USED REFERRAL  */}
        <ReferralDashboardSummaryText {...props.TotalReferralUsedProps} />
      </Grid>
      <Grid item xs={2}>
        <Box className={styles.share}>
          {/* SHARE TWITTER AND COPY  */}
          <Text type='label' color='#F3F2F1' style={{ height: '24px' }}>
            SHARE IT TO GET INCENTIVES
          </Text>
          <Box sx={{ marginTop: '12px', display: 'flex', gap: '10px' }}>
            <ShareToTwitter twitterIntent={getReferralText(currentAccount)} />
            <ClipboardButton copyText={getReferralText(currentAccount)} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
