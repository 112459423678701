import { SvgIcon } from '@mui/material';
import React from 'react';

const SingleBox = (props: any) => {
  return (
    <SvgIcon
      width='45'
      height='50'
      viewBox='0 0 45 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M43.7668 12.4068L22.2366 24.8592L0.70636 12.4068C1.14842 11.6416 1.77881 11.0022 2.5376 10.5494L19.6205 0.686795C21.2443 -0.228932 23.2288 -0.228932 24.8526 0.686795L41.9355 10.5494C42.6943 11.0022 43.3247 11.6416 43.7668 12.4068Z'
        fill='#FFE09C'
      />
      <path
        d='M22.2365 24.8592V49.7118C21.4117 49.7107 20.5978 49.5229 19.8559 49.1624L2.61606 39.1952C1.00007 38.2604 0.00353169 36.5363 0 34.6694V15.049C0.00434267 14.1221 0.247637 13.2121 0.706337 12.4067L22.2365 24.8592Z'
        fill='#FFCB54'
      />
      <path
        d='M44.4731 15.049V34.6694C44.4696 36.5363 43.473 38.2604 41.857 39.1952L24.5387 49.1886C23.8184 49.5283 23.0328 49.7068 22.2365 49.7118V24.8592L43.7667 12.4067C44.2254 13.2121 44.4687 14.1221 44.4731 15.049Z'
        fill='#E39F3D'
      />
    </SvgIcon>
  );
};

export default SingleBox;
