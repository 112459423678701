import { Button } from '@mui/material';

import Tooltip from 'src/components/lofi/tooltip';

import styles from './worldMapButton.module.scss';
import type { WorldMapButtonProps } from './worldMapButton.type';

const WorldMapButton = (props: WorldMapButtonProps) => {
  return (
    <Tooltip title={props.tooltipText} arrow placement='top'>
      <Button className={`${props.buttonIcon} ${styles.iconButton}`} />
    </Tooltip>
  );
};

export default WorldMapButton;
