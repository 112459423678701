import type { RarityBoxProps } from './rarityBox.type';
import styles from './rarityBox.module.scss';

function RarityBox(props: RarityBoxProps) {
  const rarityColor = () => {
    switch (props.type.toLowerCase()) {
      case 'uncommon':
        return '#ff0f6f';
      case 'rare':
        return '#0097ff';
      case 'epic':
        return '#b82cff';
      case 'legendary':
        return '#14f2e9';
      default:
        // common rarity as default
        return '#ff851f';
    }
  };

  return (
    <div className={styles.rarity}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.23 7.2396L12 11.9996L3.77002 7.2396C3.939 6.94712 4.17997 6.70271 4.47002 6.5296L11 2.7596C11.6207 2.40956 12.3793 2.40956 13 2.7596L19.53 6.5296C19.8201 6.70271 20.061 6.94712 20.23 7.2396ZM3.77 7.24023L12 12.0002L20.23 7.24023C20.4053 7.54808 20.4983 7.89595 20.5 8.25023V15.7502C20.4986 16.4639 20.1177 17.1229 19.5 17.4802L12.88 21.3002C12.6047 21.4301 12.3044 21.4983 12 21.5002C11.6847 21.4998 11.3736 21.428 11.09 21.2902L4.5 17.4802C3.88228 17.1229 3.50135 16.4639 3.5 15.7502V8.25023C3.50166 7.89595 3.59466 7.54808 3.77 7.24023Z'
          fill={rarityColor()}
        />
      </svg>

      <div style={{ color: rarityColor(), textTransform: 'capitalize' }}>
        {props.type.toLowerCase()}
      </div>
    </div>
  );
}
export default RarityBox;
