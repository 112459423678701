import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { images } from 'src/assets';

import GlowBox from 'src/components/lofi/box/glowBox';
import Stepper from 'src/components/lofi/stepper';
import Text from 'src/components/lofi/text';

import styles from './chooseModeSection.module.scss';

const ChooseMode = () => {
  const navigate = useNavigate();

  const gameMode = [
    {
      image: images.singlePlayer,
      text: 'Single Player',
      route: 'single',
    },
    {
      image: images.versus,
      text: 'Versus',
      route: 'versus',
    },
  ];

  return (
    <Grid
      container
      item
      xs={9}
      className={styles.gameOptionsContainer}
      spacing={2}
    >
      <Grid item xs={10}>
        <Stepper text='Select Mode' currentStep={1} steps={3} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={styles.gameOptionsContainer}
        spacing={2}
      >
        <Grid item xs={1} />
        {gameMode.map((mode) => (
          <Grid item xs={4}>
            <GlowBox
              style={{ backgroundColor: '#121542', ...styles }}
              onClick={() => navigate(mode.route)}
            >
              <div className={styles.raceTypeCard} style={{ ...styles }}>
                <img
                  src={mode.image}
                  style={{ width: '100%', height: '220px' }}
                />
                <Text
                  type={'h3'}
                  className={styles.raceTypeText}
                  align={'center'}
                >
                  {mode.text}
                </Text>
              </div>
            </GlowBox>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ChooseMode;
