import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { sortPlayersRank } from 'src/pages/race/outlet/single/trackDetail/trackDetail.logic';
import {
  setActiveBike,
  setActiveRaceDetail,
  setRaceResult,
} from 'src/redux/raceSlice';
import { AppDispatch } from 'src/redux/store';
import { gameAPI } from 'src/utils/constants/url';
import { devLog } from 'src/utils/helper';

// async function getRaceHistory(
//   signature: SignatureType,
//   currentPage: number,
//   setRaceHistory: Dispatch<SetStateAction<RaceHistoryData[]>>,
//   setPagination: Dispatch<SetStateAction<PaginationStateType>>
// ) {
//   try {
//     const page = currentPage;
//     const perPage = 10;
//     const sortField = 'createdAt';
//     const sortType = 'desc';
//     const response = await axios({
//       method: 'GET',
//       headers: {
//         pubkey: signature.pubkey,
//         message: signature.message,
//         signature: signature.signature,
//       },
//       url: `${gameAPI}/v1/races/?account=${signature.pubkey}&page=${page}&perPage=${perPage}&sortField=${sortField}&sortType=${sortType}`,
//     });
//     const raceHistories = response.data.data.docs.map((data: any) => {
//       const date = new Date(data.createdAt).toLocaleString();
//       const eachResult: RaceHistoryData = {
//         bikeId: data.bike.tokenId,
//         raceTrack: data.raceTrack.name,
//         imageUrl: data.raceTrack.imageUrl,
//         position: data.raceResult.position,
//         raceStatus: data.status,
//         date: date,
//         prize: data.raceResult.prize,
//       };
//       return eachResult;
//     });
//     setRaceHistory(raceHistories);
//     setPagination({
//       hasNextPage: response.data.data.hasNextPage,
//       hasPrevPage: response.data.data.hasPrevPage,
//       nextPage: response.data.data.nextPage,
//       prevPage: response.data.data.prevPage,
//       page: response.data.data.page,
//       totalPages: response.data.data.totalPages,
//     });
//   } catch (err) {
//     devLog(err);
//     return [];
//   }
// }

async function getRaceInProgress(
  headers: any,
  setRaceInProgress?: Dispatch<SetStateAction<boolean>>,
  dispatch?: AppDispatch
) {
  const params = {
    page: 1,
    perPage: 1,
  };
  try {
    const response = await axios({
      method: 'GET',
      url: `${gameAPI}/v1/races/active-race`,
      headers,
      params,
    });
    if (dispatch) {
      dispatch(setActiveBike(response.data.data.bike));
      dispatch(
        setActiveRaceDetail({
          tokenId: response.data.data.bike.tokenId,
          opponents: response.data.data.bots,
        })
      );
    }
    setRaceInProgress && setRaceInProgress(true);
  } catch (err: any) {
    dispatch &&
      dispatch(
        setActiveRaceDetail({
          tokenId: '',
          opponents: [],
        })
      );
    if (err.response.data.message === 'No active race') {
      devLog('no active race');
      setRaceInProgress && setRaceInProgress(false);
    } else {
      devLog('get active race failed');
    }
  }
}

async function joinRace(
  headers: any,
  dispatch: AppDispatch,
  data: {
    tokenId: string;
    trackNumber: string;
  }
) {
  try {
    const response = await axios({
      method: 'POST',
      url: `${gameAPI}/v1/races/join`,
      headers,
      data,
    });
    dispatch(setActiveBike(response.data.data.bike));
    dispatch(
      setActiveRaceDetail({
        tokenId: response.data.data.bike.tokenId,
        opponents: response.data.data.bots,
      })
    );
    return response;
  } catch (err: any) {
    return err;
  }
}

async function startRace(headers: any, dispatch: AppDispatch) {
  try {
    const response = await axios({
      method: 'POST',
      url: `${gameAPI}/v1/races/start`,
      headers,
    });
    dispatch(
      setRaceResult({
        isEvolve: response.data.data.raceResult.isEvolve,
        isLevelUp: response.data.data.raceResult.isLevelUp,
        prize: {
          coin: response.data.data.raceResult.prize.coin,
          exp: response.data.data.raceResult.prize.exp,
        },
        results: sortPlayersRank(
          response.data.data.raceResult.results,
          response.data.data.bots
        ),
        levelUpData: response.data.data.levelUpData,
        newRaceTrack: response.data.data.newRaceTrack?.raceTrack,
      })
    );
    return response;
  } catch (err: any) {
    return err;
  }
}

export { getRaceInProgress, joinRace, startRace };
