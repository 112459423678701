import melContract from '@project/contracts/deployments/mumbai/DummyMel.json';
import gachaContractProxy from '@project/contracts/deployments/mumbai/GachaProxy.json';
import gachaContract from '@project/contracts/deployments/mumbai/GachaV1.json';
import nftContractProxy from '@project/contracts/deployments/mumbai/MelXNFTProxy.json';
import nftContract from '@project/contracts/deployments/mumbai/MelXNFTV1.json';
import raceContractProxy from '@project/contracts/deployments/mumbai/RaceProxy.json';
import raceContract from '@project/contracts/deployments/mumbai/RaceV1.json';
import singleRaceContractProxy from '@project/contracts/deployments/mumbai/SinglePlayerProxy.json';
import singleRaceContract from '@project/contracts/deployments/mumbai/SinglePlayerV1.json';
import storeContractProxy from '@project/contracts/deployments/mumbai/StoreProxy.json';
import storeContract from '@project/contracts/deployments/mumbai/StoreV1.json';
import gameItemContract from '@project/contracts/deployments/mumbai/GameItemV1.json';
import faucetContract from '@project/contracts/deployments/mumbai/Faucet.json';

export const mel = {
  address: melContract.address,
  abi: melContract.abi,
};

export const gacha = {
  address: gachaContractProxy.address,
  abi: gachaContract.abi,
};

export const nft = {
  address: nftContractProxy.address,
  abi: nftContract.abi,
};

export const store = {
  address: storeContractProxy.address,
  abi: storeContract.abi,
};

export const race = {
  address: raceContractProxy.address,
  abi: raceContract.abi,
};

export const singleRace = {
  address: singleRaceContractProxy.address,
  abi: singleRaceContract.abi,
};

export const gameItem = {
  address: '0xF859a7724bb14Ba133Af07AF2D82d238cC43771a',
  abi: gameItemContract.abi,
};

export const faucet = {
  address: faucetContract.address,
  abi: faucetContract.abi,
};
