import type { ReferralSubGraphData } from 'src/utils/interface';

/**
 * @description to get Referral Data from SubGraph
 * @param fetchingFunction / contains useLazyQuery hook
 * @param currentAccount / contains Account state from redux
 * @returns Referral Data from Subgraph
 */
async function fetchingReferralData(
  fetchingFunction: any,
  currentAccount: string
): Promise<ReferralSubGraphData[]> {
  const subGraphData = await fetchingFunction({
    variables: {
      user: currentAccount,
    },
  });
  const subGraphReferral: ReferralSubGraphData[] = subGraphData.data
    ? subGraphData.data.user.referralRewards
    : [];

  return subGraphReferral;
}

/**
 * @description to get "TOTAL USERS USED"
 * @param items / SubGraphData from fetchingReferralData function
 * @param currentAccount / contains Account state from redux
 * @returns total of unique minter
 */
function getTotalUsersUsed(
  items: ReferralSubGraphData[],
  currentAccount: string
): number {
  const uniqueUsers: string[] = [];
  items.map((i: ReferralSubGraphData) => {
    if (i.referral.id == currentAccount) {
      if (!uniqueUsers.includes(i.minter.id)) {
        uniqueUsers.push(i.minter.id);
      }
    }
  });
  return uniqueUsers.length;
}

/**
 * @description to get "TOTAL REFERRAL USED"
 * @param items  / SubGraphData from fetchingReferralData function
 * @param currentAccount / contains Account state from redux
 * @returns total of referral used
 */
function getTotalReferralUsed(
  items: ReferralSubGraphData[],
  currentAccount: string
): number {
  let timesReferralUsed = 0;
  items.map((i: ReferralSubGraphData) => {
    if (i.referral.id == currentAccount) {
      timesReferralUsed++;
    }
  });
  return timesReferralUsed;
}

export { fetchingReferralData, getTotalReferralUsed, getTotalUsersUsed };
