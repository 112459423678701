import axios from 'axios';
import { BigNumber, ethers } from 'ethers';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { nft, gacha } from 'src/utils/constants/addresses';
import { gameAPI } from 'src/utils/constants/url';
import type { NFTProduct } from 'src/utils/interface';
/**
 *
 * @returns string Metamask Account
 */
async function getAccount(): Promise<string> {
  const [account]: string[] = await window.ethereum.request({
    method: 'eth_requestAccounts',
  });
  return account;
}

/**
 *
 * @param contract / initialized NFT Contract
 * @param account / string Metamask Account
 * @returns total number of NFT account has
 */
async function getNftTotalCount(
  contract: ethers.Contract,
  account: string
): Promise<number> {
  const nftBalanceBigNumber = await contract.balanceOf(account);
  const nftTotalCount = Number(
    ethers.utils.formatUnits(nftBalanceBigNumber, 0)
  );
  return nftTotalCount;
}

/**
 *
 * @param account / string Metamask Account
 * @param currentPage / which page to fetch
 * @param showMultiplier / how many data to fetch
 * @returns arrays of NFTs from fetching
 */
async function fetchNftData(
  page: number,
  perPage: number,
  headers: any,
  statusWithParts = true
): Promise<NFTProduct[]> {
  const params = {
    page,
    perPage,
    statusWithParts,
  };
  const response = await axios({
    method: 'GET',
    url: `${gameAPI}/v1/nfts`,
    params,
    headers,
  });
  const nftProduct: NFTProduct[] = response.data.data.docs;
  return nftProduct;
}

async function getNFTBalance(currentAccount: string): Promise<number> {
  const nftContract = initiateContract(nft.address, nft.abi);

  const nftBalance: BigNumber = await nftContract.balanceOf(currentAccount);

  const nftBalanceNumber = Number(nftBalance.toString());

  return nftBalanceNumber;
}

async function getNftMintingFee(): Promise<BigNumber> {
  const gachaContract = initiateContract(gacha.address, gacha.abi);

  const mintingFee: BigNumber = await gachaContract.feeAmount();

  return mintingFee;
}

export {
  fetchNftData,
  getAccount,
  getNFTBalance,
  getNftMintingFee,
  getNftTotalCount,
};
