import { images, svgs } from 'src/assets';
import { formatStringToDecimal } from 'src/utils/helper';

import Text from 'src/components/lofi/text';

import styles from './racePrizeSection.module.scss';
import { RacePrizeSectionProps } from './racePrizeSection.type';

export default function RacePrizeSection(props: RacePrizeSectionProps) {
  const { exp, coin } = props;
  return (
    <div className={styles.prize}>
      <div className={styles.prizeBox}>
        <div className={styles.boxUp} style={{ marginRight: 25 }}>
          <img src={images.expRace} />
          <Text
            align={'center'}
            className={styles.prizeValue}
          >{`${exp} XP`}</Text>
        </div>
        <div className={styles.boxUp}>
          <img src={svgs.melCoinIcon} />
          <Text
            align={'center'}
            className={styles.prizeValue}
          >{`${formatStringToDecimal(coin.toString())} MEL`}</Text>
        </div>
      </div>
    </div>
  );
}
