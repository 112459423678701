import {
  setEnergyBalanceEmpty,
  setEnoughMelFalse,
  setEnoughMelTrue,
  setGameCoinBalanceEmpty,
  setGarageLimit,
  setHasNft,
  setLoginTrue,
  setMelBalance,
  setMelBalanceEmpty,
  setMelCoinBalanceEmpty,
  setNftCount,
  setSignatureEmpty,
  setWhitelistStatus,
  setWhitelistStatusEmpty,
} from 'src/redux/accountMetamaskSlice';
import { setModalContent } from 'src/redux/modalContentSlice';
import { setBikeCollection } from 'src/redux/raceSlice';
import type { AppDispatch } from 'src/redux/store';
import { isMelBalanceEnoughToMint } from 'src/services/contract/erc20/mel';
import { getMelBalance } from 'src/services/contract/erc20/mel';
import { getWhitelistStatus } from 'src/services/contract/metamask/account';
import {
  checkNetwork,
  getAccount,
  switchNetwork,
} from 'src/services/contract/metamask/account';
import {
  modalMetamaskNotInstalled,
  modalUserRejectedTheApproval,
} from 'src/utils/constants/errorMessageConstants';
import { formatBigNumberToString } from 'src/utils/helper';

const connectMetamask = async (dispatch: AppDispatch) => {
  // set isWhitelisted to False
  dispatch(setWhitelistStatusEmpty());
  //? if metamask is not installed
  if (typeof window.ethereum === 'undefined') {
    dispatch(setModalContent(modalMetamaskNotInstalled));
    return;
  }
  // get account
  const account = await getAccount();
  //? if user rejected login approval
  if (account === '4001') {
    dispatch(setModalContent(modalUserRejectedTheApproval));
    return;
  }
  dispatch(setLoginTrue(account));
  // check network
  const network = await checkNetwork();
  if (network != process.env.REACT_APP_METAMASK_CHAINID) {
    switchNetwork(dispatch);
  }
  // get mel balance
  const melBalance = await getMelBalance(account);
  const melBalanceString: string = formatBigNumberToString(melBalance);

  dispatch(setMelBalance(melBalanceString));
  // set isWhitelisted state
  const whitelistStatus = await getWhitelistStatus(account);
  dispatch(setWhitelistStatus(whitelistStatus));
  // set isEnoughMel state
  const isMelEnoughToMint = await isMelBalanceEnoughToMint(account);
  if (isMelEnoughToMint) {
    dispatch(setEnoughMelTrue());
  } else {
    dispatch(setEnoughMelFalse());
  }
};

const logOutAccount = (dispatch: AppDispatch) => {
  dispatch(setEnoughMelFalse());
  dispatch(setMelBalanceEmpty());
  dispatch(setWhitelistStatusEmpty());
  dispatch(setEnergyBalanceEmpty());
  dispatch(setMelCoinBalanceEmpty());
  dispatch(setGameCoinBalanceEmpty());
  dispatch(setSignatureEmpty());
  dispatch(setNftCount(0));
  dispatch(setGarageLimit(0));
  dispatch(setHasNft(false));
  dispatch(setBikeCollection([]));
};

export { connectMetamask, logOutAccount };
