import { Box } from '@mui/material';
import * as React from 'react';
import Lottie, { Options } from 'react-lottie';

import GradientModal from '../gradientModal';
import GradientButton from '../../gradientButton';
import Text from '../../text';

export type CustomModalContentProps = {
  lottieAnimation: any;
  title: string;
  message: string;
  buttonText: string;
  buttonFunction?: () => void;
};

type CustomModalProps = {
  modalContent: CustomModalContentProps;
  open: boolean;
  close: () => void;
};

export const defaultCustomModalContent = {
  title: '',
  message: '',
  lottieAnimation: null,
  buttonText: 'Close',
  buttonFunction: () => null,
};

const CustomModal = (props: CustomModalProps) => {
  const {
    modalContent: {
      lottieAnimation,
      title,
      message,
      buttonText,
      buttonFunction,
    },
    open,
    close,
  } = props;

  const lottieAnimationInstance: Options = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <GradientModal
      open={open}
      style={{ padding: '30px 10px 30px', minHeight: 'unset' }}
    >
      <>
        <Box sx={{ marginBottom: '0.5rem' }}>
          <Lottie options={lottieAnimationInstance} width={180}></Lottie>
        </Box>
        <Text type={'h2'} align={'center'}>
          {title}
        </Text>
        <Text align={'center'} style={{ marginBottom: 30 }}>
          {message}
        </Text>

        {buttonText && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <GradientButton
              title={buttonText}
              onClick={() => (buttonFunction ? buttonFunction() : close())}
              style={{ marginBottom: 15 }}
            />
          </Box>
        )}
      </>
    </GradientModal>
  );
};

export default CustomModal;
