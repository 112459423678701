import { BigNumber } from 'ethers';

export interface MintingRequirementType {
  fee: BigNumber;
  minimalMel: BigNumber;
}

export const defaultMintingRequirement = {
  fee: BigNumber.from(0),
  minimalMel: BigNumber.from(0),
};
