import TwitterIcon from '@mui/icons-material/Twitter';
import { Button } from '@mui/material';
import React from 'react';

import Tooltip from 'src/components/lofi/tooltip';

interface ShareToTwitter {
  twitterIntent: string;
}

const ShareToTwitter = (props: ShareToTwitter) => {
  const { twitterIntent = '' } = props;
  const twitterIntentFormated = twitterIntent.replace('%20', ' ');

  return (
    <Tooltip title='Share to Twitter' arrow>
      <a
        href={`https://twitter.com/intent/tweet?text=${twitterIntentFormated}`}
        target='_blank'
      >
        <Button
          variant='outlinedGradient'
          sx={{
            backgroundColor: 'transparent',
            width: 'fit-content',
            minWidth: 'unset',
            padding: '0.5rem 0.5rem',
            '&:before': {
              minWidth: 'unset',
              padding: '0.1rem !important',
            },
          }}
        >
          <TwitterIcon sx={{ color: '#55ACEE' }} />
        </Button>
      </a>
    </Tooltip>
  );
};

export default ShareToTwitter;
