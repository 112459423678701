import { AppDispatch } from 'src/redux/store';
import {
  checkNetwork,
  switchNetwork,
} from 'src/services/contract/metamask/account';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { store } from 'src/utils/constants/addresses';
import { waitingApproveTransaction } from 'src/utils/constants/loadingMessageConstant';
import { getTxError } from 'src/utils/helper';

import {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';

const consumeVoucher = async (
  nftId: string,
  voucherId: string,
  waitingModalContent: ModalProps,
  setWaitingModalContent: React.Dispatch<React.SetStateAction<ModalProps>>,
  dispatch: AppDispatch
) => {
  const storeContract = initiateContract(store.address, store.abi);
  const network = await checkNetwork();
  if (network != process.env.REACT_APP_METAMASK_CHAINID) {
    await switchNetwork(dispatch);
  }

  try {
    setWaitingModalContent({
      ...waitingModalContent,
      ...waitingApproveTransaction,
    });

    const useVoucher = await storeContract.useItem(nftId, voucherId, 1);

    setWaitingModalContent({
      ...defaultModalContent,
      openStatus: true,
      title: 'Waiting for transaction to complete',
      // message: 'You have successfully used the voucher',
      showViewInventory: false,
    });

    await useVoucher.wait();
  } catch (err: any) {
    const modalContent = getTxError(err);

    setWaitingModalContent({
      ...waitingModalContent,
      ...modalContent,
    });

    return;
  }

  setWaitingModalContent({
    ...defaultModalContent,
    openStatus: true,
    title: 'Transaction Success',
    message: 'You have successfully used the voucher',
    showViewInventory: false,
  });
};

export { consumeVoucher };
