import type { TextProps } from './text.type';
import styles from './text.module.scss';

function Text(props: TextProps) {
  const { type, children, className, color, align, style } = props;

  const renderType = () => {
    if (!type) {
      return 'p';
    } else {
      return type;
    }
  };

  const renderAlign = () => {
    switch (align) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  };

  return (
    <div
      className={`${styles[`${`textComp-${renderType()}`}`]} ${
        className ?? ''
      }`}
      style={{ color, justifyContent: renderAlign(), ...style }}
    >
      {children}
    </div>
  );
}

export default Text;
