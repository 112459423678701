import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RaceResultProps } from 'src/pages/race/outlet/single/result/result.type';
import {
  ActiveBikeProps,
  OpponentProps,
} from 'src/pages/race/outlet/single/trackDetail/trackDetail.type';
import { RaceTrackProps } from 'src/pages/race/outlet/single/tracks/tracks.type';
import { OwnedBikeProps } from 'src/pages/workshop/workshop.type';

import type { RootState } from '../redux/store';

interface ActiveRaceProps {
  tokenId: string;
  opponents: OpponentProps[];
}

interface TypeRace {
  playerRaceTracks: RaceTrackProps[];
  selectedTrack: RaceTrackProps | undefined;
  bikeCollection: OwnedBikeProps[];
  activeBike: ActiveBikeProps | undefined;
  activeRaceDetail: ActiveRaceProps | undefined;
  raceResult: RaceResultProps | undefined;
}

const initialState: TypeRace = {
  playerRaceTracks: [],
  selectedTrack: undefined,
  bikeCollection: [],
  activeBike: undefined,
  activeRaceDetail: undefined,
  raceResult: undefined,
};

export const raceSlice = createSlice({
  name: 'race',
  initialState,
  reducers: {
    setPlayerRaceTracks: (state, action: PayloadAction<RaceTrackProps[]>) => {
      state.playerRaceTracks = action.payload;
    },
    setBikeCollection: (state, action: PayloadAction<OwnedBikeProps[]>) => {
      state.bikeCollection = action.payload;
    },
    setSelectedTrack: (state, action: PayloadAction<RaceTrackProps>) => {
      state.selectedTrack = action.payload;
    },
    setActiveBike: (
      state,
      action: PayloadAction<ActiveBikeProps | undefined>
    ) => {
      state.activeBike = action.payload;
    },
    setActiveRaceDetail: (
      state,
      action: PayloadAction<{
        tokenId: string;
        opponents: OpponentProps[];
      }>
    ) => {
      state.activeRaceDetail = {
        tokenId: action.payload.tokenId,
        opponents: action.payload.opponents,
      };
    },
    setRaceResult: (state, action: PayloadAction<RaceResultProps>) => {
      state.raceResult = action.payload;
    },
    resetRaceState: (state) => {
      state.raceResult = undefined;
      state.activeRaceDetail = undefined;
    },
  },
});

export const {
  setPlayerRaceTracks,
  setBikeCollection,
  setSelectedTrack,
  setActiveBike,
  setActiveRaceDetail,
  setRaceResult,
  resetRaceState,
} = raceSlice.actions;

// Selections - This is how we pull information from the Global store slice
export const selectPlayerRaceTracks = (state: RootState) =>
  state.race.playerRaceTracks;

export const selectCurrentTrack = (state: RootState) =>
  state.race.selectedTrack;

export const selectOwnedBike = (state: RootState) => state.race.bikeCollection;

export const selectActiveBike = (state: RootState) => state.race.activeBike;

export const selectActiveRaceDetail = (state: RootState) =>
  state.race.activeRaceDetail;

export const selectRaceResult = (state: RootState) => state.race.raceResult;

export default raceSlice.reducer;
