import { createTheme, responsiveFontSizes } from '@mui/material/styles';

/* * need to use module augmentation for the theme to accept the value that just added **/
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true;
    primary: true;
    outlinedGradient: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    raspberry: Palette['primary'];
    fuchsia: Palette['primary'];
    primaryLinearGradient: Palette['primary'];
  }
  interface PaletteOptions {
    raspberry: PaletteOptions['primary'];
    fuchsia: PaletteOptions['primary'];
    primaryLinearGradient: PaletteOptions['primary'];
  }
}

const primaryGradient = `linear-gradient(
  89.84deg,
  #ffdb01 -46.23%,
  #ff851f 5.03%,
  #ff3d3d 38.29%,
  #ff0f6f 74.04%,
  #fe01fa 129.67%
)`;

const fluorescentBlue = '#14F2E9';

let theme = createTheme();

//#region  //*=========== Typhography ===========

//* edit your typography here
const typography: any = createTheme(theme, {
  fontFamily: ['"Inter"', 'Roboto', 'sans-serif'].join(','),
  h1: {
    fontSize: '96px',
    fontWeight: 700,
  },
  h2: {
    fontSize: '60px',
    fontWeight: 700,
  },
  h3: {
    fontSize: '48px',
    fontWeight: 700,
  },
  h4: {
    fontSize: '32px',
    fontWeight: 700,
  },
  h5: {
    fontSize: '24px',
    fontWeight: 700,
  },
  h6: {
    fontSize: '20px',
    fontWeight: 700,
    color: fluorescentBlue,
  },
  subtitle1: {
    fontSize: '16px',
    fontWeight: 700,
    color: fluorescentBlue,
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 700,
  },
});

//* add typhography to your instance
theme = createTheme({
  typography,
});
//#endregion  //*======== Typhography ===========

//#region  //*=========== Mui Color Palette ===========
//* edit your color palette here
const palette: any = createTheme(theme, {
  raspberry: {
    main: '#FF0F6F',
  },
  primaryLinearGradient: {
    main: `linear-gradient(
      89.84deg,
      #ffdb01 -46.23%,
      #ff851f 5.03%,
      #ff3d3d 38.29%,
      #ff0f6f 74.04%,
      #fe01fa 129.67%
    )`,
  },
  fuchsia: {
    main: '#FE01FA',
  },
});
//* add colorPalette to your instance
theme = createTheme({
  palette,
});
//#endregion  //*======== Mui Color Palette ===========

//#region  //*=========== Mui Components ===========
//edit your components here
const outlinedGradientButton = [
  {
    props: { variant: 'outlinedGradient' },
    style: {
      position: 'relative',
      color: 'white',
      fontWeight: '700',
      minWidth: '11.75rem',
      fontSize: '1.375rem',
      backgroundColor: 'rgba(244, 236, 236, 0.2)',

      '&:hover': {
        backgroundColor: 'rgba(244, 236, 236, 0.2)',
      },

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        minWidth: '11.75rem',
        padding: '0.125rem !important',
        background:
          'linear-gradient(89.84deg, #FFDB01 -46.23%, #FF851F 5.03%, #FF3D3D 38.29%, #FF0F6F 74.04%, #FE01FA 129.67%)',
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0) !important',
        WebkitMaskComposite: 'xor !important',
        borderRadius: '0.75rem',
      },
    },
  },
  {
    props: { variant: 'outlinedGradient', size: 'small' },
    style: {
      fontSize: '0.875rem',
      padding: '0.8125rem 1.5rem',
    },
  },
  {
    props: {
      variant: 'outlinedGradient',
      disabled: true,
    },
    style: {
      '&.Mui-disabled': {
        color: 'white',
      },
      '&:after': {
        opacity: '0.5',
      },
    },
  },
];

const primaryButton = [
  {
    props: { variant: 'primary' },
    style: {
      backgroundImage: primaryGradient,
      color: 'white',
      backgroundSize: '300% 100%',
      transition: 'all 0.4s ease-in-out',
      fontSize: '1.375rem',
      fontWeight: '700',
      minWidth: '11.75rem',

      '&:hover': {
        backgroundImage: primaryGradient,
        transition: 'all 0.4s ease-in-out',
        backgroundPosition: '100% 0',
      },
    },
  },
  {
    props: { variant: 'primary', size: 'small' },
    style: {
      fontSize: '0.875rem',
      padding: '0.8125rem 1.5rem',
    },
  },
  {
    props: { variant: 'primary', size: 'large' },
    style: {
      padding: '8px 22px',
    },
  },
  {
    props: {
      variant: 'primary',
      disabled: true,
    },
    style: {
      '&.Mui-disabled': {
        color: 'white',
        opacity: '0.5',
      },
    },
  },
];

const components: any = createTheme(theme, {
  MuiButton: {
    //* add variants to button component
    variants: [
      {
        props: { variant: 'dashed' },
        style: {
          border: `2px dashed ${theme.palette.primary.main}`,
        },
      },
      ...primaryButton,
      ...outlinedGradientButton,
    ],

    //* override MuiButton root style
    styleOverrides: {
      root: {
        textTransform: 'initial',
        borderRadius: '0.75rem',
        padding: '0.9375rem 1.5rem',
        fontWeight: '700',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundColor: '#3F4A5C',
        borderRadius: '30px',
        color: 'white',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#b82cff',
      },
      arrow: {
        color: '#b82cff',
      },
    },
  },
});

//* Create a theme instance for components
theme = createTheme({
  components,
});
//#endregion  //*======== Mui Components ===========

// Create overall theme instance
theme = createTheme({
  typography,
  palette,
  components,
});
// add responsive MUI responsiveFontSize
theme = responsiveFontSizes(theme);

export default theme;
