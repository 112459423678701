// ! ESSENTIALS
// ! DATABASE
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
// ! UI
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
// ! STATE MANAGEMENT
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import './styles/index.scss';

import App from './App';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import theme from '../src/utils/muiTheme';

export const client = new ApolloClient({
  uri: process.env.REACT_APP_SUBGRAPH_URL,
  cache: new InMemoryCache(),
});

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          {/* Your component tree. Now you can override MUI's styles with scss */}
          <CssBaseline />
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ApolloProvider client={client}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ApolloProvider>
            </PersistGate>
          </Provider>
        </StyledEngineProvider>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
