import { useQuery } from '@apollo/client';
import { ButtonBase, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { svgs } from 'src/assets';
import styles from 'src/pages/workshop/workshop.module.scss';
import { selectAccountMelCoinBalance } from 'src/redux/accountMetamaskSlice';
import {
  selectWorkshopTabState,
  setWorkshopTab,
} from 'src/redux/gameInterfaceSlice';
import { RootState } from 'src/redux/store';
import { buyPart } from 'src/services/contract/game/part';
import {
  GET_USER_VOUCHERS,
  GET_VOUCHERS,
  GET_NFT_USER,
} from 'src/utils/constants/query';
import { formatDecimal } from 'src/utils/helper';

import BikeDetail from 'src/components/hifi/bikeDetail';
import PageHeader from 'src/components/hifi/pageHeader';
import PartDetail from 'src/components/hifi/partDetail';
import { MelCoinIcon } from 'src/components/Icon';
import GlowBox from 'src/components/lofi/box/glowBox';
import TabButton from 'src/components/lofi/button/tabButton';
import BikeCard from 'src/components/lofi/card/bikeCard';
import SparepartCard from 'src/components/lofi/card/sparepartCard';
import GradientButton from 'src/components/lofi/gradientButton';
import ConfirmModal from 'src/components/lofi/modal/confirmModal';
import GradientModal from 'src/components/lofi/modal/gradientModal';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';
import Text from 'src/components/lofi/text';

import {
  defaultSelectedOwnedBike,
  defaultSelectedPart,
  defaultSelectedShopPart,
} from './workshop.constant';
import type {
  OwnedBikeProps,
  OwnedPartProps,
  SelectedShopPartProps,
  UserPartProps,
} from './workshop.type';

function Workshop() {
  const dispatch = useDispatch();
  const { account } = useSelector((state: RootState) => state);
  const currentMelCoin = useSelector(selectAccountMelCoinBalance);
  const tabState = useSelector(selectWorkshopTabState);
  //For now hardcoded, we didnt handle wait time when get the ipfs url from onchain
  const gatewayUrl = 'https://cf-ipfs.com/ipfs/';

  const { data: vouchers, refetch: refetchVouchers } = useQuery(GET_VOUCHERS, {
    fetchPolicy: 'network-only',
  });

  const { data: userVouchers, refetch: refetchUserVouchers } = useQuery(
    GET_USER_VOUCHERS,
    {
      fetchPolicy: 'network-only',
      variables: {
        userAddress: account.account,
      },
    }
  );

  const { data: userNfts, refetch: refetchOwnedBikes } = useQuery(
    GET_NFT_USER,
    {
      fetchPolicy: 'network-only',
      variables: {
        owner: account.account,
      },
    }
  );

  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);
  const [selectedPart, setSelectedPart] =
    useState<SelectedShopPartProps>(defaultSelectedPart);
  const [showBuyPart, setShowBuyPart] = useState<boolean>(false);

  const [ownedBike, setOwnedBike] = useState<OwnedBikeProps[]>([]);
  const [selectedOwnedBike, setSelectedOwnedBike] = useState<OwnedBikeProps>(
    defaultSelectedOwnedBike
  );
  const [showOwnedBike, setShowOwnedBike] = useState<boolean>(false);
  const [ownedPart, setOwnedPart] = useState<UserPartProps[]>([]);
  const [selectedOwnedPart, setSelectedOwnedPart] = useState<OwnedPartProps>(
    defaultSelectedShopPart
  );
  const [showOwnedPart, setShowOwnedPart] = useState<boolean>(false);

  const closeModalHandle = () => {
    setWaitingModalContent(defaultModalContent);
  };

  const changeTabStateHandle = (value: string) => {
    dispatch(setWorkshopTab(value));
  };

  const setSelectedShopPartHandle = (
    id: string,
    name: string,
    price: string
  ) => {
    const partProps: SelectedShopPartProps = {
      id,
      name,
      price,
    };
    setSelectedPart(partProps);
    setShowBuyPart(true);
  };

  const buyPartHandle = async (
    id: string,
    price: number,
    setWaitingModalContent: Dispatch<SetStateAction<ModalProps>>
  ) => {
    closeBuyPart();
    await buyPart(id, price, setWaitingModalContent);
  };

  const closeBuyPart = () => {
    setSelectedPart(defaultSelectedPart);
    setShowBuyPart(false);
  };

  // OWNED BIKE
  const showBikeDetailHandle = async (bike: OwnedBikeProps) => {
    setSelectedOwnedBike(bike);
    setShowOwnedBike(true);
  };

  useEffect(() => {
    refetchVouchers();
  });

  useEffect(() => {
    refetchUserVouchers();
  });

  useEffect(() => {
    refetchOwnedBikes();
  });

  useEffect(() => {
    userNfts && setOwnedBike(userNfts.nfts);
  }, [userNfts]);

  useEffect(() => {
    userVouchers && setOwnedPart(userVouchers.user.vouchers);
  }, [userVouchers]);

  return (
    <main className={styles.workshopSection}>
      {/* NAVBAR SECTION  */}
      <PageHeader pageTitle='Workshop' />
      {/* CONTENT SECTION  */}
      <Grid container spacing={2} className={styles.mainContainer}>
        <Grid item xs={2}>
          <article className={styles.tabSection}>
            <ButtonBase
              onClick={() => changeTabStateHandle('shop')}
              className={styles.tabButton}
            >
              <TabButton data={{ value: 'Shop' }} state={tabState} />
            </ButtonBase>
            <ButtonBase
              onClick={() => changeTabStateHandle('modify')}
              className={styles.tabButton}
            >
              <TabButton data={{ value: 'Modify' }} state={tabState} />
            </ButtonBase>
            <ButtonBase
              onClick={() => changeTabStateHandle('inventory')}
              className={styles.tabButton}
            >
              <TabButton data={{ value: 'Inventory' }} state={tabState} />
            </ButtonBase>
          </article>
        </Grid>
        <Grid item xs={10} className={styles.contentSection}>
          {tabState === 'shop' && (
            <Grid container spacing={4}>
              {vouchers &&
                vouchers.vouchers.map(
                  (voucher: OwnedPartProps, index: number) => (
                    <Grid item xs={4} key={`shop-item${index}`}>
                      <GlowBox
                        onClick={() =>
                          setSelectedShopPartHandle(
                            voucher.id,
                            voucher.name,
                            Number(formatDecimal(voucher.price)).toFixed(2)
                          )
                        }
                      >
                        <SparepartCard
                          voucherMeta={{ amount: 0, voucher }}
                          showPrice
                          gatewayUrl={gatewayUrl}
                        />
                      </GlowBox>
                    </Grid>
                  )
                )}
            </Grid>
          )}
          {tabState === 'modify' && (
            <Grid container spacing={4}>
              {ownedBike.map((bike: OwnedBikeProps, index: number) => (
                <Grid item xs={4} key={`modify-item${index}`}>
                  <BikeCard
                    data={bike}
                    onClick={() => showBikeDetailHandle(bike)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          {tabState === 'inventory' && (
            <Grid container spacing={4}>
              {userVouchers && userVouchers.user.vouchers.length !== 0 ? (
                userVouchers.user.vouchers.map(
                  (voucher: UserPartProps, index: number) => (
                    <Grid item xs={4} key={`inventory-item${index}`}>
                      <GlowBox>
                        <SparepartCard
                          gatewayUrl={gatewayUrl}
                          voucherMeta={voucher}
                        />
                      </GlowBox>
                    </Grid>
                  )
                )
              ) : (
                <div className={styles.emptyInventory}>
                  <img src={svgs.emptyBox} />
                  <Text className={styles.title}>Your inventory is empty</Text>
                  <Text className={styles.desc}>
                    Parts that you purchase will appear here
                  </Text>
                  <GradientButton
                    title={'Go to Shop'}
                    onClick={() => changeTabStateHandle('shop')}
                  />
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* Modal for Purchase Confirmation */}
      <ConfirmModal
        open={showBuyPart}
        modalTitle={'Confirm purchase'}
        modalDetailText={`Are you sure want to buy ${selectedPart.name}?`}
        onCancelButtonClick={() => setShowBuyPart(false)}
        onConfirmButtonClick={() =>
          buyPartHandle(
            selectedPart.id,
            Number(selectedPart.price),
            setWaitingModalContent
          )
        }
        confirmButtonTitle={
          <div className={styles.confirmBtnContent}>
            {`Purchase`}
            <div className={styles.cost}>
              <MelCoinIcon length={'20px'} />
              {`${selectedPart.price}`}
            </div>
          </div>
        }
        notEnoughFund={Number(selectedPart.price) > Number(currentMelCoin)}
      />
      <GradientModal
        open={showOwnedBike}
        onClose={() => setShowOwnedBike(false)}
      >
        <BikeDetail
          bike={selectedOwnedBike}
          parts={ownedPart}
          close={() => setShowOwnedBike(false)}
          refetchUserVouchers={refetchUserVouchers}
          refetchOwnedBikes={refetchOwnedBikes}
        />
      </GradientModal>
      {/* Modal for Part Detail */}
      <GradientModal
        open={showOwnedPart}
        onClose={() => setShowOwnedPart(false)}
      >
        <PartDetail {...selectedOwnedPart} />
      </GradientModal>
      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={closeModalHandle}
      />
    </main>
  );
}

export default Workshop;
