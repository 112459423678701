import { ModalMessage } from 'src/interfaces/modal-interface';

const modalMetamaskNotInstalled: ModalMessage = {
  title: "You haven't installed Metamask yet",
  message: 'Please install metamask to your browser',
  showViewInventory: false,
  openStatus: true,
};

const modalWrongNetwork: ModalMessage = {
  title: 'Wrong network',
  message: 'Please switch your network to Polygon testnet.',
  showViewInventory: false,
  openStatus: true,
};

const modalUserRejectedTheApproval: ModalMessage = {
  title: 'User rejected the approval',
  message: 'Please try again or check your network',
  showViewInventory: false,
  openStatus: true,
};

const modalAddPolygonTestnet: ModalMessage = {
  title: 'Wrong network',
  message: 'Please add Polygon testnet.',
  showViewInventory: false,
  openStatus: true,
};

const transactionFailed: ModalMessage = {
  title: 'Transaction failed',
  message: `It's not your fault, please try again later`,
  showViewInventory: false,
  openStatus: true,
};

const notEnoughMel: ModalMessage = {
  title: 'Not enough MEL',
  message: 'Please top up your MEL',
  showViewInventory: false,
  openStatus: false,
};

const garageLimitReached: ModalMessage = {
  title: 'Garage limit reached',
  message: 'Please upgrade your garage limit',
  showViewInventory: false,
  openStatus: false,
};

const notWhitelisted: ModalMessage = {
  title: 'Whitelisted Account Only',
  message: 'Only whitelisted account can mint.',
  showViewInventory: false,
  openStatus: false,
};

const errorOwnReferralCode = '⚠️ You can’t use your own referral code.';
const errorRefferalNotOwnBBNFT =
  '⚠️ The referral code owner must have at least 1 Bike Battle NFT to get the incetives.';

const invalidReferralCode =
  '⚠️ The referral code is not found. Please check it again.';

export {
  errorOwnReferralCode,
  errorRefferalNotOwnBBNFT,
  garageLimitReached,
  invalidReferralCode,
  modalAddPolygonTestnet,
  modalMetamaskNotInstalled,
  modalUserRejectedTheApproval,
  modalWrongNetwork,
  notEnoughMel,
  notWhitelisted,
  transactionFailed,
};
