import { Typography } from '@mui/material';

import styles from './tabButton.module.scss';
import type { TabButtonProps } from './tabButton.type';

function TabButton(props: TabButtonProps) {
  const { data, state, style, disabled, onClick } = props;
  return (
    <section
      className={
        state === data.value.toLowerCase()
          ? styles.tabButtonActive
          : styles.tabButtonInactive
      }
    >
      <Typography className={styles.text}>{data.value}</Typography>
    </section>
  );
}

export default TabButton;
