import type { OwnedPartProps } from 'src/pages/workshop/workshop.type';

import Text from 'src/components/lofi/text';

import styles from './partDetail.module.scss';

function PartDetail(props: OwnedPartProps) {
  return (
    <div className={styles.partModalDetail}>
      <Text type='h1' align='center' className={styles.modalTitle}>
        Item details
      </Text>
      {/* <Grid container spacing={2} className={styles.partDetail}>
        <Grid item xs={7}>
          <div className={styles.partImage}>
            <img src={props.imageUrl} />
          </div>
        </Grid>
        <Grid item xs={5}>
          <Text type={'h3'} className={styles.partName}>
            {props.name}
          </Text>
          <Text type='small' className={styles.partType}>
            {props.slotType}
          </Text>
          <Text className={styles.partAttr}>
            <>
              Effect: {props.effect[0][0]}
              <Text className={styles.plusAttribute}>
                <>+{props.effect[0][1]}</>
              </Text>
            </>
          </Text>
          <Text>
            <>Min Level: {props.minLevel}</>
          </Text>
          <Text>
            <>Equipped on : {props.bike ? props.bike.name : 'none'}</>
          </Text>
        </Grid>
      </Grid> */}
    </div>
  );
}

export default PartDetail;
