import { Button } from '@mui/material';
import { MouseEventHandler } from 'react';

interface GachaVoucherButtonProps {
  canGachaVoucher: boolean;
  clickFunction: MouseEventHandler<HTMLButtonElement> | undefined;
}

const GachaVoucherButton = (prop: GachaVoucherButtonProps) => {
  return (
    <Button
      disabled={!prop.canGachaVoucher}
      onClick={prop.clickFunction}
      variant='primary'
    >
      Redeem Voucher
    </Button>
  );
};

export default GachaVoucherButton;
