import { BigNumber } from 'ethers';
import { Dispatch, SetStateAction } from 'react';
import { setEnergyBalance } from 'src/redux/accountMetamaskSlice';
import { AppDispatch } from 'src/redux/store';
import { getMelCoinBalance } from 'src/services/contract/erc20/mel';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { mel, store } from 'src/utils/constants/addresses';
import {
  waitingApproveTransaction,
  waitingAuthorizedFund,
} from 'src/utils/constants/loadingMessageConstant';
import { devLog, getTxError } from 'src/utils/helper';

import {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';

async function getEnergyBalance(account: string, dispatch: AppDispatch) {
  try {
    const storeContract = initiateContract(store.address, store.abi);
    const playerEnergy = await storeContract.getPlayerEnergy(account);

    dispatch(setEnergyBalance(BigNumber.from(playerEnergy).toString()));
  } catch (err) {
    console.log(err);
  }
}

async function buyEnergy(
  account: string,
  energyId: number,
  dispatch: AppDispatch,
  setModal: Dispatch<SetStateAction<ModalProps>>
) {
  const melContract = initiateContract(mel.address, mel.abi);
  const storeContract = initiateContract(store.address, store.abi);

  const energyInfo = await storeContract.energyIdToEnergyInfo(energyId);
  const userMelbalance = await melContract.balanceOf(account);

  if (energyInfo.price.gt(userMelbalance)) {
    setModal({
      title: 'Hold up!',
      message: `You don't have enough MEL Balance to buy this energy!`,
      showViewInventory: false,
      openStatus: true,
      buttonMessage: 'SomeWarning',
      event: () => null,
    });

    return;
  }

  const userAllowance = await melContract.allowance(account, store.address);
  const isAllowanceEnoughToMint = userAllowance.gte(energyInfo.price);
  if (!isAllowanceEnoughToMint) {
    setModal({
      ...defaultModalContent,
      ...waitingAuthorizedFund,
    });
  } else {
    setModal({
      ...defaultModalContent,
      ...waitingApproveTransaction,
    });
  }

  if (!isAllowanceEnoughToMint) {
    try {
      const waitApproval = await melContract.approve(
        store.address,
        energyInfo.price
      );
      setModal({
        openStatus: true,
        title: 'Transaction in Progress',
        message: '',
        showViewInventory: false,
        buttonMessage: 'SomeWarning',
        event: () => null,
      });
      const approveFilter = melContract.filters.Approval(account, null, null);

      melContract.once(
        approveFilter,
        (owner: string, spender: string, value: BigNumber, event: any) => {
          devLog(`
            OWNER: ,
            ${owner},
            , SPENDER: ,
            ${spender},
            , VALUE: ,
            ${value}
            `);
          devLog('event');
          devLog(event);
        }
      );

      await waitApproval.wait();
    } catch (err: any) {
      const modalContent = getTxError(err);

      setModal({
        ...defaultModalContent,
        ...modalContent,
      });

      return;
    }
  }

  try {
    // TRANSACTION
    setModal({
      openStatus: true,
      title: 'Confirmation Required',
      message: 'Transaction Request',
      showViewInventory: false,
      buttonMessage: 'SomeWarning',
      event: () => null,
    });

    const waitBuyEnergy = await storeContract.buyEnergy(energyId);
    setModal({
      openStatus: true,
      title: 'Transaction in Progress',
      message: '',
      showViewInventory: false,
      buttonMessage: 'SomeWarning',
      event: () => null,
    });
    await waitBuyEnergy.wait();

    await getEnergyBalance(account, dispatch);
    await getMelCoinBalance(dispatch, account);

    setModal({
      openStatus: true,
      title: 'Transaction Success',
      message: 'Your Energy is on the way',
      showViewInventory: false,
      buttonMessage: 'SomeWarning',
      event: () => null,
    });
    return 'placeholder';
  } catch (err: any) {
    const modalContent = getTxError(err);

    setModal({
      ...modalContent,
      buttonMessage: 'SomeWarning',
      event: () => null,
    });

    return;
  }
}

export { buyEnergy, getEnergyBalance };
