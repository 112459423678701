import { BigNumber, ethers } from 'ethers';
import { setMelCoinBalance } from 'src/redux/accountMetamaskSlice';
import { AppDispatch } from 'src/redux/store';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { mel, nft, gacha } from 'src/utils/constants/addresses';

async function getMelCoinBalance(
  dispatch: AppDispatch,
  currentAccount: string
): Promise<string> {
  const melContract = initiateContract(mel.address, mel.abi);

  const melCurrentBalance: BigNumber = await melContract.balanceOf(
    currentAccount
  );

  const stringMelBalance = Number(
    ethers.utils.formatEther(melCurrentBalance)
  ).toFixed(2);
  dispatch(setMelCoinBalance(stringMelBalance));
  return stringMelBalance;
}

async function getMelBalance(currentAccount: string): Promise<BigNumber> {
  const melContract = initiateContract(mel.address, mel.abi);

  const accountMelBalance: BigNumber = await melContract.balanceOf(
    currentAccount
  );
  return accountMelBalance;
}

async function getMelTotalSupply(): Promise<BigNumber> {
  const melContract = initiateContract(mel.address, mel.abi);

  return await melContract.totalSupply();
}

async function minimalMelToMint(): Promise<BigNumber> {
  const gachaContract = initiateContract(gacha.address, gacha.abi);

  const minimalMelToMint: BigNumber = await gachaContract.minimalMel();

  return minimalMelToMint;
}

async function isMelBalanceEnoughToMint(
  currentAccount: string
): Promise<boolean> {
  const melContract = initiateContract(mel.address, mel.abi);

  const currentMelBalance: BigNumber = await melContract.balanceOf(
    currentAccount
  );
  const minMelToMint: BigNumber = await minimalMelToMint();

  const isMelEnoughToMint = currentMelBalance.gte(minMelToMint);

  return isMelEnoughToMint;
}

async function isMelAllowanceEnoughToMint(
  currentAccount: string
): Promise<boolean> {
  const melContract = initiateContract(mel.address, mel.abi);

  const currentMelAllowance: BigNumber = await melContract.allowance(
    currentAccount,
    gacha.address
  );

  const gachaContract = initiateContract(gacha.address, gacha.abi);
  const gachaPrice: BigNumber = await gachaContract.feeAmount();
  const isAllowanceEnoughToMint = currentMelAllowance.gte(gachaPrice);
  return isAllowanceEnoughToMint;
}

async function getMelToPay(): Promise<BigNumber> {
  const melContract = initiateContract(nft.address, nft.abi);

  const melToPay = await melContract.feeAmount();

  return melToPay;
}

export {
  getMelBalance,
  getMelCoinBalance,
  getMelToPay,
  getMelTotalSupply,
  isMelAllowanceEnoughToMint,
  isMelBalanceEnoughToMint,
  minimalMelToMint,
};
