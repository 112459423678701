import { SvgIcon } from '@mui/material';
import React from 'react';

const MetamaskIcon = (props: any) => {
  return (
    <SvgIcon {...props} width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M18.1917 0.751953L11.0891 6.40639L12.4098 3.07404L18.1917 0.751953Z'
        fill='#E17726'
        stroke='#E17726'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.80835 0.751953L8.84781 6.45917L7.59026 3.07404L1.80835 0.751953Z'
        fill='#E27625'
        stroke='#E27625'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.6346 13.864L13.7448 16.9702L17.7914 18.1689L18.9506 13.9319L15.6346 13.864Z'
        fill='#E27625'
        stroke='#E27625'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.05652 13.9299L2.20869 18.167L6.2483 16.9682L4.36549 13.8621L1.05652 13.9299Z'
        fill='#E27625'
        stroke='#E27625'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.03032 8.61637L4.90625 10.4409L8.91074 10.6369L8.77725 6.00024L6.03032 8.61637Z'
        fill='#E27625'
        stroke='#E27625'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.9694 8.61616L11.1803 5.94727L11.089 10.6367L15.0935 10.4407L13.9694 8.61616Z'
        fill='#E27625'
        stroke='#E27625'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.24835 16.9693L8.67212 15.7103L6.58557 13.9612L6.24835 16.9693Z'
        fill='#E27625'
        stroke='#E27625'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.3278 15.7103L13.7445 16.9693L13.4143 13.9612L11.3278 15.7103Z'
        fill='#E27625'
        stroke='#E27625'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.7444 16.9695L11.3276 15.7104L11.5243 17.3992L11.5033 18.1155L13.7444 16.9695Z'
        fill='#D5BFB2'
        stroke='#D5BFB2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.24841 16.9695L8.49654 18.1155L8.48249 17.3992L8.67218 15.7104L6.24841 16.9695Z'
        fill='#D5BFB2'
        stroke='#D5BFB2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.53863 12.8447L6.52936 12.2114L7.94849 11.5103L8.53863 12.8447Z'
        fill='#233447'
        stroke='#233447'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.4614 12.8447L12.0515 11.5103L13.4777 12.2114L11.4614 12.8447Z'
        fill='#233447'
        stroke='#233447'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.24841 16.968L6.59968 13.8618L4.3656 13.9297L6.24841 16.968Z'
        fill='#CC6228'
        stroke='#CC6228'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.4003 13.8618L13.7445 16.968L15.6344 13.9297L13.4003 13.8618Z'
        fill='#CC6228'
        stroke='#CC6228'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.093 10.4392L11.0886 10.6352L11.4609 12.8442L12.051 11.5098L13.4772 12.2109L15.093 10.4392Z'
        fill='#CC6228'
        stroke='#CC6228'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.5293 12.2109L7.94844 11.5098L8.53857 12.8442L8.91092 10.6352L4.90643 10.4392L6.5293 12.2109Z'
        fill='#CC6228'
        stroke='#CC6228'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.90637 10.4392L6.58545 13.96L6.52924 12.2109L4.90637 10.4392Z'
        fill='#E27525'
        stroke='#E27525'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.4771 12.2109L13.4139 13.96L15.093 10.4392L13.4771 12.2109Z'
        fill='#E27525'
        stroke='#E27525'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.91068 10.6333L8.53833 12.8423L9.00903 15.4509L9.11441 12.013L8.91068 10.6333Z'
        fill='#E27525'
        stroke='#E27525'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0884 10.6333L10.8917 12.0054L10.99 15.4509L11.4607 12.8423L11.0884 10.6333Z'
        fill='#E27525'
        stroke='#E27525'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.4611 12.8433L10.9904 15.4518L11.3276 15.7082L13.4142 13.9591L13.4774 12.21L11.4611 12.8433Z'
        fill='#F5841F'
        stroke='#F5841F'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.52936 12.21L6.58556 13.9591L8.67211 15.7082L9.00933 15.4518L8.53863 12.8433L6.52936 12.21Z'
        fill='#F5841F'
        stroke='#F5841F'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5034 18.1164L11.5245 17.4002L11.3418 17.2343H8.65813L8.48249 17.4002L8.49655 18.1164L6.24841 16.9705L7.03526 17.6641L8.63003 18.8477H11.3629L12.9647 17.6641L13.7445 16.9705L11.5034 18.1164Z'
        fill='#C0AC9D'
        stroke='#C0AC9D'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.3279 15.7107L10.9907 15.4543H9.00951L8.67229 15.7107L8.4826 17.3995L8.65824 17.2336H11.3419L11.5246 17.3995L11.3279 15.7107Z'
        fill='#161616'
        stroke='#161616'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.4937 6.77582L19.0909 3.65457L18.1916 0.751953L11.3278 6.21791L13.9694 8.6154L17.6999 9.78398L18.5218 8.7511L18.1635 8.47215L18.7326 7.91424L18.297 7.55236L18.8661 7.08493L18.4937 6.77582Z'
        fill='#763E1A'
        stroke='#763E1A'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.909119 3.65457L1.5133 6.77582L1.12691 7.08493L1.70299 7.55236L1.26741 7.91424L1.83647 8.47215L1.47818 8.7511L2.30015 9.78398L6.03064 8.6154L8.6722 6.21791L1.80837 0.751953L0.909119 3.65457Z'
        fill='#763E1A'
        stroke='#763E1A'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.7001 9.78602L13.9696 8.61743L15.0937 10.4419L13.4146 13.9628L15.6346 13.9326H18.9506L17.7001 9.78602Z'
        fill='#F5841F'
        stroke='#F5841F'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.03057 8.61743L2.30008 9.78602L1.05658 13.9326H4.36555L6.58558 13.9628L4.9065 10.4419L6.03057 8.61743Z'
        fill='#F5841F'
        stroke='#F5841F'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.089 10.6358L11.3279 6.21784L12.4098 3.07397H7.59039L8.67231 6.21784L8.91117 10.6358L9.0025 12.0231L9.00953 15.4534H10.9907L10.9977 12.0231L11.089 10.6358Z'
        fill='#F5841F'
        stroke='#F5841F'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
};

export default MetamaskIcon;
