import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet as RacePageOutlet, useLocation } from 'react-router-dom';
import { svgs } from 'src/assets';
import { RootState } from 'src/redux/store';
import { getAllOwnedBikes } from 'src/services/api/nft';
import { getRaceInProgress } from 'src/services/api/race';
import { SignatureType } from 'src/utils/interface';

import PageHeader from 'src/components/hifi/pageHeader';

import ActiveRaceModal from './components/activeRaceModal';
import styles from './race.module.scss';
import type { OwnedBikeProps } from '../workshop/workshop.type';
import { useLazyQuery } from '@apollo/client';
import { GET_NFT_USER } from 'src/utils/constants/query';

function Race() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { account } = useSelector((state: RootState) => state);
  const [getNft] = useLazyQuery(GET_NFT_USER, {
    fetchPolicy: 'network-only',
    variables: {
      owner: account.account,
    },
  });
  const headers: SignatureType = {
    pubkey: account.account,
    message: account.message,
    signature: account.signature,
  };

  const [ownedBike, setOwnedBike] = useState<OwnedBikeProps[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  // to render Page title
  const renderTitle = () => {
    switch (pathname) {
      case '/race':
        return 'Race';
      case '/race/single/track-detail':
        return 'Track Details';
      case '/race/single':
        return 'Single Player';
      case '/race/versus':
        return 'eBikes';
      case '/race/versus/join':
        return 'Join Race';
      default:
        return '';
    }
  };

  const renderBackground = () => {
    const splittedPathname = pathname.split('/');
    const lastPart = splittedPathname[splittedPathname.length - 1];

    if (lastPart == 'result') {
      return svgs.wheelBg;
    }

    return svgs.glowBg;
  };

  useEffect(() => {
    // to keep page stay at the top on Outlet changes
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  useEffect(() => {
    getAllOwnedBikes(getNft, dispatch, setOwnedBike);
  }, []);

  useEffect(() => {
    getRaceInProgress(headers, setShowModal, dispatch);
  }, []);

  return (
    <div
      className={styles.raceContainer}
      style={{
        backgroundImage: `url(${renderBackground()})`,
        backgroundSize: 'cover',
      }}
    >
      <PageHeader
        pageTitle={renderTitle()}
        hideTitleSection={pathname.includes('result')}
        hideBackButton={pathname.includes('result')}
      />
      <RacePageOutlet context={{ showModal, setShowModal }} />
      <ActiveRaceModal {...{ showModal, setShowModal }} />
    </div>
  );
}
export default Race;
