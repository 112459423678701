import type { GradientButtonProps } from './gradientButton.type';
import styles from './gradientButton.module.scss';
import Text from '../text';

function GradientButton(props: GradientButtonProps) {
  const {
    theme,
    title,
    leftContent,
    rightContent,
    position,
    onClick,
    style,
    disabled,
    textStyle,
  } = props;

  const btnTheme = theme || 'primary';

  let btnAlign: string;
  switch (position) {
    case 'left':
      btnAlign = 'alignLeft';
      break;
    case 'right':
      btnAlign = 'alignRight';
      break;
    default:
      btnAlign = 'alignCenter';
  }

  return (
    <div
      className={`${styles.btnContainer} ${styles[`${btnTheme}`]} ${
        styles[`${btnAlign}`]
      } ${disabled ? styles.disabled : ''}`}
      style={style}
      onClick={() => onClick()}
    >
      {leftContent}
      <Text type={'button'} className={`btnText`} style={textStyle}>
        {title}
      </Text>
      {rightContent}
    </div>
  );
}

export default GradientButton;
