import { InfoRounded } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { svgs } from 'src/assets';
import loadingLottieBox from 'src/assets/lottie/loading-box-lottie.json';
import RaceProgress from 'src/pages/race/components/raceProgressSection';
import { RootState } from 'src/redux/store';
import { getMelCoinBalance } from 'src/services/contract/erc20/mel';

import styles from './resultSection.module.scss';

interface RouteStateType {
  raceId: string;
  raceStatus: string;
}

const RaceResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as RouteStateType;

  const { account } = useSelector((state: RootState) => state);

  const [raceStatus, setRaceStatus] = useState(
    locationState ? locationState.raceStatus : 'waiting'
  );

  // force browser back button to redirect to world-map
  window.onpopstate = () => {
    navigate('/world-map', { state: { from: 'result' }, replace: true });
  };

  useEffect(() => {
    (async () => await getMelCoinBalance(dispatch, account.account))();
  }, []);

  useEffect(() => {
    if (locationState === null) {
      return navigate('/race', { state: { from: 'result' }, replace: true });
    }
  }, [locationState]);

  const LoadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loadingLottieBox,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const renderBoxContent = () => {
    if (raceStatus === 'finished') {
      return (
        <RaceProgress
          title='Race Finished'
          media={{
            type: 'image',
            media: <img src={svgs.trophy} width={105} height={105} />,
          }}
          infoBox={{
            icon: <InfoRounded />,
            description: 'You can claim the rewards in race history page',
          }}
        />
      );
    } else if (raceStatus === 'started') {
      return (
        <RaceProgress
          title='Race is in progress'
          media={{
            type: 'lottie',
            media: LoadingLottie,
          }}
          infoBox={{
            icon: <InfoRounded />,
            description:
              'You can see the results and rewards in race history page',
          }}
        />
      );
    }
  };

  return (
    <Grid container className={styles.container}>
      <Grid container className={styles.contentContainer}>
        <Grid
          item
          container
          xs={5}
          direction='column'
          justifyContent='center'
          alignItems='center'
          className={styles.boxContainer}
        >
          {renderBoxContent()}
        </Grid>
      </Grid>
      <Grid container item xs={12} className={styles.footer} mt={4}>
        <Grid item xs={10} />
        <Grid item xs={2}>
          <Button
            variant='primary'
            onClick={() =>
              navigate('/race', {
                state: { tab: 'history', from: 'result' },
                replace: true,
              })
            }
            size='small'
          >
            <p>View race history</p>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RaceResult;
