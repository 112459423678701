import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal } from '@mui/material';

import styles from './gradientModal.module.scss';
import {
  _backgroundStyle,
  _secondBackgroundStyle,
  boxStyle,
  GradientModalProps,
} from './gradientModal.type';

function GradientModal(props: GradientModalProps) {
  const { open, onClose, children, style } = props;
  return (
    <Modal
      open={open}
      onClose={() => (onClose ? onClose() : null)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        '.MuiBackdrop-root': {
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      <Box sx={{ ...boxStyle, ...style }}>
        {onClose && (
          <>
            <IconButton
              onClick={() => onClose()}
              sx={{
                position: 'absolute',
                right: '0',
                top: '0',
                margin: '12px',
                color: 'white',
                zIndex: 3,
              }}
              size='large'
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          </>
        )}
        <Box sx={_backgroundStyle}></Box>
        <Box sx={_secondBackgroundStyle}></Box>
        <div className={styles.childrenContainer}>{children}</div>
      </Box>
    </Modal>
  );
}

export default GradientModal;
