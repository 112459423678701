import type { UserPartProps } from 'src/pages/workshop/workshop.type';
import { formatDecimal } from 'src/utils/helper';

import { MelCoinIcon } from 'src/components/Icon';

import styles from './sparepartCard.module.scss';
import Text from '../../text';

function SparepartCard(props: {
  showPrice?: boolean;
  voucherMeta: UserPartProps;
  gatewayUrl: string;
}) {
  const {
    voucherMeta: { voucher, amount },
    showPrice,
  } = props;

  const attributes = [
    {
      attribute: 'Acceleration',
      value: voucher.accelerationEffect,
    },
    {
      attribute: 'Speed',
      value: voucher.speedEffect,
    },
    {
      attribute: 'Drift',
      value: voucher.driftEffect,
    },
  ].filter((attribute) => attribute.value > 0);

  return (
    <div className={styles.partCard}>
      <div className={styles.partImage}>
        <img src={`${props.gatewayUrl}${props.voucherMeta.voucher.imageCid}`} />
      </div>
      <Text type={'h3'} className={styles.partName}>
        <>
          {voucher.name} {amount > 0 ? `x${amount}` : ''}
        </>
      </Text>
      <div className={styles.partAttrListWrapper}>
        {attributes.map((attr) => (
          <Text className={styles.partAttr}>
            <>
              Effect: {attr.attribute}
              <Text className={styles.plusAttribute}>
                <>+{attr.value}</>
              </Text>
            </>
          </Text>
        ))}
      </div>
      {showPrice && voucher.price !== '' && (
        <div className={styles.partPrice}>
          <MelCoinIcon length={'20px'} />
          <Text type={'h3'} className={styles.value}>
            {Number(formatDecimal(voucher.price)).toFixed(2)}
          </Text>
        </div>
      )}
    </div>
  );
}

export default SparepartCard;
