import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Box, Link, Typography } from '@mui/material';
import React from 'react';

import styles from './InsufficientMel.module.scss';
function InsufficientMel() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'fit-content',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        padding: '10px 40px',
        borderRadius: '12px',
        gap: '12px',
      }}
    >
      <ReportProblemIcon className={styles.warningIcon} />
      <Typography variant='h5' component='p' sx={{ fontWeight: '500' }}>
        Insufficient MEL balance, you can{' '}
        <Link
          className={styles.topUp}
          sx={{ fontWeight: '500' }}
          href='https://quickswap.exchange/#/swap?outputCurrency=0xb9784e05c7af6068fc82a80730a599fe17ca2adf&theme=dark'
          target='_blank'
        >
          top up here
        </Link>
      </Typography>
    </Box>
  );
}

export default InsufficientMel;
