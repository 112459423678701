import type {
  OwnedBikeProps,
  OwnedPartProps,
  SelectedShopPartProps,
} from './workshop.type';

const defaultSelectedPart: SelectedShopPartProps = {
  id: '',
  name: '',
  price: '',
};

const defaultSelectedOwnedBike: OwnedBikeProps = {
  name: '',
  type: '',
  level: 0,
  rarity: '',
  A: 0,
  S: 0,
  D: 0,
  imageUrl: '',
  id: '',
};

const defaultSelectedShopPart: OwnedPartProps = {
  id: '',
  imageCid: '',
  name: '',
  price: '',
  accelerationEffect: 0,
  driftEffect: 0,
  speedEffect: 0,
};

// const defaultSelectedShopPart: OwnedPartProps = {
//   id: '',
//   imageUrl: '',
//   name: '',
//   slotType: '',
//   effect: ['', -1],
//   minLevel: -1,
//   price: -1,
//   bike: '',
// };

export {
  defaultSelectedOwnedBike,
  defaultSelectedPart,
  defaultSelectedShopPart,
};
