import { setGachaVoucher } from 'src/redux/accountMetamaskSlice';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { gameItem } from 'src/utils/constants/addresses';
import { AppDispatch } from 'src/redux/store';
import { BigNumber } from 'ethers';

async function getUserGachaVoucher(account: string, dispatch: AppDispatch) {
  const gameItemContract = initiateContract(gameItem.address, gameItem.abi);
  const gachaVoucherId = 7;
  const userGachaVoucher = await gameItemContract.itemBalance(
    account,
    gachaVoucherId
  );
  dispatch(setGachaVoucher(BigNumber.from(userGachaVoucher).toNumber()));
}

export { getUserGachaVoucher };
