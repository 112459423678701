import { Grid } from '@mui/material';

import SkeletonCard from 'src/components/lofi/card/skeletonCard';

import { LoadingSkeletonProps } from './loadingSkeleton.type';

const LoadingSkeletons = (props: LoadingSkeletonProps) => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='start'
      alignItems='center'
      rowSpacing={8}
      columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      columns={12}
      marginTop={0}
      {...props}
    >
      <Grid item xs={3}>
        <SkeletonCard cardBackgroundColor={props.cardBackgroundColor} />
      </Grid>
      <Grid item xs={3}>
        <SkeletonCard cardBackgroundColor={props.cardBackgroundColor} />
      </Grid>
      <Grid item xs={3}>
        <SkeletonCard cardBackgroundColor={props.cardBackgroundColor} />
      </Grid>
      <Grid item xs={3}>
        <SkeletonCard cardBackgroundColor={props.cardBackgroundColor} />
      </Grid>
    </Grid>
  );
};

export default LoadingSkeletons;
