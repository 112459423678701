import { BigNumber } from 'ethers';
import { Dispatch, SetStateAction } from 'react';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { mel, store } from 'src/utils/constants/addresses';
import {
  waitingApproveTransaction,
  waitingAuthorizedFund,
} from 'src/utils/constants/loadingMessageConstant';
import { devLog, getTxError } from 'src/utils/helper';

import {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';

import { getAccount } from '../metamask/account';

// async function getAllShopPart(
//   setShopPart: Dispatch<SetStateAction<OwnedPartProps[]>>
// ) {

// }

async function getGatewayUrl() {
  const storeContract = initiateContract(store.address, store.abi);
  return await storeContract.getGateway();
}

async function buyPart(
  id: string,
  price: number,
  setModal: Dispatch<SetStateAction<ModalProps>>
) {
  const account = await getAccount();
  const melContract = initiateContract(mel.address, mel.abi);
  const storeContract = initiateContract(store.address, store.abi);
  const partInfo = await storeContract.energyIdToEnergyInfo(id);
  // APPROVAL

  const userAllowance = await melContract.allowance(account, store.address);
  const isAllowanceEnoughToMint = userAllowance.gte(partInfo.price);
  if (!isAllowanceEnoughToMint) {
    setModal({
      ...defaultModalContent,
      ...waitingAuthorizedFund,
    });
  } else {
    setModal({
      ...defaultModalContent,
      ...waitingApproveTransaction,
    });
  }

  if (!isAllowanceEnoughToMint) {
    try {
      const waitApproval = await melContract.approve(
        store.address,
        partInfo.price
      );
      setModal({
        openStatus: true,
        title: 'Transaction in Progress',
        message: '',
        showViewInventory: false,
        buttonMessage: 'SomeWarning',
        event: () => null,
      });
      const approveFilter = melContract.filters.Approval(account, null, null);

      melContract.once(
        approveFilter,
        (owner: string, spender: string, value: BigNumber, event: any) => {
          devLog(`
            OWNER: ,
            ${owner},
            , SPENDER: ,
            ${spender},
            , VALUE: ,
            ${value}
            `);
          devLog('event');
          devLog(event);
        }
      );

      await waitApproval.wait();
    } catch (err: any) {
      const modalContent = getTxError(err);

      setModal({
        ...defaultModalContent,
        ...modalContent,
      });

      return;
    }
  }

  setModal({
    openStatus: true,
    title: 'Confirmation Required',
    message: 'Transaction Request',
    showViewInventory: false,
    buttonMessage: 'SomeWarning',
    event: () => null,
  });

  try {
    const transaction = await storeContract.buyGameItem(id, 1);
    setModal({
      openStatus: true,
      title: 'Transaction in Progress',
      message: '',
      showViewInventory: false,
      buttonMessage: 'SomeWarning',
      event: () => null,
    });

    await transaction.wait();
  } catch (err: any) {
    const modalContent = getTxError(err);

    setModal({
      buttonMessage: 'SomeWarning',
      event: () => null,
      ...modalContent,
    });

    return;
  }

  setModal({
    openStatus: true,
    title: 'Transaction Success',
    message: 'Your Sparepart is on the way',
    showViewInventory: false,
    buttonMessage: 'SomeWarning',
    event: () => null,
  });
}

export { buyPart, getGatewayUrl };
