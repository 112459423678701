import { SvgIcon } from '@mui/material';
import React from 'react';

const MelxCryptoLogo = (props: any) => {
  return (
    <SvgIcon width={26} height={26} viewBox='0 0 26 26' fill='none' {...props}>
      <circle cx='13' cy='13' r='13' fill='black' />
      <mask
        id='mask0_406_446'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='5'
        y='5'
        width='16'
        height='16'
      >
        <rect x='5' y='5' width='16' height='16' fill='#C4C4C4' />
      </mask>
      <g mask='url(#mask0_406_446)'>
        <path
          d='M16.1468 15.1377L13.4961 16.401L17.7524 18.4465V15.9149L16.1468 15.1377Z'
          fill='url(#paint0_linear_406_446)'
        />
        <path
          d='M8.24805 15.776V18.3026L12.3638 16.3398L9.75445 15.05L8.24805 15.776Z'
          fill='url(#paint1_linear_406_446)'
        />
        <path
          d='M13 13.0789L21 9.28394V17.8032L18.6635 18.8648V12.9301L12.9901 15.6337L7.3365 12.8408V18.8648L5 17.8032V9.1748L13 13.0789Z'
          fill='url(#paint2_linear_406_446)'
        />
        <path
          d='M13.0513 9.48255L8.08391 7.09644L5.34229 8.29362L13.0513 12.0307L20.5767 8.38126L17.8813 7.16258L13.0513 9.48255Z'
          fill='url(#paint3_linear_406_446)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_406_446'
          x1='20.4573'
          y1='16.5775'
          x2='5.31054'
          y2='17.5035'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FE01FA' />
          <stop offset='0.3163' stopColor='#FF0F6F' />
          <stop offset='0.5195' stopColor='#FF3D3D' />
          <stop offset='0.7086' stopColor='#FF851F' />
          <stop offset='1' stopColor='#FFDB01' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_406_446'
          x1='20.4211'
          y1='15.9783'
          x2='5.27431'
          y2='16.9043'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FE01FA' />
          <stop offset='0.3163' stopColor='#FF0F6F' />
          <stop offset='0.5195' stopColor='#FF3D3D' />
          <stop offset='0.7086' stopColor='#FF851F' />
          <stop offset='1' stopColor='#FFDB01' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_406_446'
          x1='5'
          y1='14.0195'
          x2='20.9992'
          y2='14.0195'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FE01FA' />
          <stop offset='0.3163' stopColor='#FF0F6F' />
          <stop offset='0.5195' stopColor='#FF3D3D' />
          <stop offset='0.7086' stopColor='#FF851F' />
          <stop offset='1' stopColor='#FFDB01' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_406_446'
          x1='5.34172'
          y1='9.5639'
          x2='20.5771'
          y2='9.5639'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFDB01' />
          <stop offset='0.2914' stopColor='#FF851F' />
          <stop offset='0.4805' stopColor='#FF3D3D' />
          <stop offset='0.6837' stopColor='#FF0F6F' />
          <stop offset='1' stopColor='#FE01FA' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default MelxCryptoLogo;
