import { ethers } from 'ethers';
import type { Dispatch, SetStateAction } from 'react';
import { fetchNftData, getNftTotalCount } from 'src/services/contract/nft/nft';
import type { SignatureType } from 'src/utils/interface';

import { NFTProduct } from './inventory.type';

async function fetchDataInitial(
  currentAccount: string,
  nftContract: ethers.Contract,
  setIsEmpty: Dispatch<SetStateAction<boolean>>,
  setNftCount: Dispatch<SetStateAction<number>>,
  currentPage: number,
  showMultiplier: number,
  setProduct: Dispatch<SetStateAction<NFTProduct[]>>,
  setShowBottomPage: Dispatch<SetStateAction<boolean>>,
  setBottomPage: Dispatch<SetStateAction<boolean>>,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  setPreRender: Dispatch<SetStateAction<boolean>>,
  isLogin: boolean,
  headers: SignatureType
) {
  //! NOT LOGIN
  if (isLogin === false) {
    alert('Please Login Your Account');
  }
  const urlAccount: string = ethers.utils.getAddress(currentAccount);
  const nftTotalCount = await getNftTotalCount(nftContract, currentAccount);
  //! EMPTY INVENTORY
  if (nftTotalCount === 0) {
    setIsEmpty(true);
  }
  setNftCount(nftTotalCount);
  const nftProduct = await fetchNftData(currentPage, showMultiplier, headers);
  setProduct(nftProduct);
  const nftRemaining: number = nftTotalCount - showMultiplier;
  //? STILL HAS NFT LEFT
  if (nftRemaining > 0) {
    setShowBottomPage(true);
    setBottomPage(false);
  }
  //? NO NFT LEFT
  if (nftRemaining <= 0) {
    setBottomPage(true);
    setShowBottomPage(true);
  }
  setNftCount(nftRemaining);
  setCurrentPage((currentPage) => currentPage + 1);
  setPreRender((preRender) => !preRender);
  return nftProduct;
}
async function fetchDataNext(
  product: NFTProduct[],
  currentAccount: string,
  nftCount: number,
  setNftCount: Dispatch<SetStateAction<number>>,
  currentPage: number,
  showMultiplier: number,
  setProduct: Dispatch<SetStateAction<NFTProduct[]>>,
  setShowBottomPage: Dispatch<SetStateAction<boolean>>,
  setBottomPage: Dispatch<SetStateAction<boolean>>,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  setPreRender: Dispatch<SetStateAction<boolean>>,
  headers: SignatureType
) {
  const urlAccount: string = ethers.utils.getAddress(currentAccount);
  const nftProduct = await fetchNftData(currentPage, showMultiplier, headers);
  const tempProduct = [...product, ...nftProduct];
  setProduct(tempProduct);
  const nftRemaining: number = nftCount - showMultiplier;
  //? NO NFT LEFT
  if (nftProduct.length < showMultiplier) {
    setShowBottomPage(true);
    setBottomPage(true);
  }
  if (nftProduct.length == showMultiplier) {
    //? NO NFT LEFT
    if (nftRemaining <= 0) {
      setShowBottomPage(true);
      setBottomPage(true);
    }
    //? STILL HAS NFT LEFT
    if (nftRemaining > 0) {
      setBottomPage(false);
      setShowBottomPage(true);
    }
  }
  setCurrentPage((currentPage) => currentPage + 1);
  setNftCount(nftRemaining);
  setPreRender((preRender) => !preRender);
  return nftProduct;
}

function resetOnAccountChanged(
  setIsEmpty: Dispatch<SetStateAction<boolean>>,
  setNftCount: Dispatch<SetStateAction<number>>,
  currentPage: number,
  setProduct: Dispatch<SetStateAction<NFTProduct[]>>,
  setShowBottomPage: Dispatch<SetStateAction<boolean>>,
  setBottomPage: Dispatch<SetStateAction<boolean>>,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  setReinitialize: Dispatch<SetStateAction<boolean>>,
  reinitialize: boolean
) {
  if (currentPage !== 1) {
    setProduct([]);
    setCurrentPage(1);
    setShowBottomPage(false);
    setBottomPage(true);
    setNftCount(0);
    setIsEmpty(false);
    setReinitialize(!reinitialize);
  }
}

export { fetchDataInitial, fetchDataNext, resetOnAccountChanged };
