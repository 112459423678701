import { Button } from '@mui/material';
import React, { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import SingleBox from 'src/assets/svg/SingleBox';
import {
  selectAccountEnoughMel,
  selectAccountLoginState,
  selectAccountWhitelistStatus,
} from 'src/redux/accountMetamaskSlice';

interface GetYourMotorBikeButtonProps {
  openReferral: MouseEventHandler<HTMLButtonElement> | undefined;
}

const GetYourMotorBikeButton = (props: GetYourMotorBikeButtonProps) => {
  const isLogin = useSelector(selectAccountLoginState);
  const enoughTokenMel = useSelector(selectAccountEnoughMel);
  const { isUserCanMint } = useSelector(selectAccountWhitelistStatus);

  return (
    <Button
      //if user not logged in or not enough mel or not whitelisted this button will disabled
      disabled={!isLogin || !enoughTokenMel || !isUserCanMint ? true : false}
      onClick={props.openReferral}
      variant='primary'
      startIcon={
        <SingleBox
          sx={{
            fontSize: '2.5rem !important',
          }}
        />
      }
    >
      Get your Motorbike
    </Button>
  );
};

export default GetYourMotorBikeButton;
