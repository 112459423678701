import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import emptyFolderImg from 'src/assets/images/EmptyFolder.png';
import GetYourMotorBikeButton from 'src/components/lofi/button/getYourMotorbikeButton';
import ReferralModal from 'src/components/lofi/modal/referralModal';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';

const InventoryEmpty = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleModal = () => setOpenModal((prevState) => !prevState);
  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={4}
      sx={{
        maxWidth: '700px',
        width: '100%',
        left: '50%',
        top: '50%',
        transform: 'translate(0, 10%)',
      }}
    >
      <img width={200} src={emptyFolderImg}></img>
      <Box>
        <Typography
          variant='h5'
          align='center'
          sx={{
            color: 'white',
            fontWeight: 600,
          }}
        >
          Your inventory is empty.
        </Typography>
        <Typography
          variant='h6'
          align='center'
          sx={{
            color: 'white',
            fontWeight: 400,
            maxWidth: 470,
            marginTop: '0.5rem',
          }}
        >
          Looks likes you haven't added any motorbike to your inventory yet. Buy
          now!
        </Typography>
      </Box>
      <Stack direction='row' spacing={3}>
        <GetYourMotorBikeButton openReferral={handleModal} />
      </Stack>
      <ReferralModal
        open={openModal}
        handleClose={handleModal}
        waitingModalContent={waitingModalContent}
        setWaitingModalContent={setWaitingModalContent}
      />
      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={() => setWaitingModalContent(defaultModalContent)}
      />
    </Stack>
  );
};

export default InventoryEmpty;
