import { BigNumber } from 'ethers';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { setMelCoinBalance } from 'src/redux/accountMetamaskSlice';
import { AppDispatch } from 'src/redux/store';
import {
  checkNetwork,
  getAccount,
  switchNetwork,
} from 'src/services/contract/metamask/account';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { mel, race } from 'src/utils/constants/addresses';
import { modalUserRejectedTheApproval } from 'src/utils/constants/errorMessageConstants';
import {
  waitingApproveTransaction,
  waitingAuthorizedFund,
} from 'src/utils/constants/loadingMessageConstant';
import { getTxError } from 'src/utils/helper';

import {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';

export const defaultPVPRacePropsValue: PVPRaceProps = {
  raceId: null,
  currentRaceStartTime: 0,
  minPlayersToStart: 0,
  maxPlayerPerRound: 0,
  isPlayerJoined: false,
  raceEntryFee: 0,
  startRaceIncentive: '0',
};

interface PVPRaceProps {
  raceId: number | null;
  currentRaceStartTime: number;
  minPlayersToStart: number;
  maxPlayerPerRound: number;
  isPlayerJoined: boolean;
  raceEntryFee: number;
  startRaceIncentive: string;
}

const updateBbBalance = async (dispatch: AppDispatch, account: string) => {
  const melContract = initiateContract(mel.address, mel.abi);

  const latestBbBalance = await melContract.balanceOf(account);
  const stringBBBalance = Number(formatEther(latestBbBalance)).toFixed(2);

  dispatch(setMelCoinBalance(stringBBBalance));
};

const getPVPRaceProps = async (account: any) => {
  const raceContract = initiateContract(race.address, race.abi);
  const melContract = initiateContract(mel.address, mel.abi);

  const raceId = await raceContract.getRaceId();
  const currentRaceStartTime = await raceContract.getCurrentRaceStartTime();
  const minPlayersToStart = await raceContract.getMinimumPlayerToStartRace();
  const maxPlayerPerRound = await raceContract.getMaximumPlayerToStartRace();
  const isPlayerJoined = await raceContract.getUserJoinStatus(account.account);
  const raceEntryFee = await raceContract.getEntryFee();
  const startRaceIncentive = await raceContract.getStartRaceIncentive();

  return {
    raceId: BigNumber.from(raceId).toNumber(),
    currentRaceStartTime: BigNumber.from(currentRaceStartTime).toNumber(),
    minPlayersToStart,
    maxPlayerPerRound,
    isPlayerJoined,
    raceEntryFee: parseInt(formatUnits(raceEntryFee)),
    startRaceIncentive: formatUnits(startRaceIncentive),
  };
};

const getNumberOfParticipant = async () => {
  const raceContract = initiateContract(race.address, race.abi);

  const raceId = await raceContract.getRaceId();
  const joinedPlayers = await raceContract.getNumberOfParticipant(raceId);

  return joinedPlayers;
};

const joinPVPRace = async (
  dispatch: AppDispatch,
  tokenId: string,
  waitingModalContent: ModalProps,
  setWaitingModalContent: React.Dispatch<React.SetStateAction<ModalProps>>
) => {
  const network = await checkNetwork();
  if (network != process.env.REACT_APP_METAMASK_CHAINID) {
    await switchNetwork(dispatch);
  }

  const raceContract = initiateContract(race.address, race.abi);
  const melContract = initiateContract(mel.address, mel.abi);
  const account = await getAccount();

  const totalAllowance = await melContract.allowance(account, race.address);
  const raceEntryFee = await raceContract.getEntryFee();
  const bbTotalSupply = await melContract.totalSupply();

  try {
    if (BigNumber.from(totalAllowance).lt(raceEntryFee)) {
      setWaitingModalContent({
        ...waitingModalContent,
        ...waitingAuthorizedFund,
      });

      const bbApproval = await melContract.approve(
        race.address,
        BigNumber.from(bbTotalSupply).toBigInt()
      );

      await bbApproval.wait();
    }
  } catch (err: any) {
    setWaitingModalContent({
      ...waitingModalContent,
      ...modalUserRejectedTheApproval,
    });

    return;
  }

  try {
    setWaitingModalContent({
      ...waitingModalContent,
      ...waitingApproveTransaction,
    });

    const joinRace = await raceContract.joinRace(tokenId);

    setWaitingModalContent({
      ...waitingModalContent,
      title: 'Joining the race',
      message: 'Please wait a moment.',
      showViewInventory: false,
      openStatus: true,
    });

    await joinRace.wait();
  } catch (err: any) {
    const modalContent = getTxError(err);

    setWaitingModalContent({
      ...waitingModalContent,
      ...modalContent,
    });

    return;
  }

  await updateBbBalance(dispatch, account);

  setWaitingModalContent({
    ...defaultModalContent,
    openStatus: true,
    title: 'Transaction Success',
    message: 'You have successfully joined the race',
    showViewInventory: false,
  });
};

const startPVPRace = async (
  dispatch: AppDispatch,
  waitingModalContent: ModalProps,
  setWaitingModalContent: React.Dispatch<React.SetStateAction<ModalProps>>
) => {
  const network = await checkNetwork();
  if (network != process.env.REACT_APP_METAMASK_CHAINID) {
    await switchNetwork(dispatch);
  }

  const account = await getAccount();
  const raceContract = initiateContract(race.address, race.abi);

  try {
    setWaitingModalContent({
      ...waitingModalContent,
      ...waitingApproveTransaction,
    });

    const startRaceTx = await raceContract.startRace();
    await startRaceTx.wait();
  } catch (err: any) {
    const modalContent = getTxError(err);

    setWaitingModalContent({
      ...waitingModalContent,
      ...modalContent,
    });

    return;
  }

  await updateBbBalance(dispatch, account);
};

const claimPVPRacePrize = async (
  dispatch: AppDispatch,
  userHistory: any,
  waitingModalContent: ModalProps,
  setWaitingModalContent: React.Dispatch<React.SetStateAction<ModalProps>>
) => {
  const account = await getAccount();
  const raceContract = initiateContract(race.address, race.abi);

  try {
    setWaitingModalContent({
      ...waitingModalContent,
      ...waitingApproveTransaction,
    });

    const claimPrize = await raceContract.claimPrize(
      userHistory.nftId,
      userHistory.race.id
    );

    await claimPrize.wait();
  } catch (err: any) {
    const modalContent = getTxError(err);

    setWaitingModalContent({
      ...waitingModalContent,
      ...modalContent,
    });

    return;
  }

  await updateBbBalance(dispatch, account);

  setWaitingModalContent({
    ...defaultModalContent,
    openStatus: true,
    title: 'Transaction Success',
    message: 'You have successfully claimed the prize',
    showViewInventory: false,
  });
};

export {
  claimPVPRacePrize,
  getNumberOfParticipant,
  getPVPRaceProps,
  joinPVPRace,
  startPVPRace,
};
export type { PVPRaceProps };
