import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import melxBikeBattleImg from 'src/assets/images/melxBikeBattle.png';
import {
  selectAccountEnoughMel,
  selectAccountLoginState,
  selectGachaVoucher,
  selectHasNft,
} from 'src/redux/accountMetamaskSlice';
import { gachaWithVoucher } from 'src/services/contract/nft/gacha';
import { motorbikeOnTheWay } from 'src/utils/constants/loadingMessageConstant';
import { getTxError } from 'src/utils/helper';

import MintingRequirements from 'src/components/hifi/mintRequirementSection';
import InsufficientMel from 'src/components/lofi/alert/insufficientMel';
import GachaVoucherButton from 'src/components/lofi/button/gachaVoucherButton';
import GetYourMotorBikeButton from 'src/components/lofi/button/getYourMotorbikeButton';
import RefferalModal from 'src/components/lofi/modal/referralModal';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';
import Navbar from 'src/components/lofi/navbar';

import styles from './home.module.scss';

function Home() {
  const isLogin = useSelector(selectAccountLoginState);
  const enoughTokenMel = useSelector(selectAccountEnoughMel);
  const hasNft = useSelector(selectHasNft);
  const hasGachaVoucher = useSelector(selectGachaVoucher) > 0;
  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);

  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const gachaWithVoucherHandler = async (e: any) => {
    e.preventDefault();
    try {
      setWaitingModalContent({
        ...defaultModalContent,
        openStatus: true,
        title: 'Waiting approve the transaction',
        message: 'Please open your wallet and confirm the transaction',
        showViewInventory: false,
      });
      await gachaWithVoucher();
    } catch (err: any) {
      const modalContent = getTxError(err);

      setWaitingModalContent({
        ...waitingModalContent,
        ...modalContent,
      });

      return;
    }
    setWaitingModalContent({ ...defaultModalContent, ...motorbikeOnTheWay });
  };

  return (
    <>
      <section id='home' className={styles.home}>
        <Navbar />
        <Box
          sx={{
            width: 'fit-content',
            margin: '0 auto',
            padding: '4rem 0',
          }}
        >
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            className={styles.backdrop}
          >
            <img width={450} src={melxBikeBattleImg}></img>
            <Typography
              variant='h5'
              align='center'
              sx={{
                color: 'white',
                fontWeight: 600,
                maxWidth: 731,
              }}
            >
              Buy majestic electric bike NFTs, upgrade them, compete in races
              against other eBike riders, get higher ranks, and win rewards.
            </Typography>
            {isLogin && !enoughTokenMel && !hasNft ? <InsufficientMel /> : null}
            <Stack direction='row' spacing={3}>
              <GetYourMotorBikeButton openReferral={handleOpen} />
              <GachaVoucherButton
                canGachaVoucher={hasGachaVoucher}
                clickFunction={gachaWithVoucherHandler}
              />
              <RefferalModal
                open={open}
                handleClose={handleClose}
                waitingModalContent={waitingModalContent}
                setWaitingModalContent={setWaitingModalContent}
              />
              <WaitingModal
                openStatus={waitingModalContent.openStatus}
                title={waitingModalContent.title}
                message={waitingModalContent.message}
                showViewInventory={waitingModalContent.showViewInventory}
                buttonMessage={waitingModalContent.buttonMessage}
                event={() => null}
                closeModal={() => setWaitingModalContent(defaultModalContent)}
              />
            </Stack>
            <MintingRequirements />
          </Stack>
        </Box>
      </section>
    </>
  );
}

export default Home;
