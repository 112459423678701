import AddIcon from '@mui/icons-material/Add';
import { Button, IconButton } from '@mui/material';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { faucet } from 'src/utils/constants/addresses';

import styles from './faucetButton.module.scss';

const AddBalance = () => {
  return (
    <IconButton component='div'>
      <AddIcon />
    </IconButton>
  );
};

type Props = {
  currencyBalance: string;
  endIcon: React.ReactNode;
  style?: React.CSSProperties;
  clickHandler: any;
};

const onClickHandler = async () => {
  const faucetContract = initiateContract(faucet.address, faucet.abi);
  await faucetContract.provideMelx();
};

const FaucetButton = (props: Props) => {
  return (
    <Button
      onClick={props.clickHandler}
      sx={{
        display: 'inline-flex',
      }}
      className={styles.buttonTopUp}
      startIcon={<AddBalance />}
      endIcon={props.endIcon}
      style={props.style}
    >
      {props.currencyBalance === '' ? '0' : `${props.currencyBalance}`}
    </Button>
  );
};

export default FaucetButton;
