import { ButtonBase, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectBankTabState, setBankTab } from 'src/redux/gameInterfaceSlice';
import { quickswapUrl } from 'src/utils/constants/url';

import PageHeader from 'src/components/hifi/pageHeader';
import TabButton from 'src/components/lofi/button/tabButton';
import WaitingModal from 'src/components/lofi/modal/waitingModal';

import styles from './bank.module.scss';
function Bank() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tabState = useSelector(selectBankTabState);

  const defaultWaitingModal = {
    openStatus: false,
    title: '',
    message: '',
  };
  const [waitingModal, setWaitingModal] = useState<any>(defaultWaitingModal);

  window.onpopstate = () => {
    navigate('/world-map');
  };

  return (
    <main className={styles.bankSection}>
      {/* NAVBAR SECTION  */}
      <PageHeader pageTitle='Bank' />
      {/* CONTENT SECTION  */}
      <Grid container className={styles.mainContainer}>
        <Grid item xs={2}>
          <article className={styles.tabSection}>
            <ButtonBase
              onClick={() => dispatch(setBankTab('exchange'))}
              className={styles.tabButton}
            >
              <TabButton data={{ value: 'Exchange' }} state={tabState} />
            </ButtonBase>
          </article>
        </Grid>
        <Grid item xs={10} className={styles.contentSection}>
          {tabState === 'exchange' && (
            <article className={styles.content}>
              <div className={styles.quickswapSection}>
                <iframe
                  scrolling='yes'
                  src={quickswapUrl}
                  height='100%'
                  width='100%'
                  className={styles.quickswap}
                />
              </div>
            </article>
          )}
        </Grid>
      </Grid>
      <WaitingModal
        openStatus={waitingModal.openStatus}
        title={waitingModal.title}
        message={waitingModal.message}
        showViewInventory={false}
        buttonMessage={''}
        event={() => null}
        closeModal={() => setWaitingModal(defaultWaitingModal)}
      />
    </main>
  );
}

export default Bank;
