import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MelxCryptoLogo from 'src/assets/svg/MelxCryptoLogo';
import MelxLogo from 'src/assets/svg/MelxLogo';
import {
  selectAccountAddress,
  selectAccountMelBalance,
  setMelBalance,
} from 'src/redux/accountMetamaskSlice';
import { getMelBalance } from 'src/services/contract/erc20/mel';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { faucet } from 'src/utils/constants/addresses';
import { formatBigNumberToString, getTxError } from 'src/utils/helper';

import MetamaskButton from 'src/components/hifi/metamaskButton';
import NavItem from 'src/components/hifi/navItem';
import FaucetButton from 'src/components/lofi/button/faucetButton';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';

import styles from './navbar.module.scss';

function Navbar() {
  const dispatch = useDispatch();
  const currentBalanceMel = useSelector(selectAccountMelBalance);
  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);
  const stateAccount = useSelector(selectAccountAddress);

  const closeModalHandle = () => {
    setWaitingModalContent(defaultModalContent);
  };

  const faucetHandler = async () => {
    try {
      setWaitingModalContent({
        ...defaultModalContent,
        openStatus: true,
        title: 'Waiting approve the transaction',
        message: 'Please open your wallet and confirm the transaction',
        showViewInventory: false,
      });

      const faucetContract = initiateContract(faucet.address, faucet.abi);
      const tx = await faucetContract.provideMelx();

      setWaitingModalContent({
        ...defaultModalContent,
        openStatus: true,
        title: 'Waiting for transaction to complete',
        showViewInventory: false,
      });
      await tx.wait();
      const melBalance = await getMelBalance(stateAccount);
      const melBalanceString: string = formatBigNumberToString(melBalance);
      dispatch(setMelBalance(melBalanceString));
    } catch (err: any) {
      const modalContent = getTxError(err);

      setWaitingModalContent({
        ...waitingModalContent,
        ...modalContent,
      });

      return;
    }
    setWaitingModalContent(defaultModalContent);
  };

  return (
    <>
      <AppBar
        position='static'
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.15)',
          color: 'white',
          boxShadow: 'unset',
        }}
      >
        <Toolbar className={styles.Toolbar}>
          <IconButton edge='start' color='inherit' aria-label='menu'>
            <MelxLogo sx={{ width: 'fit-content' }} />
          </IconButton>

          <Box
            component='nav'
            sx={{
              flexGrow: 1,
              display: 'flex',
              gap: '2.2rem',
              marginLeft: '78px',
            }}
            className={styles.NavLists}
          >
            <li>
              <Typography variant='h6' component='h6'>
                <NavItem to='/'>Home</NavItem>
              </Typography>
            </li>
            <li>
              <Typography variant='h6' component='h6'>
                <NavItem to='/how-to-play'>{'How to Play'}</NavItem>
              </Typography>
            </li>
          </Box>
          <Stack
            flexDirection='row'
            justifyContent='center'
            alignItems='center'
            sx={{
              gap: '3rem',
            }}
          >
            <Stack
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              sx={{
                gap: '0.6rem',
              }}
            >
              <FaucetButton
                currencyBalance={currentBalanceMel}
                clickHandler={() => {
                  faucetHandler();
                }}
                endIcon={
                  <MelxCryptoLogo sx={{ width: '26px', height: '26px' }} />
                }
              />
            </Stack>
            <div>
              <MetamaskButton />
            </div>
          </Stack>
        </Toolbar>
        <WaitingModal
          openStatus={waitingModalContent.openStatus}
          title={waitingModalContent.title}
          message={waitingModalContent.message}
          showViewInventory={waitingModalContent.showViewInventory}
          buttonMessage={waitingModalContent.buttonMessage}
          event={() => null}
          closeModal={closeModalHandle}
        />
      </AppBar>
    </>
  );
}

export default Navbar;
