import { ButtonBase, Grid } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import TabButton from 'src/components/lofi/button/tabButton';

import ChooseMode from './ChooseModeSection';
import RaceHistory from './historySection';
import styles from './raceType.module.scss';

interface RouteStateType {
  tab: string;
}

const RaceType = () => {
  const location = useLocation();
  const locationState = location.state as RouteStateType;

  const [activeTab, setActiveTab] = useState(
    locationState?.tab ? locationState.tab : 'race'
  );

  return (
    <div>
      <Grid container className={styles.boxContainer}>
        <Grid item xs={2}>
          <article className={styles.tabSection}>
            <ButtonBase
              onClick={() => setActiveTab('race')}
              className={styles.tabButton}
            >
              <TabButton data={{ value: 'Race' }} state={activeTab} />
            </ButtonBase>
            <ButtonBase
              onClick={() => setActiveTab('history')}
              className={styles.tabButton}
            >
              <TabButton data={{ value: 'History' }} state={activeTab} />
            </ButtonBase>
          </article>
        </Grid>
        {activeTab === 'race' ? (
          <>
            <Grid item xs={1} />
            <ChooseMode />
          </>
        ) : (
          <Grid item xs={10}>
            <RaceHistory />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default RaceType;
