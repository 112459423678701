import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../redux/store';

interface TypeModalContent {
  content: {
    title: string;
    message: string;
    showViewInventory: boolean;
    openStatus: boolean;
  };
}
const initialState: TypeModalContent = {
  content: {
    title: '',
    message: '',
    showViewInventory: false,
    openStatus: true,
  },
};

export const modalContentSlice = createSlice({
  name: 'modalContent',
  initialState,
  reducers: {
    setModalContent: (
      state,
      action: PayloadAction<{
        title: string;
        message: string;
        showViewInventory: boolean;
        openStatus: boolean;
      }>
    ) => {
      state.content = action.payload;
    },
    removeModalContent: (state) => {
      state.content = {
        title: '',
        message: '',
        showViewInventory: false,
        openStatus: false,
      };
    },
  },
});

export const { setModalContent, removeModalContent } =
  modalContentSlice.actions;

// Selections - This is how we pull information from the Global store slice
export const selectModalContent = (state: RootState) =>
  state.modalContent.content;

export default modalContentSlice.reducer;
