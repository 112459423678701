import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import warningLottie from 'src/assets/lottie/metamask-fail-throw-alert-lottie.json';
import { RootState } from 'src/redux/store';

import BikeList from 'src/components/hifi/bikeList';
import CustomModal from 'src/components/lofi/modal/customModal';
import Stepper from 'src/components/lofi/stepper';

import styles from './chooseBikeSection.module.scss';

interface ChooseBikeSection {
  routeTo: string;
}

const ChooseBike = (props: ChooseBikeSection) => {
  const { routeTo } = props;

  const navigate = useNavigate();

  const { activeBike } = useSelector((state: RootState) => state.race);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleContinueButton = () => {
    if (typeof activeBike == 'undefined') {
      return setIsModalOpened(true);
    }

    return navigate(routeTo);
  };

  return (
    <>
      <Grid container className={styles.container}>
        <Grid item xs={12} mx={4}>
          <Grid item xs={12} mb={3} className={styles.stepContainer}>
            <Grid item xs={7}>
              <Stepper text='Select eBikes' steps={3} currentStep={2} />
            </Grid>
          </Grid>
          <BikeList />
        </Grid>
        <Grid container item xs={12} className={styles.footer} mt={4}>
          <Grid item xs={10} />
          <Grid item xs={2}>
            <Button
              variant='primary'
              onClick={() => handleContinueButton()}
              size='small'
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CustomModal
        modalContent={{
          lottieAnimation: warningLottie,
          title: 'Hold up!',
          message:
            'You need to choose one bike to use to join the race. Please choose your bike first.',
          buttonText: 'Close',
        }}
        open={isModalOpened}
        close={() => setIsModalOpened(false)}
      />
    </>
  );
};

export default ChooseBike;
