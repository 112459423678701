import { Grid, Link } from '@mui/material';
import { formatDecimal, formatReferral, formatTime } from 'src/utils/helper';
import type {
  ReferralSubGraphData,
  ReferralTableData,
} from 'src/utils/interface';

import styles from './referralDashboardTable.module.scss';
import Text from '../../text';

interface Column {
  id:
    | 'publicKey'
    | 'timestamp'
    | 'referralAmount'
    | 'referralItemId'
    | 'transactionId';
  label: string;
  minWidth?: number;
  align?: 'left';
}

const columns: readonly Column[] = [
  {
    id: 'publicKey',
    label: 'PUBLIC KEY',
    minWidth: 170,
  },
  {
    id: 'referralAmount',
    label: 'Voucher Amount',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'referralItemId',
    label: 'Voucher ID',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'transactionId',
    label: 'TRANSACTION ID',
    minWidth: 170,
    align: 'left',
  },
];

function createTableData(items: ReferralSubGraphData[]): ReferralTableData[] {
  const tableData: ReferralTableData[] = [];
  items.map((item: ReferralSubGraphData) => {
    const publicKey = formatReferral(item.minter.id, 5, 7);
    const publicKeyRef =
      'https://mumbai.polygonscan.com/address/' + item.minter.id;
    const timestamp = formatTime(Number(item.blockTimestamp)).toString();
    const referralAmount = formatDecimal(item.referralAmount) + ' VOUCHERS';
    const transactionHash = formatReferral(item.transactionHash, 10, 10);
    const transactionIdRef =
      'https://mumbai.polygonscan.com/tx/' + item.transactionHash;
    tableData.push({
      publicKey: publicKey,
      publicKeyRef: publicKeyRef,
      timestamp: timestamp,
      referralAmount: item.referralAmount,
      referralItemid: item.referralItemId,
      transactionHash: transactionHash,
      transactionIdRef: transactionIdRef,
    });
  });
  return tableData;
}

const ReferralDashboardTableItem = (props: {
  displayData: ReferralSubGraphData[];
}) => {
  const tableData: ReferralTableData[] = createTableData(props.displayData);
  return (
    <>
      <Grid container className={styles.tableRH}>
        <Grid container className={styles.tableHeader}>
          {columns.map((column, index) => (
            <Grid item xs={3} key={column.id}>
              <Text type='h3' align={index === 2 ? 'center' : 'left'}>
                {column.label}
              </Text>
            </Grid>
          ))}
        </Grid>
        {tableData &&
          tableData.map((data: ReferralTableData, index: number) => {
            return (
              <Grid
                key={data.transactionHash}
                container
                className={`${
                  (index + 1) % 2 === 0 ? styles.even : styles.odd
                } ${styles.tableRow}`}
              >
                <Grid item xs={3} alignItems={'center'}>
                  <Link href={data.publicKeyRef} target='_blank'>
                    <Text className={styles.tableText} color={'#0097FF'}>
                      {data.publicKey}
                    </Text>
                  </Link>
                </Grid>
                <Grid item xs={3} alignItems={'center'}>
                  <Text className={styles.tableText} align={'center'}>
                    {data.referralAmount}
                  </Text>
                </Grid>
                <Grid item xs={3} alignItems={'center'}>
                  <Text className={styles.tableText} align={'center'}>
                    {data.referralItemid}
                  </Text>
                </Grid>
                <Grid item xs={3} alignItems={'center'}>
                  <Link href={data.transactionIdRef} target='_blank'>
                    <Text className={styles.tableText} color={'#0097FF'}>
                      {data.transactionHash}
                    </Text>
                  </Link>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default ReferralDashboardTableItem;
