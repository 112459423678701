import { CheckCircle, InfoRounded } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { svgs } from 'src/assets';
import loadingLottieBox from 'src/assets/lottie/loading-box-lottie.json';
import {
  defaultPVPRacePropsValue,
  getNumberOfParticipant,
  getPVPRaceProps,
  joinPVPRace,
  PVPRaceProps,
  startPVPRace,
} from 'src/pages/race/outlet/versus/race.logic';
import { selectAccountMelCoinBalance } from 'src/redux/accountMetamaskSlice';
import { RootState } from 'src/redux/store';
import { getMelCoinBalance } from 'src/services/contract/erc20/mel';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { race } from 'src/utils/constants/addresses';
import { useCountdown } from 'src/utils/timer';

import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';
import Stepper from 'src/components/lofi/stepper';
import Text from 'src/components/lofi/text';

import styles from './joinRace.module.scss';

const JoinRace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentMelCoin: string = useSelector(selectAccountMelCoinBalance);

  const { account } = useSelector((state: RootState) => state);
  const { activeBike } = useSelector((state: RootState) => state.race);

  const [raceProps, setRaceProps] = useState<PVPRaceProps>({
    ...defaultPVPRacePropsValue,
  });
  const [numberOfParticipant, setNumberOfParticipant] = useState<number>(0);
  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);

  const { minutes, seconds } = useCountdown(raceProps.currentRaceStartTime);

  const hasEnoughBBForJoinRace =
    Number(currentMelCoin) >= raceProps.raceEntryFee;

  const LoadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loadingLottieBox,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleGetRaceProps = async () => {
    const pvpRaceProps = await getPVPRaceProps(account);
    const playerNumber = await getNumberOfParticipant();

    setNumberOfParticipant(playerNumber);
    return setRaceProps(pvpRaceProps);
  };

  const handleJoinRace = async () => {
    if (activeBike && hasEnoughBBForJoinRace) {
      await joinPVPRace(
        dispatch,
        activeBike?.tokenId,
        waitingModalContent,
        setWaitingModalContent
      );
    }
  };

  const handleStartRace = async () => {
    if (raceProps.isPlayerJoined) {
      await startPVPRace(dispatch, waitingModalContent, setWaitingModalContent);
    }
  };

  const renderFooter = () => {
    const raceRequirementsFulfilled =
      minutes <= 0 &&
      seconds <= 0 &&
      numberOfParticipant >= raceProps.minPlayersToStart;

    const canJoinRace =
      numberOfParticipant < raceProps.maxPlayerPerRound &&
      !raceProps.isPlayerJoined;

    let buttonProps = {
      function: () => handleJoinRace(),
      text: 'Join Race',
      disabled: !canJoinRace || !hasEnoughBBForJoinRace,
    };

    if (raceProps.isPlayerJoined) {
      buttonProps = {
        ...buttonProps,
        function: () => handleStartRace(),
        text: 'Start race',
        disabled: !raceRequirementsFulfilled,
      };

      if (minutes > 0 && seconds > 0) {
        buttonProps.text = `Start race in ${minutes}:${seconds}`;
      }
    }

    if (raceProps.isPlayerJoined || canJoinRace) {
      return (
        <Grid container item xs={12} className={styles.footer} mt={4}>
          <Grid item xs={10} />
          <Grid item xs={2}>
            <Button
              variant='primary'
              onClick={buttonProps.function}
              size='small'
              className={styles.continueButton}
              disabled={buttonProps.disabled}
            >
              {canJoinRace ? (
                <>
                  <p>{buttonProps.text}</p>
                  <Box className={styles.joinRacePrice}>
                    <img width={16} height={16} src={svgs.melCoinIcon} />
                    <span>{raceProps.raceEntryFee}</span>
                  </Box>
                </>
              ) : (
                <p>{buttonProps.text}</p>
              )}
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  useEffect(() => {
    handleGetRaceProps();
  });

  useEffect(() => {
    const raceContract = initiateContract(race.address, race.abi);
    const raceStartedEvent = raceContract.filters.RaceStarted(null, null);

    raceContract.once(
      raceStartedEvent,
      async (userAddress: any, raceId: any) => {
        if (raceProps.raceId === BigNumber.from(raceId).toNumber()) {
          await getMelCoinBalance(dispatch, account.account);

          setWaitingModalContent({
            ...waitingModalContent,
            title: 'Race is in progress',
            message:
              'You will automatically be redirected to result page. Please wait a moment.',
            showViewInventory: false,
            openStatus: true,
          });
        }
      }
    );
  });

  useEffect(() => {
    const raceContract = initiateContract(race.address, race.abi);
    const raceFinishedEvent = raceContract.filters.RaceFinished(null);
    raceContract.once(raceFinishedEvent, (raceId: any) => {
      if (raceProps.raceId === BigNumber.from(raceId).toNumber()) {
        navigate('/race/versus/result', {
          state: {
            raceId: raceProps.raceId,
            raceStatus: 'finished',
          },
        });
      }
    });
  });

  return (
    <>
      <Grid container xs={12} className={styles.container}>
        <Grid item xs={12} className={styles.stepContainer}>
          <Grid item xs={7}>
            <Stepper text='Join Race' steps={3} currentStep={3} />
          </Grid>
        </Grid>
        <Grid container xs={12} className={styles.joinedPlayerContainer}>
          <Grid item xs={3} />
          <Grid
            item
            container
            xs={6}
            direction='column'
            justifyContent='center'
            alignItems='center'
            className={styles.container}
          >
            <Box>
              <Lottie options={LoadingLottie} width={105} />
            </Box>
            <Box className={styles.playerNumberContainer}>
              <Text type='h3'>{`${numberOfParticipant} / ${raceProps.maxPlayerPerRound}`}</Text>
              {minutes <= 0 &&
                seconds <= 0 &&
                numberOfParticipant >= raceProps.minPlayersToStart && (
                  <CheckCircle className={styles.playerNumberCheck} />
                )}
            </Box>
            <Text type='p' className={styles.playerNumberBody}>
              Waiting other racers to join...
            </Text>
            <Box className={styles.infoBoxContainer}>
              <InfoRounded />
              <p>
                If you start the race, you have to pay for the gas fee and get{' '}
                {raceProps.startRaceIncentive} MelCoins as an incentive.
              </p>
            </Box>
          </Grid>
          <Grid item xs={3} />
        </Grid>
        {renderFooter()}
      </Grid>
      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={() => setWaitingModalContent(defaultModalContent)}
      />
    </>
  );
};

export default JoinRace;
