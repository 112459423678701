import { Dispatch, SetStateAction } from 'react';

export interface PaginationStateType {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  page: number;
  totalPages: number;
  pageOffset: number;
  pageLimit: number;
}

export const PaginationInitialState = {
  hasNextPage: true,
  hasPrevPage: false,
  page: 1,
  totalPages: 0,
  pageDataLimit: 0,
  pageOffset: 0,
  pageLimit: 0,
};

export interface PaginationProps {
  pagination: PaginationStateType;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  className?: string;
}
