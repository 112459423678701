import { Grid } from '@mui/material';

import styles from './stepper.module.scss';

export interface StepperProps {
  text: string;
  currentStep: number;
  steps: number;
}

const Stepper = (props: StepperProps) => {
  const { text, currentStep, steps } = props;

  return (
    <Grid container item xs={12} className={styles.stepperContainer}>
      <Grid item xs={3}>
        <p className={styles.stepperText}>{text}</p>
      </Grid>
      <Grid container xs={9}>
        {[...Array(steps)].map((_, index) => (
          <Grid item xs={12 / steps}>
            <div
              className={`${styles.stepperItem} ${
                currentStep >= index + 1 && styles.stepperItemActive
              }`}
            >
              &nbsp;
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Stepper;
