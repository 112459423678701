import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { SignatureType, WhitelistStatus } from 'src/utils/interface';

import type { RootState } from './store';

interface TypeAccountMetamask {
  isLogin: boolean;
  account: string;
  melBalance: string;
  enoughMel: boolean;
  melCoin: string;
  energy: string;
  pubkey: string;
  message: string;
  signature: string;
  gameCoin: string;
  websocketUrl: string;
  whitelistStatus: WhitelistStatus;
  hasNft: boolean;
  nftCount: number;
  garageLimit: number;
  gachaVoucher: number;
}

const whitelistStatusDefaultValue = {
  isContractWhitelistOn: true,
  isUserWhitelisted: false,
  isUserCanMint: false,
};

const initialState: TypeAccountMetamask = {
  isLogin: false,
  account: '',
  melBalance: '',
  enoughMel: true,
  melCoin: '',
  energy: '',
  pubkey: '',
  message: '',
  signature: '',
  gameCoin: '',
  websocketUrl: process.env.REACT_APP_WEBSOCKET_URL ?? '',
  whitelistStatus: whitelistStatusDefaultValue,
  hasNft: false,
  nftCount: 0,
  garageLimit: 0,
  gachaVoucher: 0,
};

export const accountMetamaskSlice = createSlice({
  name: 'accountMetamask',
  initialState,
  reducers: {
    setLoginTrue: (state, action: PayloadAction<string>) => {
      state.isLogin = true;
      state.account = action.payload;
    },
    setLoginFalse: (state) => {
      state.isLogin = false;
      state.account = '';
    },
    setMelBalance: (state, action: PayloadAction<string>) => {
      state.melBalance = action.payload;
    },
    setMelBalanceEmpty: (state) => {
      state.melBalance = '';
    },
    setEnoughMelTrue: (state) => {
      state.enoughMel = true;
    },
    setEnoughMelFalse: (state) => {
      state.enoughMel = false;
    },
    setMelCoinBalance: (state, action: PayloadAction<string>) => {
      state.melCoin = action.payload;
    },
    setMelCoinBalanceEmpty: (state) => {
      state.melCoin = '';
    },
    setEnergyBalance: (state, action: PayloadAction<string>) => {
      state.energy = action.payload;
    },
    setEnergyBalanceEmpty: (state) => {
      state.energy = '';
    },
    setSignature: (state, action: PayloadAction<SignatureType>) => {
      state.pubkey = action.payload.pubkey;
      state.message = action.payload.message;
      state.signature = action.payload.signature || '';
    },
    setSignatureEmpty: (state) => {
      state.pubkey = '';
      state.message = '';
      state.signature = '';
    },
    setGameCoinBalance: (state, action: PayloadAction<string>) => {
      state.gameCoin = action.payload;
    },
    setGameCoinBalanceEmpty: (state) => {
      state.gameCoin = '';
    },
    setWebsocketUrl: (state, action: PayloadAction<string>) => {
      state.websocketUrl = action.payload;
    },
    setWhitelistStatus: (state, action: PayloadAction<WhitelistStatus>) => {
      state.whitelistStatus = action.payload;
    },
    setWhitelistStatusEmpty: (state) => {
      state.whitelistStatus = whitelistStatusDefaultValue;
    },
    setHasNft: (state, action: PayloadAction<boolean>) => {
      state.hasNft = action.payload;
    },
    setNftCount: (state, action: PayloadAction<number>) => {
      state.nftCount = action.payload;
    },
    setGarageLimit: (state, action: PayloadAction<number>) => {
      state.garageLimit = action.payload;
    },
    setGachaVoucher: (state, action: PayloadAction<number>) => {
      state.gachaVoucher = action.payload;
    },
  },
});

export const {
  setLoginTrue,
  setLoginFalse,
  setMelBalance,
  setMelBalanceEmpty,
  setEnoughMelTrue,
  setEnoughMelFalse,
  setMelCoinBalance,
  setMelCoinBalanceEmpty,
  setEnergyBalance,
  setEnergyBalanceEmpty,
  setSignature,
  setSignatureEmpty,
  setGameCoinBalance,
  setGameCoinBalanceEmpty,
  setWebsocketUrl,
  setWhitelistStatus,
  setWhitelistStatusEmpty,
  setHasNft,
  setNftCount,
  setGarageLimit,
  setGachaVoucher,
} = accountMetamaskSlice.actions;

// Selections - This is how we pull information from the Global store slice
export const selectAccountLoginState = (state: RootState) =>
  state.account.isLogin;
export const selectAccountAddress = (state: RootState) => state.account.account;
export const selectAccountMelBalance = (state: RootState) =>
  state.account.melBalance;
export const selectAccountEnoughMel = (state: RootState) =>
  state.account.enoughMel;
export const selectAccountEnergyBalance = (state: RootState) =>
  state.account.energy;
export const selectAccountMelCoinBalance = (state: RootState) =>
  state.account.melCoin;
export const selectAccountPubKey = (state: RootState) => state.account.pubkey;
export const selectAccountMessage = (state: RootState) => state.account.message;
export const selectAccountSignature = (state: RootState) =>
  state.account.signature;
export const selectAccountGameCoin = (state: RootState) =>
  state.account.gameCoin;
export const selectWebsocketUrl = (state: RootState) =>
  state.account.websocketUrl;
export const selectAccountWhitelistStatus = (state: RootState) =>
  state.account.whitelistStatus;
export const selectHasNft = (state: RootState) => state.account.hasNft;
export const selectNftCount = (state: RootState) => state.account.nftCount;
export const selectGarageLimit = (state: RootState) =>
  state.account.garageLimit;
export const selectGachaVoucher = (state: RootState) =>
  state.account.gachaVoucher;

export default accountMetamaskSlice.reducer;
