import { useQuery } from '@apollo/client';
import { Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  OwnedPartProps,
  UserPartProps,
} from 'src/pages/workshop/workshop.type';
import { GET_VOUCHER } from 'src/utils/constants/query';

import RarityBox from 'src/components/lofi/box/rarityBox';
import GradientButton from 'src/components/lofi/gradientButton';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';
import Text from 'src/components/lofi/text';

import { consumeVoucher } from './bikeDetail.logic';
import styles from './bikeDetail.module.scss';
import { BikeDetailProps } from './bikeDetail.type';
import BikeAttributes from '../bikeAttributes';

function BikeDetail(props: BikeDetailProps) {
  const { bike, parts, close, refetchUserVouchers, refetchOwnedBikes } = props;
  const dispatch = useDispatch();

  const [isDropdownDisabled, setIsDropdownDisabled] = useState<boolean>(false);
  const [acceleration, setAcceleration] = useState<number>(0);
  const [speed, setSpeed] = useState<number>(0);
  const [drift, setDrift] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<string>('none');
  // const availableParts = part.filter((part) => part.bike === null || part.id !== selectedItem);
  const [error, setError] = useState<string>('');
  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);

  const { data: voucher, refetch: refetchVoucher } = useQuery(GET_VOUCHER, {
    fetchPolicy: 'network-only',
    variables: {
      voucherId: selectedItem,
    },
  });

  const closeModalHandle = () => {
    setWaitingModalContent(defaultModalContent);
  };

  const setStatusHandle = (part: OwnedPartProps) => {
    setAcceleration(part.accelerationEffect);
    setSpeed(part.speedEffect);
    setDrift(part.driftEffect);
  };

  const handleConsumeVoucher = async () => {
    setIsDropdownDisabled(true);
    await consumeVoucher(
      bike.id,
      selectedItem,
      waitingModalContent,
      setWaitingModalContent,
      dispatch
    );

    setStatusHandle(voucher.voucher);
    setIsDropdownDisabled(false);
  };

  const handleSelectItem = (e: SelectChangeEvent<string>) => {
    setSelectedItem(e.target.value);
  };

  const handleSaveChangesButton = () => {
    if (selectedItem !== 'none') {
      refetchVoucher({ voucherId: selectedItem });
      handleConsumeVoucher();
      refetchUserVouchers();
      refetchOwnedBikes();
    }
  };

  return (
    <>
      <div className={styles.bikeModalDetail}>
        <Text type='h1' align='center' className={styles.modalTitle}>
          Bike details
        </Text>
        <Grid container spacing={2} className={styles.partDetail}>
          <Grid item xs={7}>
            <div className={styles.bikeImage}>
              <img src={bike.imageUrl} />
            </div>
          </Grid>
          <Grid item xs={5}>
            <RarityBox type={bike.rarity} />
            <Text type={'h3'} className={styles.bikeName}>
              {bike.name}
            </Text>
            <Text style={{ textTransform: 'capitalize' }}>
              {`Type: ${bike.type}`}
            </Text>
            <Text>{`Level: ${bike.level}`}</Text>
            <BikeAttributes
              attributes={{
                speed: bike.S,
                acceleration: bike.A,
                drift: bike.D,
              }}
              isDetailed={true}
              boostedAttributes={{ speed, acceleration, drift }}
            />
            <Text>Voucher: </Text>
            <Select
              value={selectedItem}
              disabled={isDropdownDisabled}
              onChange={(e) => {
                handleSelectItem(e);
                setError('');
              }}
              className={styles.selectPart}
              sx={{
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
                '&:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: '1px solid transparent',
                  },
              }}
            >
              <MenuItem value={'none'} sx={{ fontFamily: 'Inter' }}>
                None
              </MenuItem>
              {parts.map((userVoucher: UserPartProps) => {
                if (userVoucher.amount > 0) {
                  return (
                    <MenuItem
                      value={userVoucher.voucher.id}
                      key={`partkey-${userVoucher.voucher.id}`}
                      sx={{ fontFamily: 'Inter' }}
                    >
                      {userVoucher.voucher.name} x{userVoucher.amount}
                    </MenuItem>
                  );
                }
              })}
            </Select>
            <Text type='h5' className={styles.errorText}>
              {error}
            </Text>
          </Grid>
          <Grid item xs={12} className={styles.buttonWrap}>
            <GradientButton
              theme='secondary'
              title={'Cancel'}
              onClick={() => close()}
            />
            <GradientButton
              title={'Use Voucher'}
              onClick={() => handleSaveChangesButton()}
              disabled={selectedItem === 'none' || isDropdownDisabled}
            />
          </Grid>
        </Grid>
      </div>
      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={closeModalHandle}
      />
    </>
  );
}

export default BikeDetail;
