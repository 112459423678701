import { Button, Grid } from '@mui/material';
import { useState } from 'react';

import styles from './historySection.module.scss';
import PVPHistory from './pvp';
import SingleRaceHistory from './single';

const RaceHistory = () => {
  const [activeTab, setActiveTab] = useState('pvp');

  return (
    <div className={styles.content}>
      <Grid container className={styles.modeButtonContainer}>
        <Button
          variant='contained'
          onClick={() => setActiveTab('pvp')}
          className={activeTab == 'pvp' ? styles.activeButton : ''}
        >
          Player versus Player
        </Button>
        <Button
          variant='contained'
          onClick={() => setActiveTab('single')}
          className={activeTab == 'single' ? styles.activeButton : ''}
        >
          Single Player
        </Button>
      </Grid>
      {activeTab === 'pvp' ? <PVPHistory /> : <SingleRaceHistory />}
    </div>
  );
};

export default RaceHistory;
