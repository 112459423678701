import BackButton from 'src/components/lofi/button/backButton';
import Text from 'src/components/lofi/text';
import styles from './error.module.scss';

function ErrorPage() {
  return (
    <div className={styles.errorPage}>
      <Text type={'h1'} style={{ fontSize: 100, marginBottom: 30 }}>
        404
      </Text>
      <Text type={'h1'} style={{ marginBottom: 40 }}>
        PAGE NOT FOUND
      </Text>
      <Text style={{ opacity: 0.6, marginBottom: 40 }}>
        This page is not available in Melx Universe
      </Text>
      <BackButton />
    </div>
  );
}

export default ErrorPage;
