import { useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type {
  ReferralDashboardData,
  ReferralProps,
} from 'src/pages/referralDashboard/referralDashboard.type';
import { selectAccountAddress } from 'src/redux/accountMetamaskSlice';
import { selectHomeTabState } from 'src/redux/gameInterfaceSlice';
import { GET_REF_MEL } from 'src/utils/constants/query';

import EmptyTable from 'src/components/hifi/emptyTable';
import LoadingTable from 'src/components/hifi/loadingTable';
import PageHeader from 'src/components/hifi/pageHeader';
import TabButton from 'src/components/lofi/button/tabButton';
import SummaryItemGrid from 'src/components/lofi/grid/referralSummaryGrid';
import {
  PaginationInitialState,
  PaginationStateType,
} from 'src/components/lofi/pagination/pagination.type';
import ReferralDashboardTableItem from 'src/components/lofi/table/referralDashboardTable';

import styles from './homepage.module.scss';
import {
  fetchingReferral,
  getReferralDataByPage,
} from '../referralDashboard/referralDashboard.logic';

function Homepage() {
  const dispatch = useDispatch();
  const tabState = useSelector(selectHomeTabState);
  const [totalReferralMel, setTotalReferralMel] = useState<string>('0');
  const [totalUserUsed, setTotalUserUsed] = useState<number>(0);
  const [totalReferralUsed, setTotalReferralUsed] = useState<number>(0);
  const [displayData, setDisplayData] = useState<ReferralDashboardData[]>([]);
  const [_preRender, setPreRender] = useState<boolean>(false);
  const [refCurrentPage, setRefCurrentPage] = useState<number>(1);
  const [referralData, setReferralData] = useState<ReferralDashboardData[]>([]);
  const [refPagination, setRefPagination] = useState<PaginationStateType>(
    PaginationInitialState
  );
  const [refLoading, setRefLoading] = useState<boolean>(true);

  const [getReferral] = useLazyQuery(GET_REF_MEL, {
    fetchPolicy: 'network-only',
  });
  const currentAccount: string = useSelector(selectAccountAddress);

  const TotalReferralMelProps: ReferralProps = {
    firstRowText: 'TOTAL EARNED',
    secondRowText: totalReferralMel + ' VOUCHERS',
    rowTwoAlign: 'right',
  };

  const TotalUserUsedProps: ReferralProps = {
    firstRowText: 'TOTAL USERS USED',
    secondRowText: totalUserUsed + ' USERS',
    rowTwoAlign: 'left',
  };

  const TotalReferralUsedProps: ReferralProps = {
    firstRowText: 'TOTAL REFERRAL USED',
    secondRowText: totalReferralUsed + 'x',
    rowTwoAlign: 'left',
  };

  useEffect(() => {
    fetchingReferral(
      currentAccount,
      setTotalReferralMel,
      getReferral,
      setDisplayData,
      setTotalReferralUsed,
      setTotalUserUsed,
      setPreRender,
      setRefLoading
    );
  }, [currentAccount]);

  useEffect(() => {
    displayData.length !== 0 &&
      getReferralDataByPage(
        displayData,
        setReferralData,
        refCurrentPage,
        refPagination,
        setRefPagination
      );
  }, [displayData, refCurrentPage]);

  return (
    <main className={styles.homeSection}>
      {/* NAVBAR SECTION  */}
      <PageHeader pageTitle='Home' />
      {/* CONTENT SECTION  */}
      <Grid container className={styles.mainContainer}>
        <Grid item xs={2}>
          <article className={styles.tabSection}>
            <TabButton data={{ value: 'Referral' }} state='referral' />
          </article>
        </Grid>
        <Grid item xs={10} className={styles.contentSection}>
          <article className={styles.content}>
            <SummaryItemGrid
              TotalReferralMelProps={TotalReferralMelProps}
              TotalUserUsedProps={TotalUserUsedProps}
              TotalReferralUsedProps={TotalReferralUsedProps}
            />
            {refLoading ? (
              <LoadingTable className={styles.refLoading} />
            ) : referralData.length !== 0 ? (
              <>
                <ReferralDashboardTableItem displayData={referralData} />
                {/* <Pagination
                  pagination={refPagination}
                  setCurrentPage={setRefCurrentPage}
                  className={styles.pagination}
                /> */}
              </>
            ) : (
              <EmptyTable
                title={'There is no transaction'}
                description={`After you've made the transaction, it will appear here`}
              />
            )}
          </article>
        </Grid>
      </Grid>
    </main>
  );
}

export default Homepage;
