import { useQuery } from '@apollo/client';
import { Box, Grid, Skeleton } from '@mui/material';
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccountAddress } from 'src/redux/accountMetamaskSlice';
import { GET_ENERGY_PACKAGES } from 'src/utils/constants/query';
import { formatBigNumberToString } from 'src/utils/helper';

import PageHeader from 'src/components/hifi/pageHeader';
import { MelCoinIcon } from 'src/components/Icon';
import GlowBox from 'src/components/lofi/box/glowBox';
import EnergyPackageCard from 'src/components/lofi/card/energyPackageCard';
import GradientButton from 'src/components/lofi/gradientButton';
import ConfirmModal from 'src/components/lofi/modal/confirmModal';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';

import { purchaseHandle, selectHandle } from './chargingStation.logic';
import styles from './chargingStation.module.scss';
import type {
  EnergyPackage,
  SelectedButtonProps,
} from './chargingStation.type';

function ChargingStation() {
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectAccountAddress);
  const [selectedButton, setSelectedButton] = useState<SelectedButtonProps>({
    id: -1,
    energy: 0,
    cost: 0,
  });
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const openHandle = () => setShowDialog(true);
  const closeHandle = () => {
    setShowDialog(false);
  };
  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);
  function closeModalHandle() {
    setWaitingModalContent(defaultModalContent);
  }

  const {
    loading: energyPackagesLoading,
    data: energyPackages,
    refetch: refetchEnergyPackages,
  } = useQuery(GET_ENERGY_PACKAGES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetchEnergyPackages();
  }, [refetchEnergyPackages]);

  return (
    <main className={styles.container}>
      <PageHeader pageTitle='Charging Station' />
      {/* ENERGY OPTIONS */}
      <section className={styles.optionRow}>
        {energyPackagesLoading ? (
          <Box className={styles.optionRow}>
            <Skeleton
              animation='wave'
              variant='rectangular'
              sx={{ width: '80vw', height: '250px' }}
            />
          </Box>
        ) : (
          <Grid container spacing={2} justifyContent={'center'}>
            {energyPackages?.energies.length > 0 &&
              energyPackages.energies.map((energy: EnergyPackage) => (
                <Grid item xs={3} key={`package-${energy.id}`}>
                  <GlowBox
                    isActive={selectedButton.id === Number(energy.id)}
                    onClick={() =>
                      selectHandle(
                        Number(energy.id),
                        Number(
                          formatBigNumberToString(BigNumber.from(energy.price))
                        ),
                        Number(energy.amount),
                        selectedButton,
                        setSelectedButton
                      )
                    }
                    style={{ backgroundColor: 'black' }}
                  >
                    <EnergyPackageCard
                      name={energy.name}
                      amount={energy.amount}
                      image={'url'}
                      price={formatBigNumberToString(
                        BigNumber.from(energy.price)
                      )}
                    />
                  </GlowBox>
                </Grid>
              ))}
          </Grid>
        )}
      </section>
      {/* PROMPT BUTTON */}
      <section className={styles.buttonRow}>
        <GradientButton
          title='PURCHASE'
          disabled={selectedButton.id === -1 ? true : false}
          onClick={openHandle}
        />
        <ConfirmModal
          open={showDialog}
          onClose={() => closeHandle()}
          modalTitle={'Confirm purchase'}
          modalDetailText={`Are you sure want to buy ${selectedButton.energy}X ENERGY?`}
          onCancelButtonClick={() => closeHandle()}
          onConfirmButtonClick={() => {
            closeHandle();
            purchaseHandle(
              currentAccount,
              selectedButton.id,
              dispatch,
              setWaitingModalContent
            );
          }}
          confirmButtonTitle={
            <div className={styles.confirmBtnCS}>
              Purchase
              <div className={styles.iconInBtn}>
                <MelCoinIcon length='20px' />
              </div>
              {selectedButton.cost} MEL
            </div>
          }
        />
      </section>
      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={closeModalHandle}
      />
    </main>
  );
}

export default ChargingStation;
