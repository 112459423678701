import { Box, ButtonBase, Stack, TextField } from '@mui/material';
import { BigNumber, ethers } from 'ethers';
import * as React from 'react';
import Lottie from 'react-lottie';
import { useDispatch } from 'react-redux';
import BikeHelmetLottie from 'src/assets/lottie/get-your-motorbike-lottie.json';
import { AppDispatch } from 'src/redux/store';
import { getAccount } from 'src/services/contract/metamask/account';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { nft } from 'src/utils/constants/addresses';
import {
  errorOwnReferralCode,
  errorRefferalNotOwnBBNFT,
  invalidReferralCode,
} from 'src/utils/constants/errorMessageConstants';
import { gachaWithoutReferral, gachaWithReferral } from './referralModal.logic';
import styles from './referralModal.module.scss';
import { ModalProps } from '../waitingModal';
import GradientModal from '../gradientModal';
import GradientButton from '../../gradientButton';

interface ReferralModal {
  open: boolean;
  handleClose: () => void;
  waitingModalContent: ModalProps;
  setWaitingModalContent: React.Dispatch<React.SetStateAction<ModalProps>>;
}

const ReferralModal = (props: ReferralModal) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [referralAddress, setReferralAddress] = React.useState<string>('');
  const [showWarning, setShowWarning] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isInputEmpty, setIsInputEmpty] = React.useState<boolean>(true);
  const [isInputDisabled, setIsInputDisabled] = React.useState<boolean>(false);

  const isAddressValidHandler = (address: string): boolean => {
    const isAddressValid = ethers.utils.isAddress(address);
    return isAddressValid;
  };

  const isOwnAddressHandler = async (address: string): Promise<boolean> => {
    const connectedAddress: any = await getAccount();
    const referralAddress = ethers.utils.getAddress(address);

    const ethersFormatedConnectedAddress: string =
      ethers.utils.getAddress(connectedAddress);

    if (ethersFormatedConnectedAddress === referralAddress) {
      setShowWarning(true);
      setErrorMessage(errorOwnReferralCode);
      return true;
    } else {
      setShowWarning(false);
      setErrorMessage('');
      return false;
    }
  };

  const inputAddressHandler = (e: any) => {
    setReferralAddress(e.target.value);
    if (e.target.value.trim().length > 0) {
      setIsInputEmpty(false);
    } else {
      setIsInputEmpty(true);
    }
  };

  const isAddressHoldNFT = async (address: string): Promise<boolean> => {
    const nftContract = initiateContract(nft.address, nft.abi);

    const userNFTs: BigNumber = await nftContract.balanceOf(address);

    // BigNumber.gt stand for > otherValue.
    if (userNFTs.gt(0)) {
      setShowWarning(false);
      setErrorMessage('');
      return true;
    } else {
      setShowWarning(true);
      setErrorMessage(errorRefferalNotOwnBBNFT);
      return false;
    }
  };

  const submitFormHandler = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const isAddressValid = isAddressValidHandler(referralAddress);

    if (referralAddress !== null && isAddressValid) {
      const isOwnAddress = await isOwnAddressHandler(referralAddress);
      const isRefAddressHoldnft = await isAddressHoldNFT(referralAddress);

      if (!isOwnAddress && isRefAddressHoldnft) {
        setIsInputDisabled(true);
      } else {
        setReferralAddress('');
        setIsInputDisabled(false);
      }
    } else {
      setReferralAddress('');
      setShowWarning(true);
      setErrorMessage(invalidReferralCode);
    }

    setTimeout(() => setIsLoading(false), 1000);
  };

  React.useEffect(() => {
    if (window.location.search.length !== 0) {
      const refLocation = window.location.search.slice(1).split('refCode=')[1];

      if (refLocation !== undefined) {
        setReferralAddress(refLocation.toString());
        setIsInputEmpty(false);
      }
    }
  }, []);

  const bikeHelmetLottie = {
    loop: true,
    autoplay: true,
    animationData: BikeHelmetLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <GradientModal open={props.open} onClose={props.handleClose}>
      <>
        <Box sx={{ marginBottom: '2rem' }}>
          <Lottie options={bikeHelmetLottie} width={200}></Lottie>
        </Box>
        <form onSubmit={submitFormHandler}>
          <TextField
            className={styles.textField}
            helperText={
              !showWarning
                ? 'The referral code owner will get incentives after you successfully buy an NFT.'
                : errorMessage
            }
            id='filled-basic'
            label='Add referral code'
            variant='filled'
            sx={{ color: 'white', minWidth: '500px' }}
            value={`${referralAddress}${isInputDisabled ? '✅' : ''}`}
            onChange={inputAddressHandler}
            autoComplete='off'
            disabled={isInputDisabled}
          />

          <Stack
            flexDirection='row'
            justifyContent='center'
            marginTop='2rem'
            sx={{ gap: '1rem' }}
          >
            <GradientButton
              position={'left'}
              theme='secondary'
              title={'Skip'}
              onClick={() =>
                gachaWithoutReferral(
                  dispatch,
                  props.handleClose,
                  props.waitingModalContent,
                  props.setWaitingModalContent
                )
              }
              style={{ height: 56 }}
            />
            {isInputDisabled ? (
              <GradientButton
                title={'Continue'}
                onClick={() =>
                  gachaWithReferral(
                    dispatch,
                    referralAddress,
                    props.handleClose,
                    props.waitingModalContent,
                    props.setWaitingModalContent
                  )
                }
              />
            ) : (
              <ButtonBase type='submit' disabled={isInputEmpty || isLoading}>
                <GradientButton
                  title={!isLoading ? 'Check Referral Code' : 'Loading ...'}
                  disabled={isInputEmpty || isLoading}
                  onClick={() => null}
                />
              </ButtonBase>
            )}
          </Stack>
        </form>
      </>
    </GradientModal>
  );
};

export default ReferralModal;
