import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import EtherSpinning from 'src/assets/lottie/etherSpinning.json';

import styles from './wrongNetworkSection.module.scss';

const WrongNetworkSection = () => {
  const etherSpin = {
    loop: true,
    autoplay: true,
    animationData: EtherSpinning,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const switchNetworkHandle = async () => {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: process.env.REACT_APP_METAMASK_CHAINID }],
    });
  };
  return (
    <section className={styles.wrongNetwork}>
      <Box className={styles.blurBg}></Box>
      <Box className={styles.section}>
        <Box className={styles.background}></Box>
        <Box>
          <Lottie options={etherSpin} width={200} height={150}></Lottie>
        </Box>
        <Typography
          variant='h6'
          component='h6'
          sx={{ fontWeight: '600', width: '100%', color: 'white' }}
        >
          Mel X - BikeBattle is only available on Mumbai Network.
        </Typography>
        <Typography variant='body1' component='p' sx={{ mt: 2, width: '100%' }}>
          Kindly switch your metamask network into Mumbai Network.
        </Typography>
        <Button
          onClick={() => switchNetworkHandle()}
          variant='primary'
          sx={{ marginTop: '34px', fontSize: '1rem' }}
        >
          Switch Network
        </Button>
      </Box>
    </section>
  );
};

export default WrongNetworkSection;
