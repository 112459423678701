import { formatDistanceToNowStrict, fromUnixTime } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { BigNumber, BigNumberish, ethers } from 'ethers';

import { modalUserRejectedTheApproval } from './constants/errorMessageConstants';

/**
 *
 * @param address / full-length address
 * @returns string address that had been shortened
 */
export function formatAddress(address: string) {
  const addressFormated = `${address.slice(0, 5)}...${address.slice(
    address.length - 4,
    address.length
  )}`;
  return addressFormated;
}

/**
 *
 * @param text / the raw input of string
 * @param first / first part that shows
 * @param last / last part that shows
 * @returns string that had been shortened
 */
export function formatReferral(text: string, first: number, last: number) {
  const referralFormated = `${text.slice(0, first)}.....${text.slice(
    text.length - last,
    text.length
  )}`;
  return referralFormated;
}

/**
 *
 * @param time / unix format
 * @returns formatted date in string
 */
export function formatTime(time: number) {
  //* step 1 - convert from unix to Date format
  const parsedTime = fromUnixTime(time);
  //* step 2 - function to change timezone to +0
  const formatInTimeZone = (date: any, fmt: any, tz: any) =>
    format(utcToZonedTime(date, tz), fmt, { timeZone: tz });
  const result = formatInTimeZone(parsedTime, 'dd-MMM-yyyy HH:mm OOOOO', 'UTC');
  return result;
}
/**
 *
 * @param amount / BigNumberish divided by 10^18
 * @returns formatted number
 */
export function formatDecimal(amount: BigNumberish): string {
  const result: string = ethers.utils.formatUnits(amount, 18);
  return result;
}

export function formatBigNumberToString(amount: BigNumber) {
  const result = ethers.utils.formatEther(amount.toString());
  return result;
}

export function formatStringToBigNumber(amount: string) {
  if (amount == '') return BigNumber.from(0);
  const result = ethers.utils.parseUnits(amount);
  return result;
}

export function formatStringToDecimal(amount: string): string {
  const result = Number(ethers.utils.formatEther(amount.toString())).toFixed(2);
  return result;
}

export function getReferralText(account: string): string {
  return `I’ve earned money from this click-to-earn game called Bike Battle. Join me fight with this referral ${account} or by https://bikebattle.melx.io/?refCode=${account}`;
}

export const isNew = (dateCreated: number, duration: number): boolean => {
  const result = formatDistanceToNowStrict(fromUnixTime(dateCreated), {
    unit: 'hour',
  });
  const resultNumber = Number(result.replace(/[^0-9 -]/g, ''));
  if (duration > resultNumber) {
    return true;
  } else {
    return false;
  }
};

export function formatRaceDate(data: string) {
  const sliceData = data.slice(11, 19) + ' | ' + data.slice(0, 10);
  return sliceData;
}

export function filterObject(arrayOfTypeObject: any) {
  const filterType = Object.fromEntries(
    Object.entries(arrayOfTypeObject).filter(([key, val]) => val !== 0)
  );
  const objectToArray = Object.keys(filterType).map((key) => [
    key,
    filterType[key],
  ]);
  return objectToArray;
}

export function decimalLimit(value: number, decimal: number) {
  return Number(
    (Math.floor(value * 10 ** decimal) / 10 ** decimal).toFixed(decimal)
  );
}

export function devLog(text: any): void {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log('console:', text);
  }
}

// to create pagination from array data
export function paginate(arr: any, size: number) {
  return arr.reduce((acc: any, val: any, i: number) => {
    const idx = Math.floor(i / size);
    const page = acc[idx] || (acc[idx] = []);
    page.push(val);

    return acc;
  }, []);
}

export function getTxError(err: any): any {
  let modalContent = {
    ...modalUserRejectedTheApproval,
  };

  const regexRule = /(?<=execution reverted: ).*/;
  const txFailedRule = /(?<=Error: transaction failed).*/;

  if (txFailedRule.exec(err)) {
    modalContent = {
      title: 'Something went wrong',
      message:
        'Something went wrong when processing your transaction. Please try again later.',
      showViewInventory: false,
      openStatus: true,
    };
  } else if (err?.code != 4001) {
    const message = regexRule.exec(err.data.message);

    if (message) {
      modalContent = {
        title: 'Something went wrong',
        message: message[0],
        showViewInventory: false,
        openStatus: true,
      };
    }
  }

  return modalContent;
}
