import { gql } from '@apollo/client';

export const GET_NFT_USER = gql`
  query UserNft($owner: ID!) {
    nfts(where: { owner: $owner }) {
      owner {
        id
      }
      id
      tokenId: id
      name
      imageUrl: image
      type: bikeType
      rarity
      level
      A: acceleration
      S: speed
      D: drift
    }
  }
`;

export const GET_REF_MEL = gql`
  query GET_REF_MEL($user: ID!) {
    user(id: $user) {
      id
      referralRewards {
        feeAmount
        feeReceiver
        id
        referralAmount
        referralItemId
        minter {
          id
        }
        referral {
          id
        }
        blockTimestamp
        feeAmount
        transactionHash
      }
    }
  }
`;

export const GET_USER_RACE_HISTORY = gql`
  query userRaces($userAddress: String!, $offset: Int, $limit: Int) {
    userRaces(
      where: { userAddress: $userAddress, isDisplayed: true }
      skip: $offset
      first: $limit
      orderBy: id
      orderDirection: desc
    ) {
      race {
        id
        status
      }
      userAddress
      nftId
      nftName
      isPrizeClaimed
      claimPrize {
        prize
        roundId
        rank
      }
    }
  }
`;

export const GET_USER_SINGLE_RACE_HISTORY = gql`
  query userSingleRaces($userAddress: String!, $offset: Int, $limit: Int) {
    raceResults(
      where: { playerAddress: $userAddress }
      skip: $offset
      first: $limit
      orderBy: blockTimestamp
      orderDirection: desc
    ) {
      mel
      exp
      nftId
      nftName
      blockTimestamp
    }
  }
`;

export const GET_ENERGY_PACKAGES = gql`
  query energyPackages {
    energies {
      id
      name
      amount
      price
      isAvailable
    }
  }
`;

//ID 7 = gacha voucer ID
export const GET_VOUCHERS = gql`
  query vouchers {
    vouchers(where: { price_gt: "0", id_not_in: [7] }) {
      id
      driftEffect
      accelerationEffect
      imageCid
      name
      price
      speedEffect
    }
  }
`;

export const GET_USER_VOUCHERS = gql`
  query userVouchers($userAddress: String!) {
    user(id: $userAddress) {
      id
      vouchers(where: { amount_gt: 0 }) {
        id
        amount
        voucher {
          accelerationEffect
          driftEffect
          id
          imageCid
          name
          price
          speedEffect
        }
      }
    }
  }
`;

export const GET_VOUCHER = gql`
  query voucher($voucherId: String!) {
    voucher(id: $voucherId) {
      accelerationEffect
      driftEffect
      id
      imageCid
      name
      price
      speedEffect
    }
  }
`;
