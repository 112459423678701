import LogoutIcon from '@mui/icons-material/Logout';
import { ButtonBase, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MetamaskIcon from 'src/assets/svg/MetamaskIcon';
import {
  selectAccountAddress,
  selectAccountLoginState,
  setGarageLimit,
  setHasNft,
  setLoginFalse,
  setMelBalance,
  setNftCount,
} from 'src/redux/accountMetamaskSlice';
import { getMelBalance } from 'src/services/contract/erc20/mel';
import { getAccount } from 'src/services/contract/metamask/account';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { getNftTotalCount } from 'src/services/contract/nft/nft';
import { nft } from 'src/utils/constants/addresses';
import {
  devLog,
  formatAddress,
  formatBigNumberToString,
} from 'src/utils/helper';

import GradientButton from 'src/components/lofi/gradientButton';
import Text from 'src/components/lofi/text';

import { connectMetamask, logOutAccount } from './metamaskButton.logic';
import { getUserGachaVoucher } from 'src/services/contract/game/gameItem';

function MetamaskButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentAccount = useSelector(selectAccountAddress);
  const displayAccount = formatAddress(currentAccount);
  const isLogin = useSelector(selectAccountLoginState);
  // HANDLE BUTTON DROPDOWN
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const setNftHandle = async () => {
    const account = await getAccount();
    const nftContract = initiateContract(nft.address, nft.abi);
    const nftCount = await getNftTotalCount(nftContract, account);
    const garageLimit = await nftContract.getUserGarageLimit(account);

    dispatch(setGarageLimit(garageLimit));
    dispatch(setNftCount(nftCount));
    dispatch(setHasNft(nftCount > 0));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // END OF HANDLE BUTTON DROPDOWN

  const handleLogoutButton = () => {
    logOutAccount(dispatch);
    handleClose();
    dispatch(setLoginFalse());
    navigate('/');
  };

  // HANDLE LOGIN BUTTON
  const loginButtonHandle = async () => {
    try {
      await connectMetamask(dispatch);
      setNftHandle();
    } catch (err) {
      devLog(err);
      return;
    }
  };
  // END OF HANDLE LOGIN BUTTON

  const handleMelBalance = async (stateAccount: string) => {
    const melBalance = await getMelBalance(stateAccount);
    const melBalanceString: string = formatBigNumberToString(melBalance);
    dispatch(setMelBalance(melBalanceString));
  };

  const handleGachaVoucher = async (stateAccount: string) => {
    await getUserGachaVoucher(stateAccount, dispatch);
  };

  useEffect(() => {
    /**
     * Refetch balance everytime to get update balance
     */
    if (isLogin) {
      handleMelBalance(currentAccount);
      handleGachaVoucher(currentAccount);
    }
  }, [isLogin]);

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      isLogin && getMelBalance(currentAccount);
    }
  }, [displayAccount]);

  return (
    <>
      <ButtonBase onClick={isLogin ? handleClick : loginButtonHandle}>
        <GradientButton
          theme='primary-m'
          title={isLogin ? displayAccount : 'Connect Wallet'}
          onClick={() => null}
          style={{ height: 48 }}
          leftContent={
            <div style={{ marginRight: 10, marginTop: 3 }}>
              <MetamaskIcon />
            </div>
          }
          textStyle={{ fontWeight: 600 }}
        />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            background:
              'linear-gradient(89.84deg, #FFDB01 -46.23%, #FF851F 5.03%, #FF3D3D 38.29%, #FF0F6F 74.04%, #FE01FA 129.67%)',
            color: '#fff',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              background:
                'linear-gradient(89.84deg, #FF0F6F 74.04%, #FE01FA 129.67%)',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleLogoutButton}>
          <LogoutIcon fontSize='small' sx={{ marginRight: '5px' }} />
          <Text type={'h5'}>Logout</Text>
        </MenuItem>
      </Menu>
    </>
  );
}
export default MetamaskButton;
