import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../redux/store';

interface TypeModalShow {
  show: boolean;
}

const initialState: TypeModalShow = {
  show: false,
};

export const modalShowSlice = createSlice({
  name: 'modalShow',
  initialState,
  reducers: {
    setModalShowTrue: (state) => {
      state.show = true;
    },
    setModalShowFalse: (state) => {
      state.show = false;
    },
  },
});

export const { setModalShowTrue, setModalShowFalse } = modalShowSlice.actions;

// Selections - This is how we pull information from the Global store slice
export const selectModalShow = (state: RootState) => state.modalShow.show;

export default modalShowSlice.reducer;
