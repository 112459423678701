import { useQuery } from '@apollo/client';
import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { svgs } from 'src/assets';
import { claimPVPRacePrize } from 'src/pages/race/outlet/versus/race.logic';
import { RootState } from 'src/redux/store';
import { GET_USER_RACE_HISTORY } from 'src/utils/constants/query';
import { formatStringToDecimal } from 'src/utils/helper';

import EmptyTable from 'src/components/hifi/emptyTable';
import LoadingTable from 'src/components/hifi/loadingTable';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';
import Pagination from 'src/components/lofi/pagination';
import {
  PaginationInitialState,
  PaginationStateType,
} from 'src/components/lofi/pagination/pagination.type';
import Text from 'src/components/lofi/text';

import styles from './pvpSection.module.scss';

const PVPHistory = () => {
  const dispatch = useDispatch();
  const { account } = useSelector((state: RootState) => state);

  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);
  const [pagination, setPagination] = useState<PaginationStateType>(
    PaginationInitialState
  );
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(10);

  const {
    loading: totalUserRaceHistoryLoading,
    data: totalUserRaceHistory,
    refetch: refetchTotalUserRaceHistory,
  } = useQuery(GET_USER_RACE_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      userAddress: account.account,
    },
  });

  const {
    loading,
    data: userRaceHistory,
    refetch: refetchUserRaceHistory,
    fetchMore,
  } = useQuery(GET_USER_RACE_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      userAddress: account.account,
      offset: paginationOffset,
      limit: paginationLimit,
    },
  });

  const rankNumber = (position: number) => {
    switch (position) {
      case 1:
        return `${position}st`;
      case 2:
        return `${position}nd`;
      case 3:
        return `${position}rd`;
      default:
        return `${position}th`;
    }
  };

  const handleLoadPaginationData = () => {
    if (
      typeof totalUserRaceHistory !== 'undefined' &&
      typeof userRaceHistory !== 'undefined'
    ) {
      const totalPages = Math.floor(
        totalUserRaceHistory.userRaces.length / paginationLimit
      );
      const currentPage =
        (paginationOffset + paginationLimit) / paginationLimit;

      const paginationState = {
        hasNextPage: totalPages > currentPage,
        hasPrevPage: currentPage < 0,
        page: currentPage,
        totalPages: totalPages > 0 ? totalPages : 1,
        pageLimit: paginationLimit,
        pageOffset: paginationOffset,
      };

      setPagination(paginationState);
    }
  };

  const handlePrizeClaim = async (userHistory: any) => {
    if (!userHistory.isPrizeClaimed) {
      await claimPVPRacePrize(
        dispatch,
        userHistory,
        waitingModalContent,
        setWaitingModalContent
      );
    }

    refetchUserRaceHistory();
  };

  useEffect(() => {
    fetchMore({ variables: { offset: paginationOffset } });
  }, [paginationOffset]);

  useEffect(() => {
    handleLoadPaginationData();
  }, [loading, totalUserRaceHistoryLoading]);

  useEffect(() => {
    refetchTotalUserRaceHistory();
    refetchUserRaceHistory();
  }, [waitingModalContent]);

  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : userRaceHistory.length !== 0 ? (
        <>
          <Grid container className={styles.tableRH}>
            <Grid container className={styles.tableHeader}>
              <Grid item xs={2}>
                <Text type='h3'>Pool Number</Text>
              </Grid>
              <Grid item xs={1}>
                <Text type='h3'>Rank</Text>
              </Grid>
              <Grid item xs={4}>
                <Text type='h3' align={'center'}>
                  Bike
                </Text>
              </Grid>
              <Grid item xs={3}>
                <Text type='h3'>Reward</Text>
              </Grid>
              <Grid item xs={2}>
                <Text type='h3'>Actions</Text>
              </Grid>
            </Grid>
            {userRaceHistory.userRaces.map((history: any, index: number) => (
              <Grid
                key={`raceHistory${index}`}
                container
                className={`${
                  (index + 1) % 2 === 0 ? styles.even : styles.odd
                } ${styles.tableRow}`}
              >
                <Grid item xs={2} alignItems={'center'}>
                  <Text color={'white'}>{`#${history.race.id}`}</Text>
                </Grid>
                <Grid item xs={1} alignItems={'center'}>
                  <Text color={'white'}>
                    {history.isPrizeClaimed
                      ? rankNumber(parseInt(history.claimPrize.rank))
                      : '-'}
                  </Text>
                </Grid>
                <Grid item xs={4}>
                  <Text color={'white'} align={'center'}>
                    {`${history.nftName} #${history.nftId}`}
                  </Text>
                </Grid>
                <Grid item xs={3}>
                  <div className={styles.prizeContainer}>
                    <div className={styles.prizeItem}>
                      {history.isPrizeClaimed ? (
                        <>
                          <img src={svgs.melCoinIcon} />
                          <Text color={'white'}>{`${formatStringToDecimal(
                            history.claimPrize.prize
                          )} MEL`}</Text>
                        </>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} alignItems={'center'}>
                  {history.race.status.toLowerCase() === 'finished' && (
                    <Button
                      variant='contained'
                      size='small'
                      disabled={history.isPrizeClaimed}
                      onClick={() => handlePrizeClaim(history)}
                    >
                      Claim rewards
                    </Button>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Pagination
            pagination={pagination}
            setCurrentPage={setPaginationOffset}
            className={styles.pagination}
          />
        </>
      ) : (
        <EmptyTable
          title={`You haven't finished any race yet`}
          description={`The race history will appear here after you finish a race`}
        />
      )}
      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={() => setWaitingModalContent(defaultModalContent)}
      />
    </>
  );
};

export default PVPHistory;
