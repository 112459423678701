import { useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { svgs } from 'src/assets';
import {
  selectAccountAddress,
  selectAccountMelCoinBalance,
  setGarageLimit,
  setNftCount,
} from 'src/redux/accountMetamaskSlice';
import { getAllOwnedBikes } from 'src/services/api/nft';
import { GET_NFT_USER } from 'src/utils/constants/query';

import BikeList from 'src/components/hifi/bikeList';
import PageHeader from 'src/components/hifi/pageHeader';
import GradientButton from 'src/components/lofi/gradientButton';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';
import Text from 'src/components/lofi/text';

import {
  defaultGarageProps,
  getGarageProps,
  IGarageProps,
  upgradeGarageLimit,
} from './garage.logic';
import styles from './garage.module.scss';

function Garage() {
  const currentAccount = useSelector(selectAccountAddress);
  const [getNft] = useLazyQuery(GET_NFT_USER, {
    fetchPolicy: 'network-only',
    variables: {
      owner: currentAccount,
    },
  });
  const dispatch = useDispatch();
  const currentMelCoin: string = useSelector(selectAccountMelCoinBalance);

  const [garageProps, setGarageProps] =
    useState<IGarageProps>(defaultGarageProps);
  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);

  const hasEnoughBBToUpgrade =
    Number(currentMelCoin) >= Number(garageProps.upgradePrice);

  const handleGetGarageProps = async () => {
    const garageAttr = await getGarageProps(currentAccount);

    setGarageProps(garageAttr);
    dispatch(setGarageLimit(garageAttr.garageLimit));
    dispatch(setNftCount(garageAttr.nftCount));
  };

  const handleUpgradeLimit = async () => {
    if (hasEnoughBBToUpgrade) {
      await upgradeGarageLimit(
        dispatch,
        waitingModalContent,
        setWaitingModalContent
      );
    }
  };

  useEffect(() => {
    getAllOwnedBikes(getNft, dispatch);
    handleGetGarageProps();
  }, [waitingModalContent]);

  return (
    <>
      <Grid container className={styles.container}>
        <Grid item xs={12}>
          <PageHeader pageTitle='Garage' />
        </Grid>
        <Grid item xs={12} className={styles.bikeBoxContainer}>
          <BikeList clickToShowDetail />
        </Grid>
        <Grid container item xs={12} className={styles.footer} mt={4}>
          <div className={styles.bikeLimit}>
            Garage space:{' '}
            {`${garageProps.nftCount} / ${garageProps.garageLimit}`}
          </div>
          <div className={styles.buttonWrapper}>
            <GradientButton
              title='Upgrade limit'
              onClick={() => handleUpgradeLimit()}
              rightContent={
                <div className={styles.btnRightContent}>
                  <img width={16} height={16} src={svgs.melCoinIcon} />{' '}
                  {garageProps.upgradePrice}
                </div>
              }
              textStyle={{ flex: 0.75 }}
              disabled={!hasEnoughBBToUpgrade}
              style={{
                maxWidth: '100%',
              }}
            />
            {!hasEnoughBBToUpgrade && (
              <Text
                className={styles.errorText}
                type={'small'}
                align={'center'}
              >
                You don't have enough MEL Coin. Please buy more MEL Coin.
              </Text>
            )}
          </div>
        </Grid>
      </Grid>

      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={() => setWaitingModalContent(defaultModalContent)}
      />
    </>
  );
}

export default Garage;
