type Narration = {
  narrationType?: string;
  description?: string;
};

type TrackData = {
  name?: string;
  imageUrl?: string;
  trackNumber?: number;
  narration?: Narration[];
};

const narrationType = ['join-race', 'start-race'];

const RaceTrackData = [
  {
    trackNumber: 0,
    name: 'Macabrepiqua Autodromo',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/macabrepiqua-autodromo.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian Local Novice eRace</b> in <b>Macabrepiqua Autodromo</b>. This race is organized by <b>FAME</b> and sponsored by XEV, one of the leading eBike manufacturers from the US. The track has several medium length stretches and numerous mid-speed corners.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders you will compete against. They look competitive. Are you ready?',
      },
    ],
  },
  {
    trackNumber: 1,
    name: 'Del Nutello Autodromo',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/del-nutello-autodromo.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to Neo Legian Local Junior eRace in Del Nutello Autodromo. This race is organized by FAME and sponsored by Synergica, one of the leading eBike manufacturers from Italy. The track has one very long stretch and numerous mid-speed corners.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders who will be your opponents. The crowd is hungry for some action. You ready?',
      },
    ],
  },
  {
    trackNumber: 2,
    name: 'Automodrom Grobmuk',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/automodrom-grobmuk.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to Neo Legian Local Amateur eRace in Automodrom Grobmuk. This race is organized by FAME and sponsored by <b>Nero</b>, one of the leading eBike manufacturers from the US. <b>The track has several short stretches and numerous low-speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders you will compete against. Better stay hydrated before the race. Shall we start?',
      },
    ],
  },
  {
    trackNumber: 3,
    name: 'Qrni Circuit',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/qrni-circuit.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian Local Associate eRace</b> in Qrni Circuit. This race is organized by <b>FAME</b> and sponsored by <b>Tinfast</b>, one of the leading eBike manufacturers from Vietnam. <b>The track has several medium stretches and a lot of low-speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders who will be your opponents. Let’s warm up our engine. Ready?',
      },
    ],
  },
  {
    trackNumber: 4,
    name: 'Zheng Circuit',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/zheng-circuit.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian Local Semi-pro eRace</b> in <b>Zheng Circuit</b>. This race is organized by <b>FAME</b> and sponsored by <b>Macito</b>, one of the leading eBike manufacturers from Italy. <b>The track has a couple of very long stretches and numerous mid-speed corners and several low speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders you will compete against. Eyes on the prize. You can do it. Ready?',
      },
    ],
  },
  {
    trackNumber: 5,
    name: 'Circuit de la Salle',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/circuit-de-la-salle.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian Local Pro eRace</b> in <b>Circuit de la Salle</b>. This race is organized by <b>FAME</b> and sponsored by <b>Matter</b>, one of the leading eBike manufacturers from India. <b>The track has a lot of mid-length stretches and numerous low speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders you will compete against. They look competitive. Are you ready?',
      },
    ],
  },
  {
    trackNumber: 6,
    name: 'Circuit Brumarten',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/circuit-brumarten.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian Provincial eRace</b> in <b>Circuit Brumarten</b>. This race is organized by <b>FAME</b> and sponsored by <b>Gotaro</b>, one of the leading eBike manufacturers from Taiwan. <b>The track has a lot of mid-length stretches and numerous mid-speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders who will be your opponents. The crowd is hungry for some action. You ready?',
      },
    ],
  },
  {
    trackNumber: 7,
    name: 'Circuit des Locales',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/circuit-des-locales.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian National eRace</b> in <b>Circuit des Locales</b>. This race is organized by <b>FAME</b> and sponsored by <b>MYU</b>, one of the leading eBike manufacturers from China. <b>The track has several long stretches and several low-speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders you will compete against. Better stay hydrated before the race. Shall we start?',
      },
    ],
  },
  {
    trackNumber: 8,
    name: 'Circuit of the Umasaris',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/circuit-of-the-umasaris.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian Super eBike Continental eRace</b> in <b>Circuit of the Umasaris</b>. This race is organized by <b>FAME</b> and sponsored by <b>Shonda</b>, one of the leading eBike manufacturers from Japan. <b>The track has a long stretch, a mid-length stretch and several low-speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders who will be your opponents. Let’s warm up our engine. Ready?',
      },
    ],
  },
  {
    trackNumber: 9,
    name: 'Circuit Picardo Darmo',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/circuit-picardo-darmo.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian Super eBike Global eRace</b> in <b>Circuit Picardo Darmo</b>. This race is organized by <b>FAME</b> and sponsored by <b>UWU</b>, one of the leading eBike manufacturers from Germany. <b>The track has a long stretch, several mid-length stretches and a lot of low-speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders you will compete against. Eyes on the prize. You can do it. Ready?',
      },
    ],
  },
  {
    trackNumber: 10,
    name: 'Circuit Goldar',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/circuit-goldar.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian Hyper eBike GP Rookies eRace</b> in <b>Circuit Goldar</b>. This race is organized by <b>FAME</b> and sponsored by <b>Playmi LLC</b>, one of the leading autonomous driving technology development companies from the US. <b>The track has several mid-length stretches and several mid-speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders you will compete against. They look competitive. Are you ready?',
      },
    ],
  },
  {
    trackNumber: 11,
    name: 'Circuito del Ladrama',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/circuito-del-ladrama.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian Hyper eBike GP Talent eRace</b> in <b>Circuito del Ladrama</b>. This race is organized by <b>FAME</b> and sponsored by <b>Chargespot</b>, one of the leading electric vehicle infrastructure companies from the US. <b>The track has several long stretches, a few mid-length stretches, and several mid-speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders who will be your opponents. The crowd is hungry for some action. You ready?',
      },
    ],
  },
  {
    trackNumber: 12,
    name: 'Claymona Speedway',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/claymona-speedway.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>Neo Legian Hyper eBike GP Supersport eRace</b> in <b>Claymona Speedway</b>. This race is organized by <b>FAME</b> and sponsored by <b>WeaveMatrix</b>, one of the leading electric vehicle infrastructure companies from the US. <b>The track has several long stretches and a few mid-speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders who will be your opponents. Let’s warm up our engine. Ready?',
      },
    ],
  },
  {
    trackNumber: 13,
    name: 'Cobbington Park',
    imageUrl:
      'https://melx-assets.s3.ap-southeast-1.amazonaws.com/race-tracks/cobbington-park.png',
    narration: [
      {
        narrationType: narrationType[0],
        description:
          'Welcome to <b>eRace Grand Prix De Neo Legian</b> in <b>Cobbington Park</b>. This race is organized by <b>FAME</b> and sponsored by <b>Juliet Energy</b>, one of the leading electric vehicle infrastructure companies from the US. <b>The track has several mid-length stretches and several mid-speed corners</b>.',
      },
      {
        narrationType: narrationType[1],
        description:
          'Here are the riders who will be your opponents. Let’s warm up our engine. Ready?',
      },
    ],
  },
];

export { RaceTrackData };
export type { TrackData };
