import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BikeFilter from 'src/pages/race/components/bikeFilter';
import { OwnedBikeProps } from 'src/pages/workshop/workshop.type';
import { setActiveBike } from 'src/redux/raceSlice';
import { RootState } from 'src/redux/store';

import BikeCard from 'src/components/lofi/card/bikeCard';

import styles from './bikeList.module.scss';
import { BikeDetailData } from '../bikeDetailModal/bikeDetailModal.type';
import EmptyTable from '../emptyTable';

interface BikeListProps {
  clickToShowDetail?: boolean;
}

const BikeList = (props: BikeListProps) => {
  const { clickToShowDetail } = props;
  const dispatch = useDispatch();

  const [filter, setFilter] = useState<string>('all');
  const [bikeList, setBikeList] = useState<OwnedBikeProps[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedBike, seSelectedBike] = useState<BikeDetailData>();

  const { bikeCollection, activeBike } = useSelector(
    (state: RootState) => state.race
  );

  useEffect(() => {
    dispatch(setActiveBike(undefined));
    if (filter === 'all') {
      setBikeList(bikeCollection);
    } else {
      setBikeList(
        bikeCollection.filter(
          (bike: OwnedBikeProps) => bike.rarity.toLowerCase() === filter
        )
      );
    }
  }, [filter]);

  const handleClickBikeCard = (bike: any) => {
    if (clickToShowDetail) {
      setShowDialog(true);
      seSelectedBike({
        name: bike.name,
        type: bike.type,
        level: bike.level,
        rarity: bike.rarity,
        A: bike.A,
        S: bike.S,
        D: bike.D,
        imageUrl: bike.imageUrl,
        id: bike.id,
        tire: bike.parts,
      });
    } else {
      dispatch(
        setActiveBike({
          tokenId: bike.id,
          imageUrl: bike.imageUrl,
          status: {
            acceleration: bike.A,
            speed: bike.S,
            drift: bike.D,
          },
        })
      );
    }
  };

  return (
    <>
      <Grid item xs={12} mb={5}>
        <BikeFilter {...{ filter, setFilter }} />
      </Grid>
      {bikeList.length !== 0 ? (
        <Grid item container spacing={2} className={styles.bikeContainer}>
          {bikeList.map((bike) => (
            <Grid item xs={3} key={`bike-${bike.id}`}>
              <BikeCard
                data={bike}
                onClick={() => handleClickBikeCard(bike)}
                isActive={activeBike?.tokenId === bike.id}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyTable
          title={`No bike found`}
          description={`You don't have any bike or your bike still on the way`}
        />
      )}

      {/* <BikeDetailModal
        open={showDialog}
        data={selectedBike}
        closeHandle={() => {
          setShowDialog(false);
        }}
      /> */}
    </>
  );
};

export default BikeList;
