import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import Workshop from 'src/pages/workshop';
import {
  selectAccountMessage,
  selectAccountPubKey,
  selectAccountSignature,
  selectHasNft,
  selectWebsocketUrl,
  setGarageLimit,
  setHasNft,
  setLoginFalse,
  setNftCount,
} from 'src/redux/accountMetamaskSlice';
import {
  selectAccountLoginState,
  setLoginTrue,
} from 'src/redux/accountMetamaskSlice';
import { websocketHandle } from 'src/utils/handle';
import { devLog } from 'src/utils/helper';
import type { SignatureType } from 'src/utils/interface';

import MobileView from 'src/components/hifi/mobileWarningSection';
import NoInternetModal from 'src/components/hifi/noInternetModal';

import LockAccountModal from './components/hifi/lockAccountModal';
import { logOutAccount } from './components/hifi/metamaskButton/metamaskButton.logic';
import NoWalletModal from './components/hifi/noWalletModal';
import WrongNetworkSection from './components/hifi/wrongNetworkSection';
import Bank from './pages/bank';
import ChargingStation from './pages/chargingStation';
import ErrorPage from './pages/error';
import Garage from './pages/garage';
import Home from './pages/home';
import Homepage from './pages/homepage';
import Inventory from './pages/inventory';
import MotorbikeDealer from './pages/motorbikeDealer';
import Race from './pages/race';
import RaceType from './pages/race/outlet/raceType';
import Single from './pages/race/outlet/single';
import Result from './pages/race/outlet/single/result';
import TrackDetail from './pages/race/outlet/single/trackDetail';
import Versus from './pages/race/outlet/versus';
import ChooseBike from './pages/race/outlet/versus/sections/ChooseBikeSection';
import JoinRace from './pages/race/outlet/versus/sections/JoinRaceSection';
import RaceResult from './pages/race/outlet/versus/sections/ResultSection';
import ReferralDashBoard from './pages/referralDashboard';
import Tutorial from './pages/tutorial';
import WorldMap from './pages/worldMap';
import type { AppDispatch, RootState } from './redux/store';
import { getAccount } from './services/contract/metamask/account';
import {
  initiateContract,
  useProvider,
} from './services/contract/metamask/provider';
import { getNftTotalCount } from './services/contract/nft/nft';
import { nft } from './utils/constants/addresses';

declare global {
  interface Window {
    ethereum: any;
  }
}

ReactGA.initialize('UA-238621247-1');

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { account } = useSelector((state: RootState) => state);

  const headers: SignatureType = {
    pubkey: useSelector(selectAccountPubKey),
    message: useSelector(selectAccountMessage),
    signature: useSelector(selectAccountSignature),
  };
  const [showMobileWarning, setShowMobileWarning] = useState<boolean>(false);
  const [showWrongNetworkModal, setShowWrongNetworkModal] =
    useState<boolean>(false);
  const [showDisconnectedModal, setShowDisconnectedModal] =
    useState<boolean>(false);
  const [showLockAccountModal, setLockAccountModal] = useState<boolean>(false);

  const defaultSocketUrl: string = process.env.REACT_APP_WEBSOCKET_URL ?? '';
  const websocketUrl = useSelector(selectWebsocketUrl);
  const isLogin = useSelector(selectAccountLoginState);
  const hasNft = useSelector(selectHasNft);
  const provider = useProvider();

  const setAccountHandle = async () => {
    const account = await getAccount();
    await dispatch(setLoginTrue(account));
  };
  const setNftHandle = async () => {
    const nftContract = initiateContract(nft.address, nft.abi);
    const account = await getAccount();
    const nftCount = await getNftTotalCount(nftContract, account);
    const garageLimit = await nftContract.getUserGarageLimit(account);

    dispatch(setGarageLimit(garageLimit));
    dispatch(setNftCount(nftCount));
    dispatch(setHasNft(nftCount > 0));
  };
  const getNetworkHandle = async () => {
    const network = await window.ethereum.request({ method: 'eth_chainId' });
    if (network != process.env.REACT_APP_METAMASK_CHAINID) {
      setShowWrongNetworkModal(true);
    }
  };

  function getLastPartUrl(): string {
    const fullUrl: string = window.location.href;
    const splittedUrl: Array<string> = fullUrl.split('/');
    const lastPartUrl: string = splittedUrl[splittedUrl.length - 1];
    return lastPartUrl;
  }
  const checkLockAccountHandle = async () => {
    const isUnlocked: boolean = await window.ethereum._metamask.isUnlocked();
    if (!isUnlocked) {
      setLockAccountModal(true);
    }
    return isUnlocked;
  };
  const logoutHandle = () => {
    logOutAccount(dispatch);
    dispatch(setLoginFalse());
    navigate('/');
  };
  const { lastJsonMessage } = useWebSocket(websocketUrl, {
    onOpen: () => devLog(`opened, ${websocketUrl}`),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });
  useEffect(() => {
    websocketHandle(lastJsonMessage, dispatch, headers);
  }, [lastJsonMessage]);

  // *  MOBILE CHECKER

  //#region  //*=========== detect viewPort ===========
  useEffect(() => {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) || // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      setShowMobileWarning(true);
      //return <MobileView />;
    }
    if (window.matchMedia('only screen and (max-width: 1024px)').matches) {
      setShowMobileWarning(true);
    }

    //* METAMASK INSTALL CHECKER
  }, []);
  //#endregion  //*======== detect viewPort ===========S

  useEffect(() => {
    window.addEventListener('offline', () => {
      setShowDisconnectedModal(true);
    });
    window.addEventListener('online', () => {
      setShowDisconnectedModal(false);
    });
  }, []);

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      getNetworkHandle();
      window.ethereum.on('chainChanged', async (chainId: string) => {
        chainId !== process.env.REACT_APP_METAMASK_CHAINID
          ? setShowWrongNetworkModal(true)
          : setShowWrongNetworkModal(false);
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      checkLockAccountHandle();
      window.ethereum.on('accountsChanged', async function () {
        const isUnlocked: boolean = await checkLockAccountHandle();
        if (isUnlocked) {
          try {
            logoutHandle();
            setAccountHandle();
            setNftHandle();
            setLockAccountModal(false);
          } catch (err) {
            devLog(err);
            return;
          }
        } else {
          devLog('LOCKED ACCOUNT');
        }
      });
    }
  }, []);

  useEffect(() => {
    if (account && account.account !== '') {
      const nftContract = initiateContract(nft.address, nft.abi);
      const afterGachaFilter = nftContract.filters.Transfer(
        null,
        account.account,
        null
      );

      nftContract.on(
        afterGachaFilter,
        async (from: string, to: string, tokenId: number) => {
          if (account.account == to.toLowerCase()) {
            setNftHandle();
          }
        }
      );

      return () => {
        nftContract.off(nftContract, navigate);
      };
    }
  });

  useEffect(() => {
    setTimeout(() => {
      if (hasNft === true) {
        if (getLastPartUrl() == '') {
          navigate('/world-map');
        }
      } else {
        navigate('/');
      }
    }, 2000);
  }, [hasNft]);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>MelX Bike Battle</title>
        <meta property='og:site_name' content='MelX Bike Battle' />
        <meta property='og:title' content='MelX Bike Battle' />
        <meta
          property='og:description'
          content='Click to earn crypto racing game'
        />
      </Helmet>
      {showMobileWarning ? (
        <MobileView />
      ) : showDisconnectedModal ? (
        <NoInternetModal />
      ) : showWrongNetworkModal ? (
        <WrongNetworkSection />
      ) : showLockAccountModal ? (
        <LockAccountModal />
      ) : typeof provider === 'undefined' ? (
        <NoWalletModal />
      ) : (
        <Routes>
          <Route index element={<Home />} />
          <Route path='/home' element={<Homepage />} />
          <Route path='/inventory' element={<Inventory />} />
          <Route path='/referraldashboard' element={<ReferralDashBoard />} />
          <Route path='/how-to-play' element={<Tutorial />} />
          {isLogin && hasNft ? (
            <>
              <Route path='/world-map' element={<WorldMap />} />
              <Route path='/workshop' element={<Workshop />} />
              <Route path='/bank' element={<Bank />} />
              <Route path='/charging-station' element={<ChargingStation />} />
              <Route path='/garage' element={<Garage />} />
              <Route path='/bike-dealer' element={<MotorbikeDealer />} />
              <Route path='/race' element={<Race />}>
                <Route index element={<RaceType />} />
                <Route path='single' element={<Single />}>
                  <Route
                    index
                    element={<ChooseBike routeTo='/race/single/join' />}
                  />
                  <Route path='join' element={<TrackDetail />} />
                  <Route path='result' element={<Result />} />
                </Route>
                <Route path='versus' element={<Versus />}>
                  <Route
                    index
                    element={<ChooseBike routeTo='/race/versus/join' />}
                  />
                  <Route path='join' element={<JoinRace />} />
                  <Route path='result' element={<RaceResult />} />
                </Route>
              </Route>
            </>
          ) : null}
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      )}
    </>
  );
}

export default App;
