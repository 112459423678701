import { Box, Stack, Typography } from '@mui/material';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccountAddress,
  selectAccountEnoughMel,
  selectAccountLoginState,
  selectAccountWhitelistStatus,
  setEnoughMelFalse,
  setEnoughMelTrue,
  setWhitelistStatus,
} from 'src/redux/accountMetamaskSlice';
import {
  isMelBalanceEnoughToMint,
  minimalMelToMint,
} from 'src/services/contract/erc20/mel';
import { getWhitelistStatus } from 'src/services/contract/metamask/account';
const MintingRequirements = () => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
  const [minMelToMint, setMinMelToMint] = useState<string>('');
  const dispatch = useDispatch();

  const whitelistStatusRedux = useSelector(selectAccountWhitelistStatus);
  const isMelEnough = useSelector(selectAccountEnoughMel);
  const isLogin = useSelector(selectAccountLoginState);
  const stateAccount = useSelector(selectAccountAddress);

  const whitelistHandle = async (stateAccount: string) => {
    const whitelistStatus = await getWhitelistStatus(stateAccount);
    dispatch(setWhitelistStatus(whitelistStatus));
  };

  const enoughMelHandle = async (stateAccount: string) => {
    if (isLogin && stateAccount !== null) {
      const isEnoughMel = await isMelBalanceEnoughToMint(stateAccount);
      if (isEnoughMel) {
        dispatch(setEnoughMelTrue());
      } else {
        dispatch(setEnoughMelFalse());
      }
    }
  };

  useEffect(() => {
    if (whitelistStatusRedux.isUserCanMint && isMelEnough) {
      setIsDisplayed(false);
    } else {
      setIsDisplayed(true);
    }
  }, [whitelistStatusRedux, isMelEnough]);

  useEffect(() => {
    whitelistHandle(stateAccount);
    enoughMelHandle(stateAccount);
  }, [stateAccount]);

  useEffect(() => {
    const getMinMelToMint = async () => {
      const minMelMint = await minimalMelToMint();

      setMinMelToMint(
        Number(ethers.utils.formatEther(minMelMint)).toLocaleString()
      );
    };
    getMinMelToMint();
  }, [minMelToMint]);

  return (
    <Box
      sx={{
        width: 'fit-content',
        height: '100%',
        backgroundColor: 'black',
        borderRadius: '12px',
        padding: '1.5rem 2rem',
        color: 'white',
        mixBlendMode: 'normal',
        border: (theme) => `2px solid ${theme.palette.fuchsia.main}`,
        boxSizing: 'border-box',
        display: `${isLogin && isDisplayed ? 'flex' : 'none'}`,
      }}
    >
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ gap: '0.5rem' }}
      >
        <Typography variant='h5'>
          You must meet all of these requirements
        </Typography>
        <Box>
          {whitelistStatusRedux.isContractWhitelistOn && (
            <Typography>
              {whitelistStatusRedux.isUserCanMint ? '✅' : '❌'} Your address
              must be on pre-sale whitelist
            </Typography>
          )}
          <Typography>
            {isMelEnough ? '✅' : '❌'} You need to hold at least {minMelToMint}{' '}
            MEL tokens
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default MintingRequirements;
