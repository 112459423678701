import axios from 'axios';
import type { RaceTrackProps } from 'src/pages/race/outlet/single/tracks/tracks.type';
import { setGameCoinBalance } from 'src/redux/accountMetamaskSlice';
import type { AppDispatch } from 'src/redux/store';
import { gameAPI } from 'src/utils/constants/url';
import { decimalLimit } from 'src/utils/helper';

async function getGameCoinBalance(
  dispatch: AppDispatch,
  headers: any
): Promise<string> {
  const response = await axios({
    method: 'GET',
    url: `${gameAPI}/v1/players`,
    headers,
  });

  const currentGameCoin = response.data.data.coin;
  const formattedGameCoin = decimalLimit(currentGameCoin, 2).toString();
  dispatch(setGameCoinBalance(formattedGameCoin));
  return formattedGameCoin;
}

async function getPlayerRaceTracks(headers: any): Promise<RaceTrackProps[]> {
  const response = await axios({
    method: 'GET',
    url: `${gameAPI}/v1/players/tracks`,
    headers,
  });
  const playerRaceTracks = response.data.data;
  return playerRaceTracks;
}

async function isNftExist(headers: any): Promise<boolean> {
  const response = await axios({
    method: 'GET',
    url: `${gameAPI}/v1/nfts`,
    params: {
      page: 1,
      perPage: 1,
    },
    headers,
  });
  return response.data.data.docs.length !== 0;
}

export { getGameCoinBalance, getPlayerRaceTracks, isNftExist };
