import { Badge, Box, Card, CardMedia, Stack, Typography } from '@mui/material';
import React from 'react';
import RarityIcon from 'src/assets/svg/RarityIcon';
import { isNew } from 'src/utils/helper';
import './rarity.scss';

import styles from './inventoryCard.module.scss';

type Props = {
  id: string;
  img: string;
  rarity: string;
  dateCreated: number;
  description: string;
};

const InventoryCard = (props: Props) => {
  const { dateCreated } = props;
  //currently new badge is shown when mintedTime vs current time is not more than 3 hours
  const itemStatus = isNew(dateCreated, 3);

  const classes = `${styles.badge} ${itemStatus ? '' : styles.invisible}`;
  return (
    <Badge
      className={classes}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      badgeContent={itemStatus ? 'New' : 0}
    >
      <Card
        sx={{
          height: '416px',
          width: '319px',
          borderRadius: '30px',
          padding: '25px',
        }}
      >
        <Stack direction='column' spacing={4}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='subtitle1'>#{props.id}</Typography>
            <Stack
              className={props.rarity.toUpperCase()}
              direction='row'
              alignItems='center'
              spacing={1}
            >
              <RarityIcon sx={{ color: 'inherit' }} />
              <Typography variant='subtitle1' sx={{ color: 'inherit' }}>
                {props.rarity}
              </Typography>
            </Stack>
          </Stack>
          <Box
            component='div'
            sx={{
              height: '220px',
            }}
          >
            <CardMedia
              component='img'
              image={props.img}
              alt='motorcycle'
              sx={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                marginLeft: 'auto !important',
                marginRight: 'auto !important',
              }}
            />
          </Box>
          <Typography
            variant='h6'
            component='h6'
            sx={{
              textAlign: 'center',
              color: 'white',
              marginTop: '50px',
            }}
          >
            {props.description}
          </Typography>
        </Stack>
      </Card>
    </Badge>
  );
};

export default InventoryCard;
