import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccountAddress } from 'src/redux/accountMetamaskSlice';
import { GET_REF_MEL } from 'src/utils/constants/query';

import SummaryItemGrid from 'src/components/lofi/grid/referralSummaryGrid';
import Navbar from 'src/components/lofi/navbar';
import ReferralDashboardTableItem from 'src/components/lofi/table/referralDashboardTable';

import { fetchingReferral } from './referralDashboard.logic';
import styles from './referralDashboard.module.scss';
import type {
  ReferralDashboardData,
  ReferralProps,
} from './referralDashboard.type';

function ReferralDashBoard() {
  const [totalReferralMel, setTotalReferralMel] = useState<string>('0');
  const [totalUserUsed, setTotalUserUsed] = useState<number>(0);
  const [totalReferralUsed, setTotalReferralUsed] = useState<number>(0);
  const [displayData, setDisplayData] = useState<ReferralDashboardData[]>([]);
  const [_preRender, setPreRender] = useState<boolean>(false);

  const [getReferral] = useLazyQuery(GET_REF_MEL, {
    fetchPolicy: 'network-only',
  });
  const currentAccount: string = useSelector(selectAccountAddress);

  useEffect(() => {
    fetchingReferral(
      currentAccount,
      setTotalReferralMel,
      getReferral,
      setDisplayData,
      setTotalReferralUsed,
      setTotalUserUsed,
      setPreRender
    );
  }, [currentAccount]);

  const TotalReferralMelProps: ReferralProps = {
    firstRowText: 'TOTAL EARNED',
    secondRowText: totalReferralMel + ' VOUCHERS',
    rowTwoAlign: 'right',
  };

  const TotalUserUsedProps: ReferralProps = {
    firstRowText: 'TOTAL USERS USED',
    secondRowText: totalUserUsed + ' USERS',
    rowTwoAlign: 'left',
  };

  const TotalReferralUsedProps: ReferralProps = {
    firstRowText: 'TOTAL USERS USED',
    secondRowText: totalReferralUsed + 'x',
    rowTwoAlign: 'left',
  };

  return (
    <>
      <section className={styles.refdashboard}>
        <Navbar />
        <section className={styles.content}>
          <SummaryItemGrid
            TotalReferralMelProps={TotalReferralMelProps}
            TotalUserUsedProps={TotalUserUsedProps}
            TotalReferralUsedProps={TotalReferralUsedProps}
          />
          <ReferralDashboardTableItem displayData={displayData} />
        </section>
      </section>
    </>
  );
}

export default ReferralDashBoard;
