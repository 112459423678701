import Text from 'src/components/lofi/text';
import { EmptyTableProps } from './emptyTable.type';
import styles from './emptyTable.module.scss';
import { svgs } from 'src/assets';

function EmptyTable(props: EmptyTableProps) {
  return (
    <div className={styles.emptyTable}>
      <img src={svgs.emptyDocs} />
      <div>
        <Text className={styles.title}>{props.title}</Text>
      </div>
      <div>
        <Text className={styles.desc}>{props.description}</Text>
      </div>
    </div>
  );
}

export default EmptyTable;
