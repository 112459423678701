import { CircularProgress, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectAccountMessage,
  selectAccountPubKey,
  selectAccountSignature,
} from 'src/redux/accountMetamaskSlice';
import {
  selectActiveBike,
  selectActiveRaceDetail,
  selectCurrentTrack,
  setActiveBike,
} from 'src/redux/raceSlice';
import { startRace } from 'src/services/api/race';
import type { SignatureType } from 'src/utils/interface';

import GradientButton from 'src/components/lofi/gradientButton';
import GradientModal from 'src/components/lofi/modal/gradientModal';
import Text from 'src/components/lofi/text';

import styles from './activeRaceModal.module.scss';
import type { ActiveRaceModalProps } from './activeRaceModal.type';
import { OpponentProps } from '../../outlet/single/trackDetail/trackDetail.type';

function ActiveRaceModal(props: ActiveRaceModalProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const headers: SignatureType = {
    pubkey: useSelector(selectAccountPubKey),
    message: useSelector(selectAccountMessage),
    signature: useSelector(selectAccountSignature),
  };
  const activeBike = useSelector(selectActiveBike);
  const activeRaceDetails = useSelector(selectActiveRaceDetail);
  const currentTrack = useSelector(selectCurrentTrack);

  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const handleStartRace = async () => {
    setError(null);
    setIsInProgress(true);
    const req = await startRace(headers, dispatch);
    if (req?.response?.status >= 400) {
      setError(req.response.data.message);
    } else {
      dispatch(setActiveBike(undefined));
      props.setShowModal(false);
      navigate('/race/result', { state: { verified: true } });
    }
    setIsInProgress(false);
  };

  return (
    <GradientModal
      open={props?.showModal}
      style={{
        maxWidth: '80%',
        padding: '20px 20px',
      }}
    >
      <div className={styles.activeRaceModal}>
        <Text type={'h1'} align={'center'} className={styles.modalTitle}>
          Your Opponents
        </Text>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <div className={`${styles.bikeOpp} ${styles.mine}`}>
              <div className={styles.bikeOppWrapper}>
                <img src={activeBike?.imageUrl} />
                <Text type={'h3'} className={styles.oppName} align={'center'}>
                  You
                </Text>
                <Text
                  type={'small'}
                  className={styles.oppStats}
                  align={'center'}
                >{`A: ${activeBike?.status.acceleration} S: ${activeBike?.status.speed} D: ${activeBike?.status.drift}`}</Text>
              </div>
            </div>
          </Grid>
          {activeRaceDetails?.opponents.map((opp: OpponentProps) => {
            return (
              <Grid item xs={2} key={`opp-${opp.id}`}>
                <div className={styles.bikeOpp}>
                  <div className={styles.bikeOppWrapper}>
                    <img src={opp?.image} />
                    <Text
                      type={'h3'}
                      className={styles.oppName}
                      align={'center'}
                    >
                      {opp?.name}
                    </Text>
                    <Text
                      type={'small'}
                      className={styles.oppStats}
                      align={'center'}
                    >{`A: ${opp.status.acceleration} S: ${opp.status.speed} D: ${opp.status.drift}`}</Text>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Text className={styles.desc} align={'center'}>
          {
            currentTrack?.narration.find(
              (n: any) => n.narrationType === 'start-race'
            )?.description
          }
        </Text>
        <GradientButton
          title={isInProgress ? 'Race in progress...' : 'Start Race'}
          onClick={() => handleStartRace()}
          style={{ marginTop: 30 }}
          disabled={isInProgress}
          leftContent={
            isInProgress ? (
              <CircularProgress
                color={'inherit'}
                size={18}
                style={{ marginRight: 10 }}
              />
            ) : (
              <></>
            )
          }
        />
        {error && (
          <Text className={styles.errorText} type={'small'} align={'center'}>
            {error}
          </Text>
        )}
      </div>
    </GradientModal>
  );
}

export default ActiveRaceModal;
