import { GlowBoxProps } from './glowBox.type';
import styles from './glowBox.module.scss';
import { images } from 'src/assets';

function GlowBox(props: GlowBoxProps) {
  const {
    children,
    style,
    disabled,
    onClick,
    isActive,
    disabledGlowEffect,
    topGlowPosition,
    bottomGlowPosition,
  } = props;
  return (
    <div
      className={`${styles.glowBox} ${isActive ? styles.active : ''}`}
      style={{ pointerEvents: disabled ? 'none' : 'unset', ...style }}
      {...(onClick && { onClick: () => onClick() })}
    >
      {!disabledGlowEffect && (
        <img
          src={images.glow}
          className={styles.topGlow}
          style={{ top: topGlowPosition }}
        />
      )}
      <div className={styles.content}>{children}</div>
      {!disabledGlowEffect && (
        <img
          src={images.glow}
          className={styles.bottomGlow}
          style={{ bottom: bottomGlowPosition }}
        />
      )}
    </div>
  );
}

export default GlowBox;
