import { ModalMessage } from 'src/interfaces/modal-interface';

const waitingApproveTransaction: ModalMessage = {
  title: 'Waiting approve the transaction',
  message: 'Please open your wallet and confirm to login',
  showViewInventory: false,
  openStatus: true,
};

const motorbikeOnTheWay: ModalMessage = {
  title: 'Your motorbike is on the way',
  message: `You'll be automatically redirected to the world map. If not, you can press the button below to go to the world map`,
  showViewInventory: true,
  openStatus: true,
};

const waitingAuthorizedFund: ModalMessage = {
  title: 'Waiting for funds authorization.',
  message: 'Please open your wallet and confirm to login',
  showViewInventory: false,
  openStatus: true,
};

export { motorbikeOnTheWay, waitingApproveTransaction, waitingAuthorizedFund };
