import { Box, Button, Stack } from '@mui/material';
import * as React from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import loadingLottieBox from 'src/assets/lottie/loading-box-lottie.json';
import warningLottie from 'src/assets/lottie/metamask-fail-throw-alert-lottie.json';
import {
  selectAccountAddress,
  selectAccountLoginState,
} from 'src/redux/accountMetamaskSlice';
import { removeModalContent } from 'src/redux/modalContentSlice';
import { switchNetwork } from 'src/services/contract/metamask/account';
import { getReferralText } from 'src/utils/helper';

import ClipboardButton from 'src/components/lofi/button/clipboardButton';
import ShareToTwitter from 'src/components/lofi/button/shareToTwitter';

import GradientModal from '../gradientModal';
import GradientButton from '../../gradientButton';
import Text from '../../text';

export type ModalProps = {
  title: string;
  message: string;
  showViewInventory: boolean;
  buttonMessage: string;
  openStatus: boolean;
  event: React.MouseEventHandler<HTMLButtonElement>;
  closeModal?: () => void;
};

export const defaultModalContent = {
  openStatus: false,
  title: '',
  message: '',
  showViewInventory: false,
  buttonMessage: '',
  event: () => null,
  closeModal: () => null,
};

function checkAccount(): string {
  const checkParentStateAccount = useSelector(selectAccountLoginState);
  if (checkParentStateAccount === undefined) {
    return '';
  } else {
    const getAccount = useSelector(selectAccountAddress);
    return getAccount;
  }
}

const WaitingModal = (props: ModalProps) => {
  const warningMessage: string[] = [
    'Wrong network',
    'User rejected the approval',
    `You haven't installed Metamask yet`,
    'User rejected the approval',
    'No Internet Connection',
    'Another withdrawal pending',
    'Withdrawal Failed',
  ];

  const closeButtonMessage = [
    'Another withdrawal pending',
    'Withdrawal Failed',
    'Withdrawal Success',
    'Transaction Success',
    'Transaction failed',
    'User rejected the approval',
    'Network Error',
    'Something went wrong',
    'Hold up!',
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentAccount: string = checkAccount();

  const handleClose = () => {
    dispatch(removeModalContent());
  };

  const renderInventoryButtonText = () => {
    if (pathname === '/bike-dealer') {
      return 'View Garage';
    } else {
      return 'Go to World Map';
    }
  };

  const handleViewInventory = React.useCallback(() => {
    handleClose();
    if (pathname === '/bike-dealer') {
      navigate('/garage');
    } else {
      navigate('/world-map');
    }
  }, [navigate]);

  const LoadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loadingLottieBox,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const WarningLottie = {
    loop: true,
    autoplay: true,
    animationData: warningLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <GradientModal
      open={props.openStatus}
      style={{ padding: '30px 10px 30px', minHeight: 'unset' }}
    >
      <>
        <Box sx={{ marginBottom: '0.5rem' }}>
          <Lottie
            options={
              !warningMessage.some((v) => props.title === v)
                ? LoadingLottie
                : WarningLottie
            }
            width={180}
          ></Lottie>
        </Box>
        <Text type={'h2'} align={'center'}>
          {props.title}
        </Text>
        <Text align={'center'} style={{ marginBottom: 30 }}>
          {props.message}
        </Text>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          {props.showViewInventory && (
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                marginBottom: '30px',
              }}
            >
              <GradientButton
                onClick={() => handleViewInventory()}
                title={renderInventoryButtonText()}
                style={{
                  marginRight: 50,
                }}
              />
              <Box
                sx={{
                  marginRight: '30px',
                }}
              >
                <Text style={{ marginBottom: 5 }}>
                  Share it to get incentives
                </Text>
                <Stack direction='row' spacing='12px'>
                  <ShareToTwitter
                    twitterIntent={getReferralText(currentAccount)}
                  />
                  <ClipboardButton copyText={getReferralText(currentAccount)} />
                </Stack>
              </Box>
            </div>
          )}
          {props.title === 'Wrong network' && (
            <GradientButton
              title={'Switch Network'}
              onClick={() => switchNetwork(dispatch)}
              style={{ marginBottom: 15 }}
            />
          )}
          {props.title === 'No Internet Connection'}
          {props.title === `You haven't installed Metamask yet` && (
            <Button
              variant='primary'
              href='https://metamask.io'
              target='_blank'
              onClick={() => handleClose()}
            >
              Install Metamask
            </Button>
          )}
          {closeButtonMessage.includes(props.title) && (
            <GradientButton
              title={'Back to Previous Screen'}
              onClick={() => (props.closeModal ? props.closeModal() : null)}
              style={{ marginBottom: 15 }}
            />
          )}
        </Box>
      </>
    </GradientModal>
  );
};

export default WaitingModal;
