import { CircularProgress } from '@mui/material';
import Text from 'src/components/lofi/text';
import styles from './loadingTable.module.scss';
import type { LoadingTableProps } from './loadingTable.type';

export default function LoadingTable(props: LoadingTableProps) {
  return (
    <div className={`${styles.loadingContainer} ${props.className ?? ''}`}>
      <Text type='h3'>Loading</Text>
      <CircularProgress size={30} className={styles.progress} />
    </div>
  );
}
