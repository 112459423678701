import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import styles from './pagination.module.scss';
import { PaginationProps } from './pagination.type';
import Text from '../text';

function Pagination(props: PaginationProps) {
  const { pagination, setCurrentPage, className } = props;
  return (
    <div className={`${styles.melxPagination} ${className ? className : ''}`}>
      <div
        className={`${styles.button} ${
          !pagination.hasPrevPage && styles.disabled
        }`}
        onClick={() =>
          pagination.hasPrevPage &&
          setCurrentPage(pagination.pageOffset - pagination.pageLimit)
        }
      >
        <ArrowBackRoundedIcon />
      </div>
      <div className={styles.text}>
        <Text className={styles.font}>Page</Text>
      </div>
      <div className={`${styles.button} ${styles.currentPage}`}>
        <Text className={styles.font}>{`${pagination.page}`}</Text>
      </div>
      <div className={styles.text}>
        <Text className={styles.font}>{`of ${pagination.totalPages}`}</Text>
      </div>
      <div
        className={`${styles.button} ${
          !pagination.hasNextPage && styles.disabled
        }`}
        onClick={() =>
          pagination.hasNextPage &&
          setCurrentPage(pagination.pageOffset + pagination.pageLimit)
        }
      >
        <ArrowForwardRoundedIcon />
      </div>
    </div>
  );
}
export default Pagination;
