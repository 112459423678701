import type { AppDispatch } from 'src/redux/store';
import { getGameCoinBalance } from 'src/services/api/player';
import { getMelCoinBalance } from 'src/services/contract/erc20/mel';
import { getEnergyBalance } from 'src/services/contract/game/energy';
import type { SignatureType } from 'src/utils/interface';

import { devLog } from './helper';
const websocketMessageHandle = async (
  code: string,
  dispatch: AppDispatch,
  headers: SignatureType
) => {
  switch (code) {
    case '001':
      devLog('Withdraw Triggered WEBSOCKET');
      await getGameCoinBalance(dispatch, headers);
      await getMelCoinBalance(dispatch, headers.pubkey);
      break;
    case '002':
      devLog('Gacha Triggered WEBSOCKET');
      break;
    case '003':
      devLog('Energy Triggered WEBSOCKET');
      await getEnergyBalance(headers.pubkey, dispatch);
      await getMelCoinBalance(dispatch, headers.pubkey);
      break;
    case '004':
      devLog('Part Triggered WEBSOCKET');
      break;
  }
};

async function websocketHandle(
  lastJsonMessage: any,
  dispatch: AppDispatch,
  headers: SignatureType
) {
  if (lastJsonMessage !== null) {
    websocketMessageHandle(lastJsonMessage.code, dispatch, headers);
  }
}

export { websocketHandle };
