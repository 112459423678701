import { Box, Typography } from '@mui/material';
import Text from '../../text';
import styles from './referralSummaryText.module.scss';
import type { ReferralProps } from './referralSummaryText.type';

const ReferralDashboardSummaryText = (props: ReferralProps) => {
  return (
    <section className={styles.summaryBox}>
      <Text type='label' color='#F3F2F1' className={styles.label}>
        {props.firstRowText}
      </Text>
      <Text className={styles.value}>{props.secondRowText}</Text>
    </section>
  );
};

export default ReferralDashboardSummaryText;
