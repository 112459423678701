import { ContractInterface, ethers } from 'ethers';

function initiateContract(contractAdress: string, abi: ContractInterface) {
  const provider = useProvider();
  const signer = provider?.getSigner();
  const contract = new ethers.Contract(contractAdress, abi, signer);
  return contract;
}

function useProvider() {
  const provider = window.ethereum
    ? new ethers.providers.Web3Provider(window.ethereum)
    : undefined;
  return provider;
}

export { initiateContract, useProvider };
