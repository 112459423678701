import { Alchemy, Network } from 'alchemy-sdk';
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import type {
  DefaultPartProps,
  OwnedBikeProps,
} from 'src/pages/workshop/workshop.type';
import { setBikeCollection } from 'src/redux/raceSlice';
import { AppDispatch } from 'src/redux/store';
import { nft as nftContract } from 'src/utils/constants/addresses';
import { gameAPI } from 'src/utils/constants/url';
import { capitalize, toSnakeCase } from 'src/utils/string';

const alchemyConfig = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.MATIC_MUMBAI,
};
const alchemy = new Alchemy(alchemyConfig);

async function getEquippedPart(
  tokenId: string,
  setEquippedPart: Dispatch<SetStateAction<DefaultPartProps[]>>
) {
  const response = await axios({
    method: 'GET',
    url: `${gameAPI}/v1/nfts/${tokenId}`,
  });
  const equippedPart: DefaultPartProps[] = response.data.data.parts;
  setEquippedPart(equippedPart);
}

async function getAllOwnedBikes(
  getNft: any,
  dispatch?: AppDispatch,
  setOwnedBike?: Dispatch<SetStateAction<OwnedBikeProps[]>>
) {
  const graphData = await getNft();
  dispatch && dispatch(setBikeCollection(graphData.data.nfts));
  setOwnedBike && setOwnedBike(graphData.data.nfts);
}

export { getAllOwnedBikes, getEquippedPart };
