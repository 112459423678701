import { BigNumber, ethers } from 'ethers';
import { setMelBalance } from 'src/redux/accountMetamaskSlice';
import { setModalContent } from 'src/redux/modalContentSlice';
import type { AppDispatch } from 'src/redux/store';
import { getMelBalance } from 'src/services/contract/erc20/mel';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { gacha } from 'src/utils/constants/addresses';
import { waitingApproveTransaction } from 'src/utils/constants/loadingMessageConstant';
import { devLog } from 'src/utils/helper';
async function gachaApproval(
  dispatch: AppDispatch,
  account: string,
  melTotalSupply: BigNumber,
  melContract: ethers.Contract,
  nftAddress: string
) {
  dispatch(setModalContent(waitingApproveTransaction));
  const approval = await melContract.approve(nftAddress, melTotalSupply);

  const approveFilter = melContract.filters.Approval(account, null, null);

  melContract.once(
    approveFilter,
    (owner: string, spender: string, value: BigNumber, event: any) => {
      devLog(`OWNER: , ${owner}, , SPENDER: , ${spender}, , VALUE: , ${value}`);
      devLog('event');
      devLog(event);
    }
  );
  await approval.wait();
}

async function gachaTransactionWithoutReferral(
  dispatch: AppDispatch,
  account: string,
  nftContract: ethers.Contract,
  gachaContract: ethers.Contract
) {
  let token: any;
  const gacha = await gachaContract.gacha('Bali');
  dispatch(setModalContent(waitingApproveTransaction));
  const transactionFilter = gachaContract.filters.MintWithGacha(
    null,
    account,
    null,
    null
  );
  gachaContract.once(
    transactionFilter,
    async (
      rarityName: string,
      basePoint: BigNumber,
      to: string,
      city: { hash: string; _isIndexed: boolean },
      tokenId: BigNumber,
      event: any
    ) => {
      // Called ONLY when your account changes the value
      devLog(`
        RARITYNAME: ,
        ${rarityName},
        , BASEPOINT: ,
        ${basePoint},
        , TO: ,
        ${to},
        , CITY: ,
        ${city},
        , TOKENID: ,
        ${tokenId}
        `);
      devLog('event');
      devLog(event);
      token = tokenId;
      const refetchMelBalance = await getMelBalance(account);
      const formattedMelBalance = ethers.utils.formatUnits(
        refetchMelBalance,
        18
      );
      dispatch(setMelBalance(formattedMelBalance));
    }
  );
  await gacha.wait();
  const metadataFilter = nftContract.filters.SetTokenURI(token, null);
  nftContract.once(metadataFilter, (tokenId, tokenUri) => {
    // Called ONLY when your account changes the value
    devLog(`
    TOKENID: , ${tokenId}, , TOKENURI: , ${tokenUri}
    `);
  });
}

async function gachaTransactionWithReferral(
  dispatch: AppDispatch,
  account: string,
  nftContract: ethers.Contract,
  gachaContract: ethers.Contract,
  referralAddress: string
) {
  let token: any;
  const gacha = await gachaContract.gachaWithReferral('Bali', referralAddress);
  dispatch(setModalContent(waitingApproveTransaction));
  const transactionFilter = gachaContract.filters.MintWithGacha(
    null,
    account,
    null,
    null
  );
  gachaContract.once(
    transactionFilter,
    async (
      rarityName: string,
      to: string,
      city: { hash: string; _isIndexed: boolean },
      tokenId: BigNumber,
      event: any
    ) => {
      // Called ONLY when your account changes the value
      devLog(`
        RARITYNAME: ,
        ${rarityName}
        , TO: ,
        ${to},
        , CITY: ,
        ${city},
        , TOKENID: ,
        ${tokenId}
        `);
      devLog('event');
      devLog(event);
      token = tokenId;
      const refetchMelBalance = await getMelBalance(account);
      const formattedMelBalance = ethers.utils.formatUnits(
        refetchMelBalance,
        18
      );
      dispatch(setMelBalance(formattedMelBalance));
    }
  );
  await gacha.wait();
  const metadataFilter = nftContract.filters.SetTokenURI(token, null);
  nftContract.once(metadataFilter, (tokenId, tokenUri) => {
    // Called ONLY when your account changes the value
    devLog(`
    TOKENID: , ${tokenId}, , TOKENURI: , ${tokenUri}
    `);
  });
}

async function gachaCheckWhitelistStatus(): Promise<boolean> {
  const gachaContract = initiateContract(gacha.address, gacha.abi);
  const whitelistStatus = await gachaContract.whitelistStatus();
  return whitelistStatus;
}

async function gachaWithVoucher() {
  const gachaContract = initiateContract(gacha.address, gacha.abi);
  const gachaTx = await gachaContract.gachaWithVoucher('Bali');
  await gachaTx.wait();
}

export {
  gachaApproval,
  gachaCheckWhitelistStatus,
  gachaTransactionWithoutReferral,
  gachaTransactionWithReferral,
  gachaWithVoucher,
};
