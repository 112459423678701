import { Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DiscordIcon from 'src/assets/svg/DiscordIcon';
import MelxCryptoLogo from 'src/assets/svg/MelxCryptoLogo';
import {
  selectAccountAddress,
  selectAccountEnergyBalance,
  selectAccountGameCoin,
  selectAccountMelCoinBalance,
  setMelBalance,
} from 'src/redux/accountMetamaskSlice';
import { getMelBalance } from 'src/services/contract/erc20/mel';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { faucet } from 'src/utils/constants/addresses';
import { formatBigNumberToString, getTxError } from 'src/utils/helper';

import BackButton from 'src/components/lofi/button/backButton';
import FaucetButton from 'src/components/lofi/button/faucetButton';
import CurrencyBox from 'src/components/lofi/currencyBox';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';
import Text from 'src/components/lofi/text';

import styles from './pageHeader.module.scss';
import { PageHeaderProps } from './pageHeader.type';
import MetamaskButton from '../metamaskButton';

function PageHeader(props: PageHeaderProps) {
  const dispatch = useDispatch();
  const discordUrl: string = process.env.REACT_APP_DISCORD_URL ?? '';
  const navigate = useNavigate();
  const currentEnergy: string = useSelector(selectAccountEnergyBalance);
  const currentMelCoin: string = useSelector(selectAccountMelCoinBalance);
  const currentGameCoin: string = useSelector(selectAccountGameCoin);
  const stateAccount = useSelector(selectAccountAddress);

  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);

  const closeModalHandle = () => {
    setWaitingModalContent(defaultModalContent);
  };

  const faucetHandler = async () => {
    try {
      setWaitingModalContent({
        ...defaultModalContent,
        openStatus: true,
        title: 'Waiting approve the transaction',
        message: 'Please open your wallet and confirm the transaction',
        showViewInventory: false,
      });

      const faucetContract = initiateContract(faucet.address, faucet.abi);
      const tx = await faucetContract.provideMelx();

      setWaitingModalContent({
        ...defaultModalContent,
        openStatus: true,
        title: 'Waiting for transaction to complete',
        showViewInventory: false,
      });
      await tx.wait();
      const melBalance = await getMelBalance(stateAccount);
      const melBalanceString: string = formatBigNumberToString(melBalance);
      dispatch(setMelBalance(melBalanceString));
    } catch (err: any) {
      const modalContent = getTxError(err);

      setWaitingModalContent({
        ...waitingModalContent,
        ...modalContent,
      });

      return;
    }
    setWaitingModalContent(defaultModalContent);
  };

  return (
    <div
      className={`${styles.pageHeaderContainer} ${
        props.className ? props.className : ''
      }`}
    >
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <div
            className={`${styles.navigateBtn} ${styles.howToPlayButton}`}
            onClick={() => navigate('/how-to-play')}
          >
            <Text type={'h3'}>How to Play</Text>
          </div>
          <a href={discordUrl} target={'_blank'}>
            <div className={styles.joinButton}>
              <DiscordIcon
                sx={{
                  fontSize: '1.5rem !important',
                  marginRight: '0.55rem',
                }}
              />
              <Text type='button'>Join Discord</Text>
            </div>
          </a>
        </div>
        <div className={styles.currencyInHeader}>
          {currentGameCoin !== '' && (
            <CurrencyBox
              value={currentGameCoin}
              icon={'r-coin'}
              style={{ marginRight: '25px' }}
              symbol={'RC'}
            />
          )}
          {currentEnergy !== '' && (
            <CurrencyBox
              value={currentEnergy}
              icon={'energy'}
              style={{ marginRight: '25px' }}
            />
          )}
          {currentMelCoin !== '' && (
            <FaucetButton
              currencyBalance={currentMelCoin}
              clickHandler={() => {
                faucetHandler();
              }}
              endIcon={
                <MelxCryptoLogo sx={{ width: '26px', height: '26px' }} />
              }
              style={{ marginRight: '25px' }}
            />
          )}
          <MetamaskButton />
        </div>
      </div>
      {!props?.hideTitleSection && (
        <Grid
          container
          spacing={2}
          className={styles.pageTitle}
          alignItems='center'
        >
          <Grid item xs={3}>
            {!props.hideBackButton && <BackButton />}
          </Grid>
          <Grid item xs={6}>
            {props?.pageTitle && (
              <Text type='h1' className={styles.title} align={'center'}>
                {props?.pageTitle}
              </Text>
            )}
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      )}
      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={closeModalHandle}
      />
    </div>
  );
}
export default PageHeader;
