import { Grid, Stack, Typography } from '@mui/material';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import {
  selectAccountAddress,
  selectAccountLoginState,
} from 'src/redux/accountMetamaskSlice';
import {
  selectAccountMessage,
  selectAccountPubKey,
  selectAccountSignature,
} from 'src/redux/accountMetamaskSlice';
import { nft } from 'src/utils/constants/addresses';
import type { SignatureType } from 'src/utils/interface';

import InventoryEmpty from 'src/components/hifi/inventoryEmpty';
import LoadingSkeletons from 'src/components/hifi/loadingSkeletons';
import InventoryCard from 'src/components/lofi/card/inventoryCard';
import Navbar from 'src/components/lofi/navbar';

import {
  fetchDataInitial,
  fetchDataNext,
  resetOnAccountChanged,
} from './inventory.logic';
import styles from './inventory.module.scss';
import type { NFTProduct } from './inventory.type';
function Inventory() {
  //* ACCOUNT
  const currentAccount: string = useSelector(selectAccountAddress);
  const isLogin = useSelector(selectAccountLoginState);
  const headers: SignatureType = {
    pubkey: useSelector(selectAccountPubKey),
    message: useSelector(selectAccountMessage),
    signature: useSelector(selectAccountSignature),
  };
  //* FETCH VARIABLE
  const showMultiplier = 8;
  const [currentPage, setCurrentPage] = useState<number>(1);
  //* END OF FETCH VARIABLE
  const [showBottomPage, setShowBottomPage] = useState<boolean>(false);
  const [bottomPage, setBottomPage] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  //* PRODUCT
  const [product, setProduct] = useState<NFTProduct[]>([]);
  //* NFT COUNT
  const [nftCount, setNftCount] = useState<number>(0);
  //* PRERENDER STATE
  const [reinitialize, setReinitialize] = useState<boolean>(false);
  const [_preRender, setPreRender] = useState<boolean>(false);
  //* INITIALIZE CONTRACT
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const nftContract = new ethers.Contract(nft.address, nft.abi, signer);
  const fetchDataNextHandle = () => {
    fetchDataNext(
      product,
      currentAccount,
      nftCount,
      setNftCount,
      currentPage,
      showMultiplier,
      setProduct,
      setShowBottomPage,
      setBottomPage,
      setCurrentPage,
      setPreRender,
      headers
    );
  };
  //* INITIAL TRIGGER *//
  useEffect(() => {
    resetOnAccountChanged(
      setIsEmpty,
      setNftCount,
      currentPage,
      setProduct,
      setShowBottomPage,
      setBottomPage,
      setCurrentPage,
      setReinitialize,
      reinitialize
    );
  }, [currentAccount]);

  useEffect(() => {
    fetchDataInitial(
      currentAccount,
      nftContract,
      setIsEmpty,
      setNftCount,
      currentPage,
      showMultiplier,
      setProduct,
      setShowBottomPage,
      setBottomPage,
      setCurrentPage,
      setPreRender,
      isLogin,
      headers
    );
  }, [reinitialize]);

  return (
    <>
      <main className={styles.inventory} id='inventory'>
        <Navbar />
        <section className={styles.myInventory} id='myInventory'>
          <Stack alignItems='center' sx={{}}>
            <Typography
              variant='h4'
              sx={{
                fontWeight: 600,
                marginBottom: '2.5rem',
              }}
            >
              My Inventory
            </Typography>

            {isEmpty ? (
              <InventoryEmpty />
            ) : (
              <InfiniteScroll
                dataLength={product.length} //This is important field to render the next data
                next={fetchDataNextHandle}
                hasMore={bottomPage ? false : true}
                // loader={'loading...'}
                loader={<LoadingSkeletons cardBackgroundColor='#3f4a5c' />}
                endMessage={
                  !showBottomPage ? null : (
                    <p style={{ textAlign: 'center', marginTop: '2rem' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  )
                }
              >
                <Grid
                  container
                  direction='row'
                  justifyContent='start'
                  alignItems='center'
                  rowSpacing={8}
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  columns={12}
                  marginTop={0}
                >
                  {product.map((p: any) => {
                    return (
                      <Grid item xs={3} key={p.tokenId}>
                        <InventoryCard
                          rarity={p.rarity}
                          img={p.imageUrl}
                          id={p.tokenId}
                          description={p.description}
                          dateCreated={p.mintedAt}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </InfiniteScroll>
            )}
          </Stack>
        </section>
      </main>
    </>
  );
}

export default Inventory;
