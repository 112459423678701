import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import desktopLottie from 'src/assets/lottie/desktopLottie.json';
import DiscordIcon from 'src/assets/svg/DiscordIcon';

import styles from './mobileView.module.scss';

const style = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  //minHeight: '50vh',
  hegiht: '100%',
  color: 'white',
  padding: '40px 3rem',
  background:
    'linear-gradient(89.84deg, #FFDB01 -46.23%, #FF851F 5.03%, #FF3D3D 38.29%, #FF0F6F 74.04%, #FE01FA 129.67%)',
  backgroundClip: 'padding-box',
  border: '2px solid transparent',
  borderRadius: '20px',
  textAlign: 'center',
  outline: 'none',

  ':after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: 'inherit',
    padding: '0.125rem !important',
    zIndex: -1,
    margin: '1px',
    background: '#0C0E36',
    borderRadius: '20px',
  },
};

const _backgroundStyle = {
  position: 'absolute',
  top: '0',
  width: '95%',
  height: '101%',
  background:
    'linear-gradient(89.84deg, #FFDB01 -46.23%, #FF851F 5.03%, #FF3D3D 38.29%, #FF0F6F 74.04%, #FE01FA 129.67%)',
  zIndex: '-1',
  mixBlendMode: 'darken',
  borderRadius: 'inherit',
};

const MobileView = () => {
  const lottieDesktop = {
    loop: true,
    autoplay: true,
    animationData: desktopLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div className={styles.mobileView}>
      <Box className={styles.blurBg}></Box>
      <Box sx={style}>
        <Box sx={_backgroundStyle}></Box>
        <Box sx={{ marginBottom: '1rem' }}>
          <Lottie options={lottieDesktop} width={200} height={150}></Lottie>
        </Box>
        <Typography
          variant='h6'
          component='h6'
          sx={{ fontWeight: '600', width: '100%', color: 'white' }}
        >
          Unfortunately, Mel X - BikeBattle is not available for your mobile
          screen size yet.
        </Typography>
        <Typography variant='body1' component='p' sx={{ mt: 2, width: '100%' }}>
          Kindly access it via your desktop browser and please stay tuned for
          the update by joining our community
        </Typography>
        <Button
          target='_blank'
          href='http://discord.io/bikebattle'
          variant='primary'
          sx={{ marginTop: '34px', fontSize: '1rem' }}
          startIcon={
            <DiscordIcon
              sx={{
                fontSize: '1.5rem !important',
              }}
            />
          }
        >
          Discord
        </Button>
      </Box>
    </div>
  );
};

export default MobileView;
