import { Card, CardContent, Skeleton, Stack } from '@mui/material';

import { SkeletonCardProps } from './skeletonCard.type';

const SkeletonCard = (props: SkeletonCardProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: '319px',
          borderRadius: '30px',
          padding: '25px',
          backgroundColor: props.cardBackgroundColor,
        }}
      >
        <Stack direction='column' spacing={4}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Skeleton
              variant='text'
              sx={{
                width: '35%',
              }}
            />

            <Skeleton
              variant='text'
              sx={{
                width: '35%',
              }}
            />
          </Stack>
          <Skeleton
            variant='rectangular'
            sx={{
              height: '200px',
              width: '200px',
              marginLeft: 'auto !important',
              marginRight: 'auto !important',
            }}
          />
          <CardContent>
            <Skeleton
              variant='text'
              sx={{
                textAlign: 'center',
                width: '100%',
              }}
            />
          </CardContent>
        </Stack>
      </Card>
    </div>
  );
};

export default SkeletonCard;
