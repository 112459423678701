import { NavigateFunction } from 'react-router-dom';
import { AppDispatch } from 'src/redux/store';
import {
  checkNetwork,
  switchNetwork,
} from 'src/services/contract/metamask/account';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { singleRace } from 'src/utils/constants/addresses';
import { waitingApproveTransaction } from 'src/utils/constants/loadingMessageConstant';
import { RaceTrackData, TrackData } from 'src/utils/constants/raceTrackData';
import { getTxError } from 'src/utils/helper';

import { ModalProps } from 'src/components/lofi/modal/waitingModal';

export const defaultSingleRaceProps: ISingleRaceProps = {
  energyCost: 0,
  trackInfo: null,
};

interface ISingleRaceProps {
  energyCost: number;
  trackInfo: TrackData | null;
}

const getRaceProps = async () => {
  const singleRaceContract = initiateContract(
    singleRace.address,
    singleRace.abi
  );

  const energyCost = await singleRaceContract.energyCost();
  const currentTrackInfo = await singleRaceContract.getCurrentTrack();
  const currentTrackMeta = RaceTrackData.find(
    (trackData) => trackData.trackNumber == currentTrackInfo[0]
  );

  return {
    energyCost,
    trackInfo: {
      ...currentTrackMeta,
    },
  };
};

const startRace = async (
  dispatch: AppDispatch,
  tokenId: string,
  waitingModalContent: ModalProps,
  setWaitingModalContent: React.Dispatch<React.SetStateAction<ModalProps>>,
  navigate: NavigateFunction
) => {
  const network = await checkNetwork();
  if (network != process.env.REACT_APP_METAMASK_CHAINID) {
    await switchNetwork(dispatch);
  }

  const singleRaceContract = initiateContract(
    singleRace.address,
    singleRace.abi
  );

  try {
    setWaitingModalContent({
      ...waitingModalContent,
      ...waitingApproveTransaction,
    });

    const startRaceTx = await singleRaceContract.startRace(tokenId);

    setWaitingModalContent({
      ...waitingModalContent,
      title: 'Race is in progress',
      message:
        'You will automatically be redirected to result page. Please wait a moment.',
      showViewInventory: false,
      openStatus: true,
    });

    await startRaceTx.wait();
  } catch (err: any) {
    const modalContent = getTxError(err);

    setWaitingModalContent({
      ...waitingModalContent,
      ...modalContent,
    });

    return;
  }

  return navigate('/race/single/result', { state: { verified: true } });
};

export { getRaceProps, startRace };
export type { ISingleRaceProps };
