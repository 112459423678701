import type { OpponentProps } from './trackDetail.type';
import { ResponseResultProps } from '../result/result.type';

const playerNameSelector = (
  result: ResponseResultProps,
  opponents: OpponentProps[]
) => {
  if (result.isBot === false) {
    return 'You';
  } else {
    return opponents.find((opp) => opp.id === result.id)?.name;
  }
};

export const sortPlayersRank = (
  responseResult: ResponseResultProps[],
  opponents: OpponentProps[]
) => {
  const playerRanks = responseResult.map((resResult) => {
    return { name: playerNameSelector(resResult, opponents) };
  });
  return playerRanks;
};
