import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal } from '@mui/material';

import styles from './confirmModal.module.scss';
import {
  _backgroundStyle,
  boxStyle,
  ConfirmModalProps,
} from './confirmModal.type';
import GradientButton from '../../gradientButton';
import Text from '../../text';

function ConfirmModal(props: ConfirmModalProps) {
  const {
    open,
    onClose,
    modalTitle,
    modalDetailText,
    style,
    confirmButtonTitle,
    cancelButtonTitle,
    onCancelButtonClick,
    onConfirmButtonClick,
    notEnoughFund,
  } = props;

  return (
    <Modal
      open={open}
      onClose={() => (onClose ? onClose() : null)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        '.MuiBackdrop-root': {
          backdropFilter: 'blur(10px)',
        },
      }}
    >
      <Box sx={{ ...boxStyle, ...style, justifyContent: 'center' }}>
        <>
          <IconButton
            onClick={onCancelButtonClick}
            sx={{
              position: 'absolute',
              right: '0',
              top: '0',
              margin: '12px',
              color: 'white',
              zIndex: 3,
            }}
            size='large'
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        </>
        <Box sx={_backgroundStyle}></Box>
        <div className={styles.childrenContainer}>
          <>
            <Text type='h1' className={styles.modalTitle} align={'center'}>
              {modalTitle}
            </Text>
            <Text align={'center'} className={styles.modalDetail}>
              {modalDetailText}
            </Text>
            <div className={styles.buttonWrapper}>
              <GradientButton
                theme='secondary'
                title={cancelButtonTitle ? cancelButtonTitle : 'Cancel'}
                onClick={onCancelButtonClick}
              />
              <GradientButton
                disabled={notEnoughFund}
                title={confirmButtonTitle ? confirmButtonTitle : 'Confirm'}
                onClick={onConfirmButtonClick}
              />
            </div>
            {notEnoughFund && (
              <div className={styles.textWrapper}>
                <div className={styles.leftText} />
                <div className={styles.rightText}>Insufficient MEL Coin.</div>
              </div>
            )}
          </>
        </div>
      </Box>
    </Modal>
  );
}

export default ConfirmModal;
