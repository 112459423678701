import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { format, fromUnixTime } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { images, svgs } from 'src/assets';
import { RootState } from 'src/redux/store';
import { GET_USER_SINGLE_RACE_HISTORY } from 'src/utils/constants/query';
import { formatStringToDecimal } from 'src/utils/helper';

import EmptyTable from 'src/components/hifi/emptyTable';
import LoadingTable from 'src/components/hifi/loadingTable';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';
import Pagination from 'src/components/lofi/pagination';
import {
  PaginationInitialState,
  PaginationStateType,
} from 'src/components/lofi/pagination/pagination.type';
import Text from 'src/components/lofi/text';

import styles from './singleSection.module.scss';

const SingleRaceHistory = () => {
  const { account } = useSelector((state: RootState) => state);

  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);
  const [pagination, setPagination] = useState<PaginationStateType>(
    PaginationInitialState
  );
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(10);

  const {
    loading: totalUserSingleRaceHistoryLoading,
    data: totalUserSingleRaceHistory,
    refetch: refetchTotalUserSingleRaceHistory,
  } = useQuery(GET_USER_SINGLE_RACE_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      userAddress: account.account,
    },
  });

  const {
    loading,
    data: userSingleRaceHistory,
    refetch: refetchUserSingleRaceHistory,
    fetchMore,
  } = useQuery(GET_USER_SINGLE_RACE_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      userAddress: account.account,
      offset: paginationOffset,
      limit: paginationLimit,
    },
  });

  const handleLoadPaginationData = () => {
    if (
      typeof totalUserSingleRaceHistory !== 'undefined' &&
      typeof userSingleRaceHistory !== 'undefined'
    ) {
      const totalPages = Math.ceil(
        totalUserSingleRaceHistory.raceResults.length / paginationLimit
      );
      const currentPage =
        (paginationOffset + paginationLimit) / paginationLimit;

      const paginationState = {
        hasNextPage: totalPages > currentPage,
        hasPrevPage: currentPage > 1,
        page: currentPage,
        totalPages: totalPages > 0 ? totalPages : 1,
        pageLimit: paginationLimit,
        pageOffset: paginationOffset,
      };

      setPagination(paginationState);
    }
  };

  useEffect(() => {
    fetchMore({ variables: { offset: paginationOffset } });
  }, [paginationOffset]);

  useEffect(() => {
    handleLoadPaginationData();
  }, [loading, totalUserSingleRaceHistoryLoading]);

  useEffect(() => {
    refetchUserSingleRaceHistory();
    refetchTotalUserSingleRaceHistory();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : userSingleRaceHistory.length !== 0 ? (
        <>
          <Grid container className={styles.tableRH}>
            <Grid container className={styles.tableHeader}>
              <Grid item xs={3}>
                <Text type='h3'>Date of Race</Text>
              </Grid>
              <Grid item xs={4}>
                <Text type='h3' align={'center'}>
                  Bike
                </Text>
              </Grid>
              <Grid item xs={4}>
                <Text type='h3'>Reward</Text>
              </Grid>
            </Grid>
            {userSingleRaceHistory.raceResults.map(
              (history: any, index: number) => (
                <Grid
                  key={`raceHistory${index}`}
                  container
                  className={`${
                    (index + 1) % 2 === 0 ? styles.even : styles.odd
                  } ${styles.tableRow}`}
                >
                  <Grid item xs={3} alignItems={'center'}>
                    <Text color={'white'}>
                      {format(
                        fromUnixTime(history.blockTimestamp),
                        'dd-MM-yyyy HH:mm:ss'
                      )}
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text color={'white'} align={'center'}>
                      {`${history.nftName} #${history.nftId}`}
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={styles.prizeContainer}>
                      <div className={styles.prizeItem}>
                        <img src={images.expRace} />
                        <Text
                          color={'white'}
                          className={styles.rewardText}
                        >{`${history.exp.toString()} XP`}</Text>
                      </div>
                      <div className={styles.prizeItem}>
                        <img src={svgs.melCoinIcon} />
                        <Text
                          color={'white'}
                          className={styles.rewardText}
                        >{`${formatStringToDecimal(history.mel)} MEL`}</Text>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
          <Pagination
            pagination={pagination}
            setCurrentPage={setPaginationOffset}
            className={styles.pagination}
          />
        </>
      ) : (
        <EmptyTable
          title={`You haven't finished any race yet`}
          description={`The race history will appear here after you finish a race`}
        />
      )}
      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={() => setWaitingModalContent(defaultModalContent)}
      />
    </>
  );
};

export default SingleRaceHistory;
