import { Dispatch, SetStateAction } from 'react';
import { getEnergyPackage } from 'src/services/api/energy';
import { buyEnergy } from 'src/services/contract/game/energy';

import { ModalProps } from 'src/components/lofi/modal/waitingModal';

import { AppDispatch } from './../../redux/store';
import type {
  EnergyPackage,
  SelectedButtonProps,
} from './chargingStation.type';

function purchaseHandle(
  account: string,
  energyId: number,
  dispatch: AppDispatch,
  setWaitingModalContent: Dispatch<SetStateAction<ModalProps>>
) {
  buyEnergy(account, energyId, dispatch, setWaitingModalContent);
}

function selectHandle(
  energyId: number,
  cost: number,
  energy: number,
  selectedButton: SelectedButtonProps,
  setSelectedButton: Dispatch<SetStateAction<SelectedButtonProps>>
) {
  if (selectedButton.id === energyId) {
    setSelectedButton({ id: -1, energy: 0, cost: 0 });
  } else {
    setSelectedButton({ id: energyId, energy: energy, cost: cost });
  }
}
async function getEnergyPackageHandle(
  setEnergyPackage: Dispatch<SetStateAction<EnergyPackage[]>>
) {
  const responseEnergyPackage = await getEnergyPackage();
  setEnergyPackage(responseEnergyPackage);
}
export { getEnergyPackageHandle, purchaseHandle, selectHandle };
