import { Box, Typography } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import LockIcon from 'src/assets/lottie/lockIcon.json';

import styles from './noWalletModal.module.scss';

const NoWalletModal = () => {
  const lockAccount = {
    loop: true,
    autoplay: true,
    animationData: LockIcon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <section className={styles.noWalletModal}>
      <Box className={styles.blurBg}></Box>
      <Box className={styles.section}>
        <Box className={styles.background} />
        <Box>
          <Lottie options={lockAccount} width={160} height={160}></Lottie>
        </Box>
        <Typography
          variant='h6'
          component='h6'
          sx={{ fontWeight: '600', width: '100%', color: 'white' }}
        >
          No Metamask Detected.
        </Typography>
        <Typography
          variant='body1'
          component='p'
          sx={{ mt: 2, mb: '24px', width: '100%' }}
        >
          Please install Metamask to continue using this application.
        </Typography>
      </Box>
    </section>
  );
};

export default NoWalletModal;
