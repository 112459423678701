import Text from 'src/components/lofi/text';
import { FilterIcon } from 'src/components/Icon';
import styles from './bikeFilter.module.scss';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  BikeFilterProps,
  bikeTypes,
  menuItemProps,
  selectSx,
} from './bikeFilter.type';

export default function BikeFilter(props: BikeFilterProps) {
  const { filter, setFilter } = props;

  const handleSelectFilter = (e: SelectChangeEvent<string>) => {
    setFilter(e.target.value);
  };

  return (
    <div className={styles.bikeFilter}>
      <div className={styles.label}>
        <FilterIcon length='16px' />
        <Text className={styles.labelText}>Filter by</Text>
      </div>
      <Select
        className={styles.selectType}
        value={filter}
        onChange={(e) => {
          handleSelectFilter(e);
        }}
        MenuProps={menuItemProps}
        sx={selectSx}
      >
        {bikeTypes.map((type) => {
          return (
            <MenuItem
              key={type.key}
              value={type.key}
              sx={{ fontFamily: 'Inter' }}
            >
              {type.label}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}
