import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Button } from '@mui/material';
import React from 'react';

import Tooltip from 'src/components/lofi/tooltip';

import type { ClipboardButtonProps } from './clipboardButton.type';

const ClipboardButton = (props: ClipboardButtonProps) => {
  const [copied, setCopied] = React.useState<boolean>(false);

  const handleCopied = () => {
    setCopied(!copied);
    navigator.clipboard.writeText(props.copyText);
  };

  const handleCopiedClose = () => {
    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  return (
    <Tooltip
      title={!copied ? 'Copy the Referral Link' : 'Copied to Clipboard'}
      arrow
    >
      <Button
        onMouseLeave={handleCopiedClose}
        onClick={handleCopied}
        variant='outlinedGradient'
        sx={{
          backgroundColor: 'transparent',
          width: 'fit-content',
          minWidth: 'unset',
          padding: '0.5rem 0.5rem',
          '&:before': {
            minWidth: 'unset',
            padding: '0.1rem !important',
          },
        }}
      >
        <ContentPasteIcon sx={{ color: 'white' }} />
      </Button>
    </Tooltip>
  );
};

export default ClipboardButton;
