import { BigNumber } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { setMelCoinBalance } from 'src/redux/accountMetamaskSlice';
import { AppDispatch } from 'src/redux/store';
import {
  checkNetwork,
  getAccount,
  switchNetwork,
} from 'src/services/contract/metamask/account';
import { initiateContract } from 'src/services/contract/metamask/provider';
import { getNftTotalCount } from 'src/services/contract/nft/nft';
import { mel, nft, store } from 'src/utils/constants/addresses';
import { modalUserRejectedTheApproval } from 'src/utils/constants/errorMessageConstants';
import {
  waitingApproveTransaction,
  waitingAuthorizedFund,
} from 'src/utils/constants/loadingMessageConstant';
import { formatBigNumberToString, getTxError } from 'src/utils/helper';

import {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';

export const defaultGarageProps: IGarageProps = {
  garageLimit: 0,
  nftCount: 0,
  upgradePrice: '',
};

interface IGarageProps {
  garageLimit: number;
  nftCount: number;
  upgradePrice: string;
}

const updateBbBalance = async (dispatch: AppDispatch, account: string) => {
  const melContract = initiateContract(mel.address, mel.abi);

  const latestBbBalance = await melContract.balanceOf(account);
  const stringBBBalance = Math.floor(
    Number(formatEther(latestBbBalance))
  ).toString();

  dispatch(setMelCoinBalance(stringBBBalance));
};

const getGarageProps = async (address: string) => {
  const nftContract = initiateContract(nft.address, nft.abi);
  const storeContract = initiateContract(store.address, store.abi);

  const garageLimit = await nftContract.getUserGarageLimit(address);
  const nftCount = await getNftTotalCount(nftContract, address);
  const upgradePrice = await storeContract.garagePrice();

  return {
    garageLimit,
    nftCount,
    upgradePrice: Number(formatBigNumberToString(upgradePrice)).toFixed(2),
  };
};

const upgradeGarageLimit = async (
  dispatch: AppDispatch,
  waitingModalContent: ModalProps,
  setWaitingModalContent: React.Dispatch<React.SetStateAction<ModalProps>>
) => {
  const network = await checkNetwork();
  if (network != process.env.REACT_APP_METAMASK_CHAINID) {
    await switchNetwork(dispatch);
  }

  const storeContract = initiateContract(store.address, store.abi);
  const melContract = initiateContract(mel.address, mel.abi);

  const account = await getAccount();

  const totalAllowance = await melContract.allowance(account, store.address);
  const upgradePrice = await storeContract.garagePrice();
  const bbTotalSupply = await melContract.totalSupply();

  try {
    if (BigNumber.from(totalAllowance).lt(upgradePrice)) {
      setWaitingModalContent({
        ...waitingModalContent,
        ...waitingAuthorizedFund,
      });

      const bbApproval = await melContract.approve(
        store.address,
        BigNumber.from(bbTotalSupply).toBigInt()
      );

      await bbApproval.wait();
    }
  } catch (err: any) {
    setWaitingModalContent({
      ...waitingModalContent,
      ...modalUserRejectedTheApproval,
    });

    return;
  }

  try {
    setWaitingModalContent({
      ...waitingModalContent,
      ...waitingApproveTransaction,
    });

    const upgradeLimit = await storeContract.buyGarageLimit();
    await upgradeLimit.wait();
  } catch (err: any) {
    const modalContent = getTxError(err);

    setWaitingModalContent({
      ...waitingModalContent,
      ...modalContent,
    });

    return;
  }

  await updateBbBalance(dispatch, account);

  setWaitingModalContent({
    ...defaultModalContent,
    openStatus: true,
    title: 'Transaction Success',
    message: 'You have successfully upgraded the limit',
    showViewInventory: false,
  });
};

export { getGarageProps, upgradeGarageLimit };
export type { IGarageProps };
