import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from './store';

interface TypeGameInterface {
  homeTab: string;
  workshopTab: string;
  bankTab: string;
}

const initialState: TypeGameInterface = {
  homeTab: 'achievement',
  workshopTab: 'shop',
  bankTab: 'exchange',
};

export const gameInterfaceSlice = createSlice({
  name: 'gameInterface',
  initialState,
  reducers: {
    setHomeTab: (state, action: PayloadAction<string>) => {
      state.homeTab = action.payload;
    },
    resetHomeTab: (state) => {
      state.homeTab = 'referral';
    },
    setWorkshopTab: (state, action: PayloadAction<string>) => {
      state.workshopTab = action.payload;
    },
    resetWorkshopTab: (state) => {
      state.workshopTab = 'shop';
    },
    setBankTab: (state, action: PayloadAction<string>) => {
      state.bankTab = action.payload;
    },
    resetBankTab: (state) => {
      state.bankTab = 'exchange';
    },
  },
});

export const {
  setHomeTab,
  resetHomeTab,
  setWorkshopTab,
  resetWorkshopTab,
  setBankTab,
  resetBankTab,
} = gameInterfaceSlice.actions;

// Selections - This is how we pull information from the Global store slice
export const selectHomeTabState = (state: RootState) =>
  state.gameInterface.homeTab;
export const selectWorkshopTabState = (state: RootState) =>
  state.gameInterface.workshopTab;
export const selectBankTabState = (state: RootState) =>
  state.gameInterface.bankTab;

export default gameInterfaceSlice.reducer;
