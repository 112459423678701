import type { Dispatch, SetStateAction } from 'react';
import {
  fetchingReferralData,
  getTotalUsersUsed,
  getTotalReferralUsed,
} from 'src/services/subgraph/referral';
import { formatDecimal, paginate } from 'src/utils/helper';

import { PaginationStateType } from 'src/components/lofi/pagination/pagination.type';

import type { ReferralDashboardData } from './referralDashboard.type';

async function fetchingReferral(
  currentAccount: string,
  setTotalReferralMel: Dispatch<SetStateAction<string>>,
  getReferral: any,
  setDisplayData: Dispatch<SetStateAction<ReferralDashboardData[]>>,
  setTotalReferralUsed: Dispatch<SetStateAction<number>>,
  setTotalUserUsed: Dispatch<SetStateAction<number>>,
  setPreRender: Dispatch<SetStateAction<boolean>>,
  setLoading?: Dispatch<SetStateAction<boolean>>
) {
  //* 1 fetching full data from subgraph
  const referralData: ReferralDashboardData[] = await fetchingReferralData(
    getReferral,
    currentAccount
  );
  //* 2 get total earned MEL from referral
  setTotalReferralMel(referralData.length.toString());
  setDisplayData(referralData);
  // hide loading
  setLoading && setLoading(false);

  //* 3 get total users used
  const pushTotalUserUsed: number = getTotalUsersUsed(
    referralData,
    currentAccount
  );
  setTotalUserUsed(pushTotalUserUsed);

  //* 4 get total referral used
  const pushTotalReferralUsed: number = getTotalReferralUsed(
    referralData,
    currentAccount
  );
  setTotalReferralUsed(pushTotalReferralUsed);

  //* 5 update render
  setPreRender((preRender) => !preRender);
}

function getReferralDataByPage(
  referralData: ReferralDashboardData[],
  setReferralData: Dispatch<SetStateAction<ReferralDashboardData[]>>,
  currentPage: number,
  pagination: PaginationStateType,
  setPagination: Dispatch<SetStateAction<PaginationStateType>>
) {
  // limit list to 10
  const referralPages = paginate(referralData, 10);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  setReferralData(referralPages[currentPage - 1]);
  // pagination &&
  //   setPagination &&
  //   setPagination({
  //     ...pagination,
  //     page: currentPage,
  //     totalPages: referralPages.length,
  //     prevPage: prevPage === 0 ? null : prevPage,
  //     nextPage: nextPage,
  //     hasNextPage: currentPage !== referralPages.length,
  //     hasPrevPage: currentPage !== 1,
  //   });
}

export { fetchingReferral, getReferralDataByPage };
