import Text from 'src/components/lofi/text';

import styles from './levelUpSection.module.scss';
import { LevelUpSectionProps } from './levelUpSection.type';

export default function LevelUpSection(props: LevelUpSectionProps) {
  const { levelUpData } = props;
  return (
    <div className={styles.levelUp}>
      <Text
        className={styles.levelPlus}
        align={'center'}
        style={{ marginTop: 30 }}
      >
        <>
          {`Level ${levelUpData?.previousLevel} -->`}
          <Text
            className={styles.levelPlus}
            color={'#FF851F'}
            style={{ marginLeft: 5 }}
          >
            {`Level ${levelUpData?.currentLevel}`}
          </Text>
        </>
      </Text>
      <ul className={styles.attrBox}>
        <li className={styles.attrTitle}>
          <Text align={'center'} className={styles.attrText}>
            Attributes
          </Text>
        </li>
        <li className={styles.attrItem}>
          <Text align={'center'} className={styles.attrText}>
            <>
              Acceleration
              <Text className={styles.attrText2}>
                {`+${levelUpData?.attributes.acceleration}`}
              </Text>
            </>
          </Text>
        </li>
        <li className={styles.attrItem}>
          <Text align={'center'} className={styles.attrText}>
            <>
              Speed
              <Text className={styles.attrText2}>
                {`+${levelUpData?.attributes.speed}`}
              </Text>
            </>
          </Text>
        </li>
        <li className={styles.attrItem}>
          <Text align={'center'} className={styles.attrText}>
            <>
              Drift
              <Text className={styles.attrText2}>
                {`+${levelUpData?.attributes.drift}`}
              </Text>
            </>
          </Text>
        </li>
      </ul>
    </div>
  );
}
