import GppGoodIcon from '@mui/icons-material/GppGood';
import { images, svgs } from 'src/assets';

interface IconSize {
  length: string;
}

const EnergyIcon = (props: IconSize) => {
  return (
    <img
      src={images.energyIcon}
      style={{
        width: props.length,
        height: props.length,
        objectFit: 'contain',
      }}
    />
  );
};

const MelCoinIcon = (props: IconSize) => {
  return (
    <img
      src={svgs.melCoinIcon}
      style={{
        width: props.length,
        height: props.length,
        objectFit: 'contain',
      }}
    />
  );
};

const GameCoinIcon = (props: IconSize) => {
  return (
    <img
      src={images.rcCoinIcon}
      style={{
        width: props.length,
        height: props.length,
        objectFit: 'contain',
      }}
    />
  );
};

const SpeedIcon = (props: IconSize) => {
  return (
    <img
      src={svgs.speedIcon}
      style={{
        width: props.length,
        height: props.length,
        objectFit: 'contain',
      }}
    />
  );
};

const AccelerationIcon = (props: IconSize) => {
  return (
    <img
      src={svgs.accelerationIcon}
      style={{
        width: props.length,
        height: props.length,
        objectFit: 'contain',
      }}
    />
  );
};

const DriftIcon = (props: IconSize) => {
  return (
    <img
      src={svgs.driftIcon}
      style={{
        width: props.length,
        height: props.length,
        objectFit: 'contain',
      }}
    />
  );
};

const FilterIcon = (props: IconSize) => {
  return (
    <img
      src={svgs.filterIcon}
      style={{
        width: props.length,
        height: props.length,
        objectFit: 'contain',
      }}
    />
  );
};

const CustomIcon = (props: IconSize) => {
  return (
    <GppGoodIcon
      sx={{
        width: props.length,
        height: props.length,
      }}
    />
  );
};

export {
  AccelerationIcon,
  CustomIcon,
  DriftIcon,
  EnergyIcon,
  FilterIcon,
  GameCoinIcon,
  MelCoinIcon,
  SpeedIcon,
};
