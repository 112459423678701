import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { images } from 'src/assets';
import { selectAccountEnergyBalance } from 'src/redux/accountMetamaskSlice';
import { RootState } from 'src/redux/store';

import GradientButton from 'src/components/lofi/gradientButton';
import WaitingModal, {
  defaultModalContent,
  ModalProps,
} from 'src/components/lofi/modal/waitingModal';
import Text from 'src/components/lofi/text';

import styles from './trackDetail.module.scss';
import {
  defaultSingleRaceProps,
  getRaceProps,
  ISingleRaceProps,
  startRace,
} from '../race.logic';

function TrackDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [waitingModalContent, setWaitingModalContent] =
    useState<ModalProps>(defaultModalContent);
  const [raceProps, setRaceProps] = useState<ISingleRaceProps>(
    defaultSingleRaceProps
  );
  const [isLoading, setIsLoading] = useState(true);

  const { pubkey, message, signature } = useSelector(
    (state: RootState) => state.account
  );
  const { activeBike } = useSelector((state: RootState) => state.race);
  const currentEnergy: string = useSelector(selectAccountEnergyBalance);

  const handleStartRace = async () => {
    const hasEnoughEnergy = parseInt(currentEnergy) >= raceProps.energyCost;

    if (activeBike && hasEnoughEnergy) {
      await startRace(
        dispatch,
        activeBike.tokenId.toString(),
        waitingModalContent,
        setWaitingModalContent,
        navigate
      );
    } else if (!hasEnoughEnergy) {
      const modalContent = {
        title: 'Hold up!',
        message: `You don't have enough energy, please buy more energy!`,
        showViewInventory: false,
        openStatus: true,
      };

      return setWaitingModalContent({
        ...waitingModalContent,
        ...modalContent,
      });
    }
  };

  const handleGetRaceProps = async () => {
    const singleRaceProps = await getRaceProps();

    return setRaceProps(singleRaceProps);
  };

  useEffect(() => {
    if (!isLoading && !raceProps.trackInfo) {
      navigate('/world-map');
    }
  }, [isLoading]);

  useEffect(() => {
    handleGetRaceProps().then(() => setIsLoading(false));
  }, [waitingModalContent]);

  return (
    <>
      <div className={styles.trackContainer}>
        <div className={styles.trackInfo}>
          <Text type={'h2'} align={'center'} className={styles.pageTitle}>
            {raceProps.trackInfo?.name}
          </Text>
          <div className={styles.row}>
            <div className={styles.colLeft}>
              <img width={'100%'} src={raceProps.trackInfo?.imageUrl} />
            </div>
            <div className={styles.colRight}>
              {ReactHtmlParser(
                raceProps.trackInfo?.narration?.find(
                  (n) => n.narrationType === 'join-race'
                )?.description ?? ''
              )}

              {raceProps.trackInfo && (
                <div className={styles.buttonWrapper}>
                  <GradientButton
                    title='Start Race'
                    onClick={() => handleStartRace()}
                    rightContent={
                      <div className={styles.btnRightContent}>
                        <img width={10} height={19} src={images.energyIcon} />{' '}
                        {raceProps.energyCost}
                      </div>
                    }
                    textStyle={{ flex: 0.75 }}
                    style={{ marginTop: 50 }}
                    disabled={
                      activeBike &&
                      parseInt(currentEnergy) >= raceProps.energyCost
                        ? false
                        : true
                    }
                  />
                  {parseInt(currentEnergy) < raceProps.energyCost && (
                    <Text
                      className={styles.errorText}
                      type={'small'}
                      align={'center'}
                    >
                      You don't have enough energy. Please buy more energy on
                      Charging Station.
                    </Text>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <WaitingModal
        openStatus={waitingModalContent.openStatus}
        title={waitingModalContent.title}
        message={waitingModalContent.message}
        showViewInventory={waitingModalContent.showViewInventory}
        buttonMessage={waitingModalContent.buttonMessage}
        event={() => null}
        closeModal={() => setWaitingModalContent(defaultModalContent)}
      />
    </>
  );
}

export default TrackDetail;
