import styles from './bikeCard.module.scss';
import type { BikeCardProps } from './bikeCard.type';
import GlowBox from '../../box/glowBox';
import RarityBox from '../../box/rarityBox';
import Text from '../../text';
import BikeAttributes from 'src/components/hifi/bikeAttributes';

function BikeCard(props: BikeCardProps) {
  const { data, style, disabled, onClick, isActive } = props;

  return (
    <GlowBox
      isActive={isActive}
      disabled={disabled}
      style={{ ...style }}
      {...(onClick && { onClick: () => onClick() })}
    >
      <div className={styles.bikeCard}>
        <RarityBox type={data.rarity} />
        <Text type={'h3'} className={styles.name}>
          {data.name}
        </Text>
        <Text className={styles.type}>{`Type: ${data.type}`}</Text>
        <Text className={styles.level}>{`Level: ${data.level}`}</Text>
        <BikeAttributes
          attributes={{ speed: data.S, acceleration: data.A, drift: data.D }}
        />
        <div className={styles.bikeImage}>
          <img width={'100%'} src={data.imageUrl} />
        </div>
      </div>
    </GlowBox>
  );
}

export default BikeCard;
