import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import accountMetamaskReducer from './accountMetamaskSlice';
import gameInterfaceReducer from './gameInterfaceSlice';
import modalContentReducer from './modalContentSlice';
import modalShowReducer from './modalShowSlice';
import raceReducer from './raceSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  modalContent: modalContentReducer,
  modalShow: modalShowReducer,
  account: accountMetamaskReducer,
  race: raceReducer,
  gameInterface: gameInterfaceReducer,
});

const persistedRootReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
