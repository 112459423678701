import { Dispatch, SetStateAction } from 'react';

export interface BikeFilterProps {
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
}

export const bikeTypes = [
  {
    key: 'all',
    label: 'Show all',
  },
  {
    key: 'common',
    label: 'Common',
  },
  {
    key: 'uncommon',
    label: 'Uncommon',
  },
  {
    key: 'rare',
    label: 'Rare',
  },
  {
    key: 'epic',
    label: 'Epic',
  },
  {
    key: 'legendary',
    label: 'Legendary',
  },
];

export const menuItemProps = {
  sx: {
    '.MuiPaper-root': {
      backgroundColor: 'rgba(31, 27, 53, 0.8)',
      border: '1px solid rgba(52, 49, 74, 0.8)',
      borderRadius: '10px',
      width: '233px',
      marginLeft: '13px',
      marginTop: '5px',
      ul: { padding: '0' },
      li: {
        borderBottom: '1px solid rgba(52, 49, 74, 0.8)',
        color: 'white',
        padding: '13px 20px',
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '18px',
        '&:last-child': {
          borderBottom: 'none',
        },
        '&.Mui-selected, &:hover': {
          backgroundColor: 'rgba(31, 27, 53, 0.8)',
          color: '#0097FF',
        },
      },
    },
  },
};

export const selectSx = {
  border: '1px solid darkgrey',
  color: '#fff',
  '& .MuiSvgIcon-root': {
    color: 'white',
    marginRight: '10px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
    {
      border: '1px solid transparent',
    },
};
