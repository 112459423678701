import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import LockIcon from 'src/assets/lottie/lockIcon.json';

import styles from './lockAccountModal.module.scss';

const LockAccountModal = () => {
  const lockAccount = {
    loop: true,
    autoplay: true,
    animationData: LockIcon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  async function loginMetamaskHandle() {
    window.ethereum.request({
      method: 'eth_requestAccounts',
    });
  }
  return (
    <section className={styles.lockAccountModal}>
      <Box className={styles.blurBg}></Box>
      <Box className={styles.section}>
        <Box className={styles.background} />
        <Box>
          <Lottie options={lockAccount} width={160} height={160}></Lottie>
        </Box>
        <Typography
          variant='h6'
          component='h6'
          sx={{ fontWeight: '600', width: '100%', color: 'white' }}
        >
          Account Locked.
        </Typography>
        <Typography
          variant='body1'
          component='p'
          sx={{ mt: 2, mb: '24px', width: '100%' }}
        >
          Unlock your account to continue.
        </Typography>
        <Button
          onClick={() => {
            loginMetamaskHandle();
          }}
          variant='primary'
          sx={{ fontSize: '1rem' }}
        >
          Unlock
        </Button>
      </Box>
    </section>
  );
};

export default LockAccountModal;
