import { useLocation, useNavigate } from 'react-router-dom';
import { images } from 'src/assets';

import styles from './backButton.module.scss';
import Text from '../../text';

function BackButton() {
  const navigate = useNavigate();
  const { pathname, state }: { pathname: string; state: any } = useLocation();

  const handleNavigate = () => {
    if (
      document.referrer === '' ||
      state?.from?.includes('result') ||
      pathname.includes('garage') ||
      pathname.includes('bank')
    ) {
      return navigate('/world-map');
    }

    return navigate(-1);
  };

  return (
    <div className={styles.backBtn} onClick={() => handleNavigate()}>
      <img width={25} height={32} src={images.backIcon} />
      <Text type='h3'>Back</Text>
    </div>
  );
}

export default BackButton;
