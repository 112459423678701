import { SxProps, Theme } from '@mui/material';

export interface GradientModalProps {
  open: boolean;
  onClose?: () => void;
  children: JSX.Element;
  style?: SxProps<Theme>;
}

export const boxStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  minWidth: '734px',
  minHeight: '433px',
  color: 'white',
  padding: '75px',
  background:
    'linear-gradient(89.84deg, #FFDB01 -46.23%, #FF851F 5.03%, #FF3D3D 38.29%, #FF0F6F 74.04%, #FE01FA 129.67%)',
  backgroundClip: 'padding-box',
  border: '2px solid transparent',
  borderRadius: '20px',
  textAlign: 'center',
  outline: 'none',

  ':after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // transform: 'translate(-50%, -50%)',
    zIndex: -1,
    margin: '1px',
    background: '#0C0E36',
    borderRadius: '20px',
  },
};

export const _backgroundStyle = {
  position: 'absolute',
  top: '0',
  width: '95%',
  height: '101%',
  background:
    'linear-gradient(89.84deg, #FFDB01 -46.23%, #FF851F 5.03%, #FF3D3D 38.29%, #FF0F6F 74.04%, #FE01FA 129.67%)',
  zIndex: '-1',
  mixBlendMode: 'darken',
  borderRadius: 'inherit',
};

export const _secondBackgroundStyle = {
  position: 'absolute',
  top: '0',
  width: '101%',
  height: '100%',
  background:
    'linear-gradient(89.84deg, #FFDB01 -46.23%, #FF851F 5.03%, #FF3D3D 38.29%, #FF0F6F 74.04%, #FE01FA 129.67%)',
  zIndex: '-1',
  mixBlendMode: 'darken',
  borderRadius: 'inherit',
};
