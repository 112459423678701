import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectAccountAddress,
  selectHasNft,
} from 'src/redux/accountMetamaskSlice';
import { getAllOwnedBikes } from 'src/services/api/nft';
import { getMelCoinBalance } from 'src/services/contract/erc20/mel';
import { getEnergyBalance } from 'src/services/contract/game/energy';

import ComingSoonModal from 'src/components/hifi/comingSoonModal';
import PageHeader from 'src/components/hifi/pageHeader';
import WorldMapGrid from 'src/components/hifi/worldMapGrid';
import VersionBox from 'src/components/lofi/box/versionBox';

import styles from './worldMap.module.scss';
import { useLazyQuery } from '@apollo/client';
import { GET_NFT_USER } from 'src/utils/constants/query';

function WorldMap() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectAccountAddress);
  const hasNft = useSelector(selectHasNft);
  const [getNft] = useLazyQuery(GET_NFT_USER, {
    fetchPolicy: 'network-only',
    variables: {
      owner: currentAccount,
    },
  });
  const [showComingSoonModal, setShowComingSoonModal] =
    useState<boolean>(false);

  const getWorldMapInfoHandle = async (currentAccount: string) => {
    await getMelCoinBalance(dispatch, currentAccount);
    await getEnergyBalance(currentAccount, dispatch);
  };

  useEffect(() => {
    //? is the player logged in when entering world map
    if (currentAccount !== '' && hasNft) {
      getWorldMapInfoHandle(currentAccount);
    } else {
      navigate('/');
    }
  }, [currentAccount, hasNft]);

  useEffect(() => {
    getAllOwnedBikes(getNft, dispatch);
  }, [navigate]);

  return (
    <>
      <section id='worldMap' className={styles.worldMap}>
        <PageHeader className={styles.pageHeaderMap} hideTitleSection={true} />
        <WorldMapGrid setShowComingSoonModal={setShowComingSoonModal} />
        <VersionBox />

        {showComingSoonModal && (
          <ComingSoonModal
            showModal={showComingSoonModal}
            setShowModal={setShowComingSoonModal}
          />
        )}
      </section>
    </>
  );
}

export default WorldMap;
