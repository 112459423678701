import { BigNumber } from 'ethers';
import { Dispatch, SetStateAction } from 'react';
import {
  setEnoughMelFalse,
  setWhitelistStatus,
} from 'src/redux/accountMetamaskSlice';
import { setModalContent } from 'src/redux/modalContentSlice';
import { AppDispatch } from 'src/redux/store';
import {
  getMelBalance,
  getMelTotalSupply,
  isMelAllowanceEnoughToMint,
} from 'src/services/contract/erc20/mel';
import { isMelBalanceEnoughToMint } from 'src/services/contract/erc20/mel';
import {
  checkNetwork,
  getAccount,
  getWhitelistStatus,
  switchNetwork,
} from 'src/services/contract/metamask/account';
import { initiateContract } from 'src/services/contract/metamask/provider';
import {
  gachaApproval,
  gachaTransactionWithoutReferral,
  gachaTransactionWithReferral,
} from 'src/services/contract/nft/gacha';
import { getNftTotalCount } from 'src/services/contract/nft/nft';
import { gacha, mel, nft } from 'src/utils/constants/addresses';
import {
  garageLimitReached,
  modalUserRejectedTheApproval,
  notEnoughMel,
  notWhitelisted,
} from 'src/utils/constants/errorMessageConstants';
import {
  motorbikeOnTheWay,
  waitingApproveTransaction,
  waitingAuthorizedFund,
} from 'src/utils/constants/loadingMessageConstant';
import { devLog, getTxError } from 'src/utils/helper';

import { ModalProps } from '../waitingModal';

const gachaWithoutReferral = async (
  dispatch: AppDispatch,
  closeGachaModal: () => void,
  waitingModalContent: ModalProps,
  setWaitingModalContent: React.Dispatch<React.SetStateAction<ModalProps>>
) => {
  const account = await getAccount();
  const nftContract = initiateContract(nft.address, nft.abi);
  const gachaContract = initiateContract(gacha.address, gacha.abi);
  const melContract = initiateContract(mel.address, mel.abi);

  const nftCount = await getNftTotalCount(nftContract, account);
  const garageLimit = await nftContract.getUserGarageLimit(account);

  //check network
  const network = await checkNetwork();
  if (network != process.env.REACT_APP_METAMASK_CHAINID) {
    await switchNetwork(dispatch);
  }

  if (nftCount >= garageLimit) {
    setWaitingModalContent({ ...waitingModalContent, ...garageLimitReached });
  }

  const isAllowanceEnoughToMint = await isMelAllowanceEnoughToMint(account);
  if (!isAllowanceEnoughToMint) {
    setWaitingModalContent({
      ...waitingModalContent,
      ...waitingAuthorizedFund,
    });
  } else {
    setWaitingModalContent({
      ...waitingModalContent,
      ...waitingApproveTransaction,
    });
  }
  closeGachaModal();

  // check isWhitelisted
  const whitelistStatus = await getWhitelistStatus(account);
  dispatch(setWhitelistStatus(whitelistStatus));
  if (!whitelistStatus.isUserCanMint) {
    setWaitingModalContent({ ...waitingModalContent, ...notWhitelisted });
    return;
  }
  // get mel amount to pay
  const isMelEnoughToMint = await isMelBalanceEnoughToMint(account);
  if (!isMelEnoughToMint) {
    dispatch(setEnoughMelFalse());
    setWaitingModalContent({ ...waitingModalContent, ...notEnoughMel });
    return;
  }

  if (!isAllowanceEnoughToMint) {
    const melTotalSupply: BigNumber = await getMelTotalSupply();
    // approval
    try {
      await gachaApproval(
        dispatch,
        account,
        melTotalSupply,
        melContract,
        gacha.address
      );
    } catch (err: any) {
      const modalContent = getTxError(err);

      dispatch(setModalContent({ ...waitingModalContent, ...modalContent }));
      setWaitingModalContent({
        ...waitingModalContent,
        ...modalContent,
      });

      return;
    }
  }

  try {
    await gachaTransactionWithoutReferral(
      dispatch,
      account,
      nftContract,
      gachaContract
    );
  } catch (err: any) {
    const modalContent = getTxError(err);

    dispatch(setModalContent({ ...waitingModalContent, ...modalContent }));
    setWaitingModalContent({
      ...waitingModalContent,
      ...modalContent,
    });

    devLog(err);
    return;
  }
  setWaitingModalContent({ ...waitingModalContent, ...motorbikeOnTheWay });
};

const gachaWithReferral = async (
  dispatch: AppDispatch,
  referralAddress: string,
  closeGachaModal: () => void,
  waitingModalContent: ModalProps,
  setWaitingModalContent: Dispatch<SetStateAction<ModalProps>>
) => {
  // initiate contract
  const account = await getAccount();
  const nftContract = initiateContract(nft.address, nft.abi);
  const gachaContract = initiateContract(gacha.address, gacha.abi);
  const melContract = initiateContract(mel.address, mel.abi);

  const nftCount = await getNftTotalCount(nftContract, account);
  const garageLimit = await nftContract.getUserGarageLimit(account);

  //check network
  const network = await checkNetwork();
  if (network != process.env.REACT_APP_METAMASK_CHAINID) {
    await switchNetwork(dispatch);
  }
  closeGachaModal();
  setWaitingModalContent({ ...waitingModalContent, ...waitingAuthorizedFund });

  // check isWhitelisted
  const whitelistStatus = await getWhitelistStatus(account);
  dispatch(setWhitelistStatus(whitelistStatus));
  if (!whitelistStatus.isUserCanMint) {
    setWaitingModalContent({ ...waitingModalContent, ...notWhitelisted });
    return;
  }

  if (nftCount >= garageLimit) {
    setWaitingModalContent({ ...waitingModalContent, ...garageLimitReached });
  }

  // get mel amount to pay
  const melBalanceToPay = await getMelBalance(account);
  // set isEnoguhMel state
  const isMelEnoughToMint = await isMelBalanceEnoughToMint(account);
  if (isMelEnoughToMint == false) {
    dispatch(setEnoughMelFalse());
    setWaitingModalContent({ ...waitingModalContent, ...notEnoughMel });
    return;
  }
  // approval
  try {
    await gachaApproval(
      dispatch,
      account,
      melBalanceToPay,
      melContract,
      nft.address
    );
  } catch (err) {
    setWaitingModalContent({
      ...waitingModalContent,
      ...modalUserRejectedTheApproval,
    });

    return;
  }
  // transaction
  try {
    await gachaTransactionWithReferral(
      dispatch,
      account,
      nftContract,
      gachaContract,
      referralAddress
    );
  } catch (err: any) {
    devLog(err);
    setWaitingModalContent({
      ...waitingModalContent,
      ...modalUserRejectedTheApproval,
    });
    return;
  }
  setWaitingModalContent({
    ...waitingModalContent,
    ...motorbikeOnTheWay,
  });
};

export { gachaWithoutReferral, gachaWithReferral };
