import { Box, Typography } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import DinoNoInternet from 'src/assets/lottie/dinoNoInternet.json';

import styles from './noInternetModal.module.scss';

const NoInternetModal = () => {
  const dinoNoInternet = {
    loop: true,
    autoplay: true,
    animationData: DinoNoInternet,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <section className={styles.noInternetModal}>
      <Box className={styles.blurBg}></Box>
      <Box className={styles.section}>
        <Box className={styles.background}></Box>
        <Box sx={{ padding: '25px' }}>
          <Lottie options={dinoNoInternet} width={120} height={120}></Lottie>
        </Box>
        <Typography
          variant='h6'
          component='h6'
          sx={{ fontWeight: '600', width: '100%', color: 'white' }}
        >
          No Internet Connection.
        </Typography>
        <Typography
          variant='body1'
          component='p'
          sx={{ mt: 2, mb: '24px', width: '100%' }}
        >
          Failed to connect on the Extravagant Mel X - Bike Battle Multiverse.
        </Typography>
      </Box>
    </section>
  );
};

export default NoInternetModal;
