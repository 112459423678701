import GradientButton from 'src/components/lofi/gradientButton';
import GradientModal from 'src/components/lofi/modal/gradientModal';
import Text from 'src/components/lofi/text';

import styles from './comingSoonModal.module.scss';
import type { ComingSoonModalProps } from './comingSoonModal.type';

function ComingSoonModal(props: ComingSoonModalProps) {
  return (
    <GradientModal
      open={props?.showModal}
      style={{
        maxWidth: '80%',
        padding: '20px 20px',
      }}
    >
      <section className={styles.contentSection}>
        <Text type={'h2'} align={'center'} className={styles.firstSection}>
          The Feature is coming soon.
        </Text>
        <div className={styles.secondSection}>
          <GradientButton
            title={'OK'}
            onClick={() => props.setShowModal(false)}
            style={{ marginTop: 30 }}
          />
        </div>
      </section>
    </GradientModal>
  );
}

export default ComingSoonModal;
