import { AccelerationIcon, DriftIcon, SpeedIcon } from 'src/components/Icon';
import Text from 'src/components/lofi/text';
import styles from './bikeAttributes.module.scss';
import { BikeAttributesProps } from './bikeAttributes.type';

function BikeAttributes(props: BikeAttributesProps) {
  const { attributes, isDetailed, className, boostedAttributes } = props;
  return (
    <ul className={`${styles.bikeAttributes} ${className ?? ''}`}>
      <li>
        <div className={styles.attrIcon}>
          <SpeedIcon length='32px' />
        </div>
        <div>
          {isDetailed && <Text className={styles.statLabel}>Speed</Text>}
          <div className={styles.statPoint}>
            <Text className={styles.statValue}>
              {attributes.speed.toString()}
            </Text>
            {boostedAttributes && boostedAttributes?.speed !== 0 && (
              <Text
                className={styles.boostedSpeed}
              >{` +${boostedAttributes?.speed}`}</Text>
            )}
          </div>
        </div>
      </li>
      <li>
        <div className={styles.attrIcon}>
          <AccelerationIcon length='32px' />
        </div>
        <div>
          {isDetailed && <Text className={styles.statLabel}>Acceleration</Text>}
          <div className={styles.statPoint}>
            <Text className={styles.statValue}>
              {attributes.acceleration.toString()}
            </Text>
            {boostedAttributes && boostedAttributes?.acceleration !== 0 && (
              <Text
                className={styles.boostedAccel}
              >{` +${boostedAttributes?.acceleration}`}</Text>
            )}
          </div>
        </div>
      </li>
      <li>
        <div className={styles.attrIcon}>
          <DriftIcon length='32px' />
        </div>
        <div>
          {isDetailed && <Text className={styles.statLabel}>Drift</Text>}
          <div className={styles.statPoint}>
            <Text className={styles.statValue}>
              {attributes.drift.toString()}
            </Text>
            {boostedAttributes && boostedAttributes?.drift !== 0 && (
              <Text
                className={styles.boostedDrift}
              >{` +${boostedAttributes?.drift}`}</Text>
            )}
          </div>
        </div>
      </li>
    </ul>
  );
}

export default BikeAttributes;
